import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';
import { BookingListing } from '../booking-listing';
import { BookingListingServiceService } from '../booking-listing-service.service';

@Component({
  selector: 'app-upcoming',
  templateUrl: './upcoming.component.html',

  styleUrls: ['./upcoming.component.css'],
})
export class UpcomingComponent implements OnInit {
  constructor(
    private router: Router,
    private Service: BookingListingServiceService,
    private headerTitle: Title,
    private http: HttpClient
  ) {}

  // @Input() searchData:string | undefined;
  searchData = '';
  p: number = 1;
  totalItems: any;

  pg: any;
  mymodel: any;
  localGetUpcoming: any;
  hidePages: boolean = true;

  public setTitle(newTitle: string) {
    this.headerTitle.setTitle(newTitle);
  }

  ngOnInit(): void {
    this.setTitle('Bookings - Upcoming | EVcityride');
    localStorage.removeItem('searchPast');
    localStorage.removeItem('searchOngoing');

    // localStorage.setItem('searchUpcoming','');

    if (localStorage.searchUpcoming == null) {
      this.getUpcoming();
    } else {
      // this.onSearchChange;
      // console.log('LocalStorage ma data che....!!!');
      this.localGetUpcoming = localStorage.getItem('searchUpcoming');

      this.Service.getSearchResultUpcoming(
        this.localGetUpcoming,
        this.p
      ).subscribe((res) => {
        // console.log(res);
        this.upcoming = res.bookings;
        this.totalItems = res.total_items;
      });
    }

    //   For Search Icon Jquery...
    $(document).ready(function () {
      $('#search-field').hide();

      $('#search-icon').click(function () {
        $('#search-field').show();
        $('#search-icon').hide();
        // alert('runnn');
      });

      $('#search-field').focusin(function () {
        $('#search-icon').hide();
      });

      $('#search-field').focusout(function () {
        $('#search-icon').show();
        $('#search-field').hide();
      });
    });
  }

  noBookings: boolean = false;
  nameSearch: string = '';
  noDataFound: boolean = false;
  searchIcon: boolean = false;
  upcoming: BookingListing | any;

  // Working with async and await

  async getUpcoming() {
    const res = await this.Service.getUpcomingBookings(this.p).toPromise();

    setTimeout(() => {
      $('.preloader').css('display', 'none');
      $('.preloader').fadeOut('slow');
    }, 1000);
    if (res.bookings != 0) {
      this.upcoming = res.bookings;
      this.totalItems = res.total_items;
      this.searchIcon = true;
      $('.searchStyles').removeClass('dynamicClassAdd');
      this.noDataFound = true;
    } else {
      this.noBookings = true;
      this.noDataFound = false;
      $('.searchStyles').addClass('dynamicClassAdd');
      this.hidePages = false;
      this.searchIcon = false;
    }

    // .subscribe((res) => {
    //   setTimeout(() => {
    //     $('.preloader').css('display', 'none');
    //     $('.preloader').fadeOut('slow');
    //   }, 1000);

    //   if (res.bookings != 0) {
    //     this.upcoming = res.bookings;
    //     this.totalItems = res.total_items;
    //     this.searchIcon = true;
    //     $('.searchStyles').removeClass('dynamicClassAdd');
    //     this.noDataFound = true;
    //   } else {
    //     this.noBookings = true;
    //     this.noDataFound = false;
    //     $('.searchStyles').addClass('dynamicClassAdd');
    //     this.hidePages = false;
    //     this.searchIcon = false;
    //   }
    // });
  }

  baseUrl: any;

  getPage(page: any) {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);

    // if(localStorage.searchUpcoming === ''){
    //   this.baseUrl = `https://evcityride.9brainz.store/api/v1/booking-search?type=upcoming&agency_id=${localStore.agency_id}&page=${page}`

    //   this.http.get(this.baseUrl).subscribe((data: any) => {
    //   console.log(data);
    //   this.upcoming =  data.bookings;
    //   this.totalItems = data.total_items;

    // });
    // }else{
    this.baseUrl = `https://admin.evcityride.com/api/v1/booking-search?type=upcoming&agency_id=${localStore.agency_id}&page=${page}&search=${this.searchRes}`
    // this.baseUrl = `https://evcityride.9brainz.store/api/v1/booking-search?type=upcoming&agency_id=${localStore.agency_id}&page=${page}&search=${this.searchRes}`;

    this.http.get(this.baseUrl).subscribe((data: any) => {
      // console.log(data);
      this.upcoming = data.bookings;
      this.totalItems = data.total_items;
    });
    // }
  }

  searchRes: any = '';

  onSearchChange(searchValue: any): void {
    this.searchRes = searchValue.target.value;
    localStorage.setItem('searchUpcoming', this.searchRes);
    this.p = 1;
    this.Service.getSearchResultUpcoming(this.searchRes, this.p).subscribe(
      (res) => {
        // console.log(res);
        this.upcoming = res.bookings;
        this.totalItems = res.total_items;
      }
    );
  }
}
