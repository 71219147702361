import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-your-first-choice',
  templateUrl: './web-your-first-choice.component.html',
  styleUrls: ['./web-your-first-choice.component.css']
})
export class WebYourFirstChoiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      $(".preloader").css('display','none');
      $('.preloader').fadeOut('slow');
    }, 1000);
    // this.spinner.show();


    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 5000);
  }

}
