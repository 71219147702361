<!-- Upcoming Tab Content Start -->

<!-- <ngx-spinner bdColor="white" color="black"
   template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->

<div class="preloader"></div>
<div class="col-md-12" *ngIf="noBookings">
  <div
    style="
      width: 100%;
      display: flex;
      margin-top: 20px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    "
  >
    <img
      src="assets/emptyStats/nooBookings.svg"
      style="width: 285px"
      alt=""
    /><br />
    <h2><b> No Bookings</b></h2>
    <br />
    <h5>Book your ride and you're ready to go</h5>
  </div>
</div>
<div style="position: relative">
  <div class="searchStyles mt-3" *ngIf="hidePages">
    <div class="d-flex justify-content-end align-items-center h-100">
      <div class="form-group mb-0 mr-3 w-100" id="search-field">
        <input
          type="text"
          style="width: 314px"
          name="msg"
          [value]="localGetUpcoming == null ? '' : localGetUpcoming"
          (input)="onSearchChange($event)"
          class="form-control"
          autocomplete="off"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Search Something"
        />
      </div>
      <img
        src="assets/images/icons/search.svg"
        alt=""
        class="mr-3"
        id="search-icon"
      />
    </div>
  </div>
</div>
<!-- <h4>{{mymodel}}</h4> -->

<div
  class="tab-pane upcming"
  id="Upcoming"
  style="margin-top: 20px"
  [ngClass]="upcoming === 0 ? 'trip-wrapper' : 'heyy'"
  role="tabpanel"
  aria-labelledby="Upcoming-tab"
>
  <!-- <div *ngIf="( upcoming | paginate: { itemsPerPage: 5, currentPage: p }  )as result"  > -->

  <!-- <div *ngIf="(upcoming|filterPipe:searchData) as result" > -->

  <a
    style="cursor: pointer; text-decoration: none"
    *ngFor="
      let Up of upcoming
        | paginate: { itemsPerPage: 10, currentPage: p, totalItems: totalItems }
    "
    [routerLink]="['/rider/bookings/upcoming/upcoming_details/', Up.id]"
    class="text-dark"
  >
    <!-- Change Card For Solve layout Problem -->
    <div class="card mt-3 trip-card">
      <div class="card-body">
        <div class="Source-and-destination">
          <div class="font-weight-bold" *ngIf="Up.request_type == 'day rental'">
            <p>Day Rental</p>
          </div>
          <div
            class="d-flex justify-content-between align-content-center w-100 font-weight-bold"
            *ngIf="Up.request_type !== 'day rental'"
          >
            <p class="text-truncate w-50 font-weight-bold">{{ Up.source }}</p>
            <img src="assets/images/icons/Line.svg" alt="" class="ml-4 mr-4" />
            <p class="text-truncate w-50 font-weight-bold">
              {{ Up.destination }}
            </p>
          </div>
        </div>
        <div class="trip-details">
          <p class="client-name charCount">{{ Up.passenger_name }}</p>
          <p class="trip-date">{{ Up.booking_date }}</p>
        </div>
      </div>
    </div>
    <!-- Change Card For Solve layout Problem -->
  </a>
  <!-- <div class="col-md-12" *ngIf="result.length === 0 ">
        <div style="width: 100%; height: 71vh; display: flex;  justify-content: center; align-items: center; flex-direction: column; " >

         <img  src="assets/emptyStats/nooBookings.svg" style="width:300px;" alt=""><br>
         <h2><b> No Bookings</b></h2><br>
         <h5>Book your ride and you're ready to go</h5>
        </div>

    </div> -->
  <!-- <pagination-controls    class="alignStyles" (pageChange)="p = $event"></pagination-controls> -->
  <div class="col-md-12" *ngIf="totalItems === 0">
    <div
      style="
        width: 100%;
        height: 71vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      "
    >
      <img
        src="assets/emptyStats/nooBookings.svg"
        style="width: 300px"
        alt=""
      /><br />
      <h2><b> No Record Found</b></h2>
      <br />
      <!-- <h5>Book your ride and you're ready to go</h5> -->
    </div>
  </div>

  <!-- </div> -->
</div>
<pagination-controls
  *ngIf="totalItems != 0 && hidePages"
  class="alignStyles"
  (pageChange)="getPage((p = $event))"
></pagination-controls>
<!-- Upcoming Tab Content End -->
