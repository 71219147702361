<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
<app-header *ngIf="nointerNet"></app-header>

<div *ngIf="nointerNet" class="container padingTop" style="padding-top: 100px">
  <div class="row">
    <div class="col-md-3 winview">
      <app-main-sidebar></app-main-sidebar>
    </div>
    <div class="col-md-9 winview">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!--
<div class="container">
  <div class="ev-parent">
    <div class="ev-sidebar">
      <app-main-sidebar></app-main-sidebar>
    </div>
    <div class="ev-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div> -->
