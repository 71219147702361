<div class="preloader"></div>

<!-- <br><br>
<div *ngIf="!ServiCE.isUserAnonymousLoggedIn">
	<button class="btn btn-primary btn-block" (click)="signInAnonymously()">Anonymously Login</button>
</div> -->

<div class="row mt-4 mb-4">
  <div class="col">
    <p class="text-dark font-weight-bold mb-0">Invoice</p>
    <!-- <div class="k-block">
      <button kendoButton [look]="'default'">Default Button</button>
      <button kendoButton [look]="'outline'">Outline</button>
    </div> -->
  </div>

  <!-- <div class="col">
                    <div class=""  >
                        <div class="d-flex justify-content-end align-items-center h-100">
                            <div class="form-group mb-0 mr-3 w-100" id="search-field">
                                <input type="text"  [(ngModel)]="gmeAe"  class="form-control"
                                 id="exampleInputEmail1" aria-describedby="emailHelp"
                                 placeholder="Search Something">
                            </div>
                            <img src="assets/images/icons/search.svg" alt="" class="mr-3" id="search-icon">

                        </div>
                    </div>
                </div> -->
</div>

<div class="row">
  <div class="col-md-12" *ngIf="noInvoice">
    <div
      style="
        width: 100%;
        height: 58vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      "
    >
      <img
        src="assets/emptyStats/noinvoice.svg"
        style="width: 285px"
        alt=""
      /><br />
      <h2><b> No Invoice</b></h2>
      <br />
      <h5>Sorry, there is no invoice information</h5>
    </div>
  </div>
</div>

<div class="row" style="height: 72vh; overflow-y: auto">
  <div class="col-sm-12 col-md-12">
    <!-- <div *ngIf="(invoiceVar|invoiceFilter:gmeAe) as result" > -->
    <a
      *ngFor="let invoice of invoiceVar"
      [routerLink]="['/rider/invoice/invoice_details/', invoice.id]"
      class="text-dark"
    >
      <div class="card mt-4 trip-card">
        <div class="card-body">
          <div class="invoice-container w-100">
            <div class="invoice-details">
              <p class="invoice-id text-truncate mb-0 font-weight-bold">
                {{ invoice.invoice_id }}
              </p>
              <p class="invoice-date text-truncate mb-0">
                {{ invoice.invoice_date }}
              </p>
            </div>
            <div class="Next-image">
              <img src="assets/images/icons/Icon.png" alt="" class="" />
            </div>
          </div>
        </div>
      </div>
    </a>
    <!-- <div class="col-md-12" *ngIf="result.length === 0 ">
                        <div style="width: 100%; height: 71vh; display: flex;  justify-content: center; align-items: center; flex-direction: column; " >

                         <img  src="assets/emptyStats/nooBookings.svg" style="width:300px;" alt=""><br>
                         <h2><b> No Invoice</b></h2><br>
                        </div>

                    </div>
                    </div> -->
  </div>
</div>

<section class="backdrop"></section>
