import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SkguardGuard } from '../skguard.guard';
import { PastTripsComponent } from './bookings/past-trips/past-trips.component';
import { UpcomingComponent } from './bookings/upcoming/upcoming.component';
import { AirPickupComponent } from './request-a-ride/air-pickup/air-pickup.component';
import { OngoingComponent } from './bookings/ongoing/ongoing.component';
import { BookingsComponent } from './bookings/bookings.component';
// import { BookingsComponent } from 'Temp/src/app/featured/bookings/bookings.component';
// import { MainLayoutComponent } from 'Temp/src/app/req-ride-layout/main-layout/main-layout.component';
// import { } from './'
// import { SkguardGuard } from '../skguard.guard';
// import { AirportDropDetailsComponent } from './billing/airport-drop/airport-drop-details/airport-drop-details.component';
// import { AirportDropComponent } from './billing/airport-drop/airport-drop.component';
// import { AirportPickupDetailsComponent } from './billing/airport-pickup/airport-pickup-details/airport-pickup-details.component';
// import { AirportPickupComponent } from './billing/airport-pickup/airport-pickup.component';
// import { BillingDetailsComponent } from './billing/billing-details/billing-details.component';
// import { SelfDriveDetailsComponent } from './billing/self-drive/self-drive-details/self-drive-details.component';
// import { SelfDriveComponent } from './billing/self-drive/self-drive.component';
// import { BillingComponent } from './billing/billing.component';
// import { BookingsDetailsComponent } from './bookings/bookings-details/bookings-details.component';
// import { OngoingDetailsComponent } from './bookings/ongoing-details/ongoing-details.component';
// import { OngoingTrackComponent } from './bookings/ongoing-details/ongoing-track/ongoing-track.component';
// import { PastTripDetailsComponent } from './bookings/past-trip-details/past-trip-details.component';
// import { UpcomingDetailsComponent } from './bookings/upcoming-details/upcoming-details.component';
// import { InvoiceDetailsComponent } from './invoice/invoice-details/invoice-details.component';
// import { TrackTripDetailsComponent } from './track-trip/track-trip-details/track-trip-details.component';
// import { RequestARideComponent } from './request-a-ride/request-a-ride.component';

const routes: Routes = [

   
      
  
    


  
   

  
   
  // {
  // path:'rider/request-a-ride/ongoing',canActivate:[SkguardGuard], component:OngoingComponent
  // },
  // {path:'rider', component:MainLayoutComponent, children:[
  //   {path:'ongoing', component:OngoingComponent}
    
  // ]},
  {
    path:'pickup', component:AirPickupComponent
  },
  // {
  //   path:'ongoing', component:OngoingComponent
  // }

  // {path:'rider/bookings/upcoming',  canActivate:[SkguardGuard], component:UpcomingComponent},
  //   {path:'past_trips',  canActivate:[SkguardGuard], component:PastTripsComponent},



  
  // {path:'', redirectTo:'request-a-ride', pathMatch:'full'},
  // {path:'hy', component:BookingsDetailsComponent},
  // {path:'billdetail', component:BillingDetailsComponent},
  
  // This is Bookings Child Routes Start
  // {path:'booking_details/:id', canActivate:[SkguardGuard], component:BookingsDetailsComponent},
  // {path:'bookings/ongoing_details/:id',canActivate:[SkguardGuard], component:OngoingDetailsComponent},
  // {path:'bookings/upcoming_details/:id',canActivate:[SkguardGuard], component:UpcomingDetailsComponent},
  // {path:'bookings/past_trips_details/:id',canActivate:[SkguardGuard], component:PastTripDetailsComponent},
  // {path:'bookings/ongoing_track',canActivate:[SkguardGuard], component:OngoingTrackComponent},

// Bookings Child Routes End
 

// Billings Child Routes Start

  // {path:'billing/bill_details/:id',canActivate:[SkguardGuard], component:BillingDetailsComponent},
  // {path:'billing/airport_pickup',canActivate:[SkguardGuard], component:AirportPickupComponent},
  // {path:'billing/airport_pickup/airport-pickup-details/:id',canActivate:[SkguardGuard], component:AirportPickupDetailsComponent},
  // {path:'billing/airport_drop',canActivate:[SkguardGuard], component:AirportDropComponent},
  // {path:'billing/airport_drop/airport_drop_details/:id',canActivate:[SkguardGuard], component:AirportDropDetailsComponent},
  // {path:'billing/self_drive',canActivate:[SkguardGuard], component:SelfDriveComponent },
  
  // {path:'billing/self_drive/self_drive_details/:id', canActivate:[SkguardGuard], component:SelfDriveDetailsComponent},
  
// Billings Child Routes End



// Invoice Child Route Start
// Invoice Child Route End


// Track-Trip Child Route Start

  // {path:'track-trip/track_trip_details/:id', canActivate:[SkguardGuard], component:TrackTripDetailsComponent},
// Track-Trip Child Route End





];

@NgModule({
  imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})
export class FeaturedRoutingModule { }
