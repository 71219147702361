<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
<div class="preloader"></div>
<div class="page-wrapper">

<section class="inner-banner">
    <div class="container">
        <h2>About Page</h2>
    </div>
</section>

<section class="mt-5 mb-5">
    <div class="block-title text-center">
        <div class="dot-line"></div>
        <h2>About Us</h2>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-left">
                <p>We provide end to end solution, from developing & maintaining electrical infrastructure to managing entire fleet.</p>
                <p>We work with Businesses, Government Organisations, PSUs, and Corporates that currently use fleet of fuel-burning cars and help them shift towards better standards for a greener environment, offering them an eco-friendly alternative, that's also friendly to their bottom line.</p>
                <p>Despite being an establishment, we're not here just to do business. We are here to ignite a spark of change, for a brighter future. </p>
            </div>
        </div>
    </div>

</section>

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 p-0">
            <div class="divider"></div>
        </div>
    </div>
</div>
<app-web-our-founders></app-web-our-founders>
</div>
