import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingListingServiceService } from 'src/app/featured/bookings/booking-listing-service.service';
import { Billing } from '../../billing';

@Component({
  selector: 'app-self-drive-details',
  templateUrl: './self-drive-details.component.html',
  styleUrls: ['./self-drive-details.component.css']
})
export class SelfDriveDetailsComponent implements OnInit {

  constructor(private router:Router
    ,private route:ActivatedRoute,
     private Service:BookingListingServiceService, 
    private titleHeader:Title) { }

    id:any;
    public setTitle(newTitle: string) {
      this.titleHeader.setTitle(newTitle);
    }
  ngOnInit(): void {
  this.setTitle('Billings-Self Drive Details | Evcityride');

    this.id = this.route.snapshot.params.id;
    

    this.dayrentalDetails();

    $(document).ready(function(){
      $(".menu-icon").click(function(){
        $(".aside-menu").show();

      });
      
      $(".backdrop").click(function(){  
        $(".aside-menu").hide();
        $(".backdrop").hide();
      });
    });

  }

  dayRentalDetails:Billing | any;
  source:any;
  destination:any;
  trip_date:any;
  trip_end_date:any;
  passengerName:any;
  passengerContact:any;
  passengerCount:any;
  booking_id:any;
  booking_date:any;
  carType:any;
  driver:any;
  driver_number:any;
  date:any;
  end_date:any;
  


  dayrentalDetails(){
    // this.spinner.show();

    this.Service.getOngoingBookingsDetails(this.id).subscribe((res)=>{

      if(res.success == true){
        this.dayRentalDetails = res.data;
        this.source = this.dayRentalDetails.source;
        this.destination = this.dayRentalDetails.destination;
        this.trip_date = this.dayRentalDetails.trip_date;
        this.trip_end_date = this.dayRentalDetails.trip_end_date;
        this.passengerName = this.dayRentalDetails.passenger_name;
        this.passengerContact = this.dayRentalDetails.passenger_contact_number;
        this.passengerCount = this.dayRentalDetails.total_passenger;
        this.booking_id = this.dayRentalDetails.booking_id;
        this.booking_date = this.dayRentalDetails.booking_date;
        this.carType = this.dayRentalDetails.carType;
        this.driver = this.dayRentalDetails.driver;
        this.driver_number = this.dayRentalDetails.driver_number;
        this.date = this.dayRentalDetails.date;
        this.end_date = this.dayRentalDetails.end_date
        
        // this.spinner.hide();
        setTimeout(() => {
          $(".preloader").css('display','none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      }else{
        
        // this.spinner.hide();
        setTimeout(() => {
          $(".preloader").css('display','none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      }

      
    })
  }
  gotoAirportSelfDrive(){
    this.router.navigateByUrl('/rider/billing/self_drive');
    
  }

}
