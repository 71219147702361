<div class="preloader" ></div>
<section class="inner-banner">
    <div class="container">
        <h2>Contact</h2>
    </div>
</section>

<section class="mt-5 mb-5">
    <div class="container">
        <div class="row mb-5">
            <div class="col-sm-12">
                <h2 class="font-weight-bold text-center">LET’S TALK ! Tell  us about your needs.</h2>
                <p class="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
        </div>
        <div class="row">
            
            <div class="col-sm-12 col-md-4 card-space">
                <div class="d-flex align-items-start">
                    <img src="assets/images/Evcity/Location.svg" alt="">
                    <div class="content ml-4 location-content">
                        <h5 class="font-weight-bold font-dark">Address</h5>
                        <p>Optiqa, 12 Greenway Plaza Suite 1100 Houston TX 77046 USA</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-4 card-space">
                <div class="d-flex align-items-start">
                    <img src="assets/images/Evcity/Call.svg" alt="">
                    <div class="content ml-4">
                        <h5 class="font-weight-bold font-dark">Call</h5>
                        <p class="mb-0">Phone: 9101234569</p>
                        <p class="mb-0">Fax: 2819412063</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-4 card-space">
                <div class="d-flex align-items-start">
                    <img src="assets/images/Evcity/Mail.svg" alt="">
                    <div class="content ml-4">
                        <h5 class="font-weight-bold font-dark">Mail</h5>
                        <p>demo@demo.com</p>
                    </div>
                </div>
            </div>

        </div>
    </div>

</section>

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 p-0">
            <div class="divider"></div>
        </div>
    </div>
</div>

<section class="mt-5 mb-5">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h1 class="font-weight-bold text-center">Send us your Message</h1>
                <p class="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                <div class="row mt-5">
                    <div class="col-sm-12 col-md-6">
                        <div class="form-layout">
                            <div class="form-group">
                                <input type="text" class="input-field" placeholder="Name">
                                <label>Name</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                        <div class="form-layout">
                            <div class="form-group">
                                <input type="text" class="input-field" placeholder="Compant">
                                <label>Compant *</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                        <div class="form-layout">
                            <div class="form-group">
                                <input type="email" class="input-field" placeholder="Email">
                                <label>Email *</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                        <div class="form-layout">
                            <div class="form-group">
                                <input type="number" class="input-field" placeholder="Phone">
                                <label>Phone *</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12">
                        <div class="form-layout">
                            <div class="form-group">
                                <textarea class="input-field" rows="5" placeholder="Comment *"></textarea>
                                <!-- <input type="text" class="input-field" placeholder="Name"> -->
                                <label>Comment *</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12">
                        <button type="button" class="btn btn-primary">Submit Now</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->