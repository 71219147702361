import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';
import { BookingListingServiceService } from '../../bookings/booking-listing-service.service';
import { Billing } from '../billing';
// import { BillingServiceService } from '../billing-service.service';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.css']
})
export class BillingDetailsComponent implements OnInit {

  constructor(private router:Router,
     private route:ActivatedRoute
     , private Service:BookingListingServiceService,
      private titleHeader:Title) { }

  id:any;

  public setTitle(newTitle:string){
    this.titleHeader.setTitle(newTitle)
  }

  ngOnInit(): void {
  this.setTitle('Billing Details | Evcityride');

    this.id = this.route.snapshot.params.id;
    

    this.getBillDetails();

    $(document).ready(function(){
      $(".menu-icon").click(function(){
        $(".aside-menu").show();

      });
      
      $(".backdrop").click(function(){  
        $(".aside-menu").hide();
        $(".backdrop").hide();
      });
    });
  }

  dataB:Billing  |any;
  source:any;
  destination:any;
  trip_date:any;
  trip_end_date:any;
  passengerName:any;
  passengerContact:any;
  passengerCount:any;
  booking_id:any;
  booking_date:any;
  carType:any;
  driver:any;
  driver_number:any;
  agency:any;
  payment_type:any;
  kilometer:any;
  start_date:any;
  request_type:any;
  end_date:any;
  date:any;

getBillDetails(){

  // this.spinner.show();

  this.Service.getOngoingBookingsDetails(this.id).subscribe((res)=>{
    
    if(res.success == true){ 
      this.dataB = res.data;
      this.source = this.dataB.source;
      this.destination = this.dataB.destination;
      this.trip_date = this.dataB.trip_date;
      this.trip_end_date = this.dataB.trip_end_date;
      this.passengerName = this.dataB.passenger_name;
      this.passengerContact = this.dataB.passenger_contact_number;
      this.passengerCount = this.dataB.total_passenger;
      this.booking_id = this.dataB.booking_id;
      this.booking_date = this.dataB.booking_date;
      this.carType = this.dataB.carType;
      this.driver = this.dataB.driver;
      this.driver_number = this.dataB.driver_number;
      this.agency = this.dataB.booking_type;
      this.payment_type = this.dataB.payment_type;
      this.kilometer = this.dataB.kilometer;
      this.request_type = this.dataB.request_type;
      this.end_date = this.dataB.end_date;
      this.date = this.dataB.date;
  
      this.start_date = this.dataB.date;
      // this.spinner.hide();
      setTimeout(() => {
        $(".preloader").css('display','none');
        $('.preloader').fadeOut('slow');
      }, 1000);
    }else{
      
      // this.spinner.hide();
      setTimeout(() => {
        $(".preloader").css('display','none');
        $('.preloader').fadeOut('slow');
      }, 1000);
    }
    




  })
}


  gotoBill(){
    this.router.navigateByUrl('/rider/billing');
    
  }

}
