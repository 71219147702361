<!-- <div class="col-sm-12 col-md-8 col-lg-9"> -->
    <!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
    <div class="preloader"></div>
    
    <div class="col" style="margin-top: 35px;">
        <p class="text-dark font-weight-bold mb-0">Contact Service</p>
    </div>
    <div class="row mt-4">
       
        <!-- <div class="row"> -->
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="card contact-service">
                    <div class="card-body">
                        <a href="mailto:connect@evcityride.com" style="color: black; text-decoration: none;"><img src="assets/images/icons/Mail.svg" alt="" class="img-fluid mr-2" style="font-size: 20px; height: 30px; width: 30px;">connect@evcityride.com</a>            
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="card contact-service">
                    <div class="card-body">
                        
                        <a href="tel:+919411091109" style="color: black; text-decoration: none;"><img src="assets/images/icons/Call.svg" alt="" class="img-fluid mr-3" style="font-size: 20px; height: 30px; width: 30px; ">+91 9411091109</a>            
                    </div>
                </div>
            </div>
        <!-- </div> -->

        <!-- <div class="col-sm-12 col-md-8 col-lg-6"> -->
            
            <!-- <div class="card contact-service">
                <div class="card-body">
                    <div class="form-layout">
                        <form action="">
                                <div class="form-group">
                                    <input type="text" class="input-field" placeholder="Subject">
                                    <label>Subject</label>
                                </div>
                                <div class="form-group">
                                    <textarea class="input-field" cols="10" rows="5"></textarea>
                                    <label>Description *</label>
                                </div>
                            <button type="submit" class="btn btn-primary btn-block">Submit Request</button>
                        </form>
                    </div>	
                </div>
            </div> -->
        <!-- </div> -->
    </div>

    <div class="row">
        <div class="col-md-12">
            <a class="navbar-brand stylee"><img src="assets/images/resources/EV City Logo.svg" alt=""></a>

        </div>
    </div>
<!-- </div> -->
<section class="backdrop"></section>