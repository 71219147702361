import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Invoice } from './invoice';

@Injectable({
  providedIn: 'root',
})
export class InvoiceServiceService {
  // baseUrl = 'https://evcityride.9brainz.store';
  baseUrl ='https://admin.evcityride.com'

  constructor(private http: HttpClient) {}

  getInvoices(): Observable<Invoice> {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);

    return this.http
      .get<Invoice>(
        this.baseUrl + '/api/v1/invoice?agency_id=' + localStore.agency_id
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  getInvoiceDetails(InvoiceID: any): Observable<Invoice> {
    return this.http
      .get<Invoice>(
        this.baseUrl + '/api/v1/invoice-details?invoice_id=' + InvoiceID
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  errorHandle(error: HttpErrorResponse) {
    return throwError(error.message || 'Serve Error');
  }
}
