import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ReqRideService } from '../req-ride.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';
import { RequestRide } from '../request-ride';
import { HttpClient } from '@angular/common/http';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
// import { passengerMax } from '../passenger.validator';

// import { } from 'intl-tel-input';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { DatePipe, SlicePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
// import {  } from "./";

@Component({
  selector: 'app-air-pickup',
  templateUrl: './air-pickup.component.html',
  styleUrls: ['./air-pickup.component.css'],
  providers: [DatePipe],
})
export class AirPickupComponent implements OnInit {
  // public minDate: Date = new Date ("05/07/2017 2:00 AM");

  // public maxDate: Date = new Date ("05/27/2017 11:00 AM");
  public dateValue: Date = new Date();

  separateDialCode = true;
  SearchCountryField = SearchCountryField;

  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.UnitedKingdom,
  ];
  submitted = false;

  // dayRentalForm: FormGroup | any;

  airportPickupForm: FormGroup | any;
  airpickPostForm: FormGroup | any;

  // public min = new Date(2021, 1, 12, 10, 30);
  // public max = new Date(2021, 3, 21, 20, 30);

  // date:any;
  // setDefault(){
  //   this.carsTypePickup[0]
  // }

  constructor(
    private Service: ReqRideService,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private toastrMsg: ToastrService,
    private titleHeader: Title
  ) {
    // var section = this.carsTypePickup.image[0]

    this.airportPickupForm = this.fb.group({
      // setDefault : this.carsTypePickup[0],

      airPickControl: new FormControl('', Validators.required),

      airPickFareControl: new FormControl('', Validators.required),
    });
    // this.airportPickupForm.setValue({
    //   this.airPickPlansOBJ.image;
    // })

    this.AiportPickupPostForm();
  }

  // setDefaultPic() {
  //   this.pic = "assets/images/my-image.png";
  // }

  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  // maxMsgLength:any = '';

  // private changeMaxMsgLength() : void {
  //   let values = Object.values(crossVal);
  //   if(values.includes("oldAccount")) {
  //     this.maxMsgLength = 150;
  //   } else {
  //     this.maxMsgLength = 200;
  //   }
  // }

  PICKIMGchange(event: any) {
    // console.log(event);
  }

  max = 7;

  min = 1;

  AiportPickupPostForm() {
    this.airpickPostForm = this.fb.group({
      start_date: ['', [Validators.required]],

      passenger_name: ['', [Validators.required]],
      total_passenger: ['', [crossVal]],

      passenger_contact_number: ['', [Validators.required]],
      km: [''],
      basicFare: [''],
      source: ['', [Validators.required]],
      destination: ['', [Validators.required]],
      img: [''],

      car_name: [''],

      payment_detail: ['bill_to_agency', Validators.required],
      model_id: [''],
      fare_id: [''],
      country_code: [''],
      executive_name: [''],
      remarks: [''],
      flight_number: [''],

      // date : ['', Validators.required],
    });
  }

  // Only Alphabets with Some Characters [-_ ]
  keyPressAlphaNumericWithCharacters(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow alpahbets, space, underscore
    if (/[a-zA-Z-_ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  get passenger_name() {
    return this.airpickPostForm.get('passenger_name');
  }
  get start_date() {
    return this.airpickPostForm.get('start_date');
  }
  get passenger_contact_number() {
    return this.airpickPostForm.get('passenger_contact_number');
  }

  max_passenger: any;

  // getPass(){
  //   this.Service.airportPickupPOST(this.max_passenger).subscribe((res)=>{
  //       console.log(res);
  //   })
  // }

  mini = new Date();

  msgCongo: any;
  bkId: any;
  showCongo: boolean = false;
  showMDL: boolean = false;

  classModal: boolean = false;
  public submi: boolean = false;

  airPickpostSubmit() {
    this.submi = true;

    if (!this.showMeAfterPICK) {
      // alert('ny');
      this.toastrMsg.info('Choose First Plan');
    } else {
      const data = this.airpickPostForm.value;
      data.start_date = this.datePipe.transform(
        data.start_date,
        'dd-MM-YYYY HH:mm'
      );
      data.country_code = data.passenger_contact_number.dialCode;
      data.passenger_contact_number = data.passenger_contact_number.number
        .slice(1)
        .split(' ')
        .join('');
      data.km = this.airPickfareOBJ.kilometer;

      data.basicFare = this.airPickfareOBJ.basic_fare;

      data.img = this.airPickPlansOBJ.image;
      data.car_name = this.airPickPlansOBJ.name;

      data.model_id = this.airPickPlansOBJ.id;
      data.fare_id = this.airPickfareOBJ.fare_id;

      this.Service.airportPickupPOST(data).subscribe((res) => {
        if (this.airpickPostForm.valid) {
          if (res.success == true) {
            this.bkId = res.booking_id;
            this.msgCongo = res.message;
            this.toastrMsg.success(res.message, 'Success');

            this.showCongo = true;

            this.showMDL = true;

            this.classModal = true;

            this.airpickPostForm.reset();
            this.getBookings();
            this.airpickPostForm.reset();
            this.getSrcDestiAirPickup();
            this.getCarsModelsPickup();
            // this.ngOnInit();
          } else {
            this.toastrMsg.error(res.message);

            console.error(res);
          }
        } else {
          this.toastrMsg.error(res.message);
          console.error('Error');
        }
      });
    }
  }

  congoo() {
    this.showCongo = true;
  }
  closs() {
    this.showMDL = false;
  }

  ngOnInit(): void {
    this.setTitle('Airport Pickup | Evcityride');
    // this.spinner.show();

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);
    setTimeout(() => {
      $('.preloader').css('display', 'none');
      $('.preloader').fadeOut('slow');
    }, 1000);

    $(document).ready(function () {
      $('#phone').css('height', '65px');
      $('#phone').css('font-size', '18px');
      $('.iti--allow-dropdown').css('width', '100%');
      $('.labPick').css('top', '-24px');
      $('.drower-menu').hide();
      $('.backdrop').hide();

      // ----------------------------------------------------

      // Select Car type
      $('#choose-plan-tri-1').click(function () {
        $('#car-type-1').addClass('show-drower');
        $('.backdrop').show();
      });
      $('#close').click(function () {
        $('#car-type-1').removeClass('show-drower');
        $('.backdrop').hide();
      });
      $('.backdrop').click(function () {
        $('#car-type-1').removeClass('show-drower');
        $('.backdrop').hide();
      });
      $('#close, .backdrop').click(function () {
        $('.drower').removeClass('show-drower');
        $('.backdrop').hide();
      });
      // ----------------------------------------------------

      // ----------------------------------------------------
      // This code is reserved for aside menu in mobile screen
      $('aside').hide();
      $('.backdrop').hide();

      $('.backdrop').click(function () {
        $('aside').hide();
        $('.backdrop').hide();
        $('aside').removeClass('show-drower');
      });
      $('.menu-icon').click(function () {
        $('aside').show();
        $('.backdrop').show();
      });
      // ----------------------------------------------------
    });

    this.getCarsModelsPickup();
    this.getSrcDestiAirPickup();
    // this.getPass();
    // this.getBookings();
    // this.getSrcDestiAirDrop();
    // this.getCarsModelsDayRental();
    // this.getCarsModelsDrop();
  }

  airPickPlansOBJ: any = { max_passenger: 7 };
  airPickfareOBJ: any;

  // showingImgDayRent: any;
  showImgPICK: boolean = false;

  showMePICK: boolean = true;
  showMeAfterPICK: boolean = false;

  gazab: any = {};

  airPickSubmit() {
    // console.log(this.airportPickupForm.value);

    if (this.airportPickupForm.valid) {
      this.airPickPlansOBJ = this.airportPickupForm.value.airPickControl;
      this.airPickfareOBJ = this.airportPickupForm.value.airPickFareControl;
      this.gazab = this.airPickPlansOBJ.max_passenger;
      // console.log(this.gazab, "Max_Passenger");

      // console.log(this.airPickPlansOBJ);
      // console.log(this.airPickfareOBJ);

      this.showMePICK = false;
      this.showMeAfterPICK = true;
      this.showImgPICK = true;

      $('.drower').removeClass('show-drower');
      $('.backdrop').hide();
      // console.log("right close");
    } else {
      // console.log("Errors he bhai");
    }
  }

  autoReset() {
    alert('YOur code is not valid>>>');
  }

  form: FormGroup | any;

  closy() {
    $('.drower').removeClass('show-drower');
    $('.backdrop').hide();
    // console.log("right close");
  }

  // this is choose_plan---> Airport_Pickup section Start

  // carsTypePickup: RequestRide | any;

  faresPlanAirportPickup: RequestRide | any;

  clickAirportFares(faresAirport_Pickup: any) {
    this.faresPlanAirportPickup = faresAirport_Pickup;
    // console.log(this.faresPlanAirportPickup);
  }

  // this is choose_plan---> Airport_Pickup section End

  // this is choose_plan---> Airport_Drop section Start

  carsTypePickup: RequestRide | any;
  fare: RequestRide | any;

  getCarsModelsPickup() {
    this.Service.choosePlanAirPickup().subscribe(
      (res) => {
        this.carsTypePickup = res.models;
        // console.log(this.carsTypePickup[0]);
        if (this.carsTypePickup.length > 0) {
          this.airportPickupForm.controls['airPickFareControl'].setValue(
            this.carsTypePickup[0].fares[0]
          );
          this.airportPickupForm.controls['airPickControl'].setValue(
            this.carsTypePickup[0]
          );
        }
      },
      (error: any) => {
        // console.log(error);
      }
    );
  }

  faresPlanAirporPickup: RequestRide | any;

  clickAirport_PickupFares(faresAirport_Pickup: any) {
    this.faresPlanAirporPickup = faresAirport_Pickup;

    // console.log(this.faresPlanAirporPickup);
    this.airportPickupForm.controls['airPickFareControl'].setValue(
      this.faresPlanAirporPickup[0]
    );
  }

  // firstClick(faresAirport_Pickup: any){
  //   this.faresPlanAirporPickup  = faresAirport_Pickup;

  //   console.log(this.faresPlanAirporPickup );
  // }

  srcPickup: RequestRide | any;
  destiPickup: RequestRide | any;
  // airport_pickup:'' | any;
  // skk:any;

  getSrcDestiAirPickup() {
    this.Service.srcDestiAirportPickup().subscribe((res) => {
      this.srcPickup = res.source;
      // console.log(this.srcPickup);
      this.destiPickup = res.destination;
      // console.log(this.destiPickup);
    });
  }

  optionsDate = {
    locale: moment.locale('ru'),
    // format: 'DD/MM/YYYY HH:mm A',

    buttons: { showClear: true },
    icons: { clear: 'fa fa-trash' },
    minDate: new Date(),
  };

  close() {
    this.showMDL = false;
    this.showMeAfterPICK = false;
    this.showImgPICK = false;
    this.showMePICK = true;
    this.submi = false;
  }

  // onFormSubmit(userForm: NgForm) {

  //   console.log(userForm.value);
  //   console.log(this.date.format('DD/MM/YYYY HH:mm'));

  // }

  // getTime() {
  //   window.alert('Selected time is:' + this.date.format('YYYY/MM/DD HH:mm'));
  // }

  bookingData: RequestRide | any;
  booking_id: any;
  carType: any;
  // hours:any;

  trip_date: any;

  // plan_price:any;
  source: any;
  destination: any;

  getBookings() {
    this.Service.getBookingScreen(this.bkId).subscribe((res) => {
      var bookingDetail = res.data;
      this.booking_id = bookingDetail.booking_id;

      this.carType = bookingDetail.carType;
      this.trip_date = bookingDetail.trip_date;
      this.source = bookingDetail.source;
      this.destination = bookingDetail.destination;
    });
  }

  options: AnimationOptions = {
    path: '../../../assets/checkmark.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    // console.log(animationItem);
  }
}

function crossVal(
  this: any,
  control: AbstractControl
): { [key: string]: any } | null {
  // console.log(this.gazab);

  if (
    control.value !== undefined &&
    (isNaN(control.value) || control.value > 7)
  ) {
    return { crossVal: true };
  }
  return null;
}

// function valueCheck(this:any, control:AbstractControl):{[key:string]:any} | null{

//   if()

//   return null
// }
