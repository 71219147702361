<!-- <div class="col-sm-12 col-md-8 col-lg-9 details"> -->
<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
<!-- Back Link -->
<div class="preloader"></div>
<div class="d-flex align-items-center">
  <a
    style="cursor: pointer"
    (click)="gotoBooking()"
    class="text-dark font-weight-bold mt-3 d-block"
  >
    <img src="assets/images/icons/arrow-left.svg" alt="" class="mr-2" /> </a
  ><b class="mt-3">Booking Details</b>
  <!-- <b class="mt-3"  *ngIf="request_type != 'day rental'">{{source}} to {{destination}}
        </b><b class="mt-3" *ngIf="request_type == 'day rental'"> {{source}}
        </b> -->
</div>

<!-- Row Start -->
<div class="row mt-3">
  <!-- Column Start -->
  <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
    <div class="card h-100">
      <div class="card-header bg-white">
        <!-- <p class="mb-0 text-dark font-weight-bold">Source and Destination.</p> -->
        <p
          *ngIf="request_type == 'airport pickup'"
          class="mb-0 text-dark font-weight-bold"
        >
          Airport Pickup
        </p>
        <p
          *ngIf="request_type == 'airport drop'"
          class="mb-0 text-dark font-weight-bold"
        >
          Airport Drop
        </p>
        <p
          *ngIf="request_type == 'day rental'"
          class="mb-0 text-dark font-weight-bold"
        >
          Day Rental
        </p>
      </div>
      <div class="card-body">
        <div class="d-flex h-100">
          <div
            *ngIf="request_type !== 'day rental'"
            class="left d-flex flex-column justify-content-between align-items-center"
            style="margin-right: 20px; margin-top: 10px; margin-bottom: 18px"
          >
            <div class="round-bg"></div>
            <div class="round-bg small"></div>
            <div class="round-bg small"></div>
            <div class="round-bg small"></div>
            <div class="round-bg small"></div>
            <div class="round-bg small"></div>
            <div class="round-bg"></div>
          </div>
          <div
            *ngIf="request_type == 'day rental' && destination == ''"
            class="right d-flex flex-column h-100 justify-content-between"
          >
            <div class="left mb-4 d-flex">
              <img src="assets/images/day_rent.svg" alt="" class="pr-3" />
              <div class="content">
                <p class="mb-0 font-weight-bold text-dark">{{ source }}</p>
                <!-- <p class="mb-0 text-muted">{{trip_date}}</p> -->
              </div>
            </div>
            <!-- <div class="right d-flex">
                                <img src="assets/images/icons/airplane icon.svg" alt=""   >
                                <div class="content pl-3">
                                    <p class="mb-0 font-weight-bold text-dark">{{destination}}</p>
                                    <p class="mb-0 text-muted">{{trip_end_date}}</p>
                                </div>
                            </div> -->
          </div>
          <div
            *ngIf="request_type == 'day rental' && destination != ''"
            class="d-flex h-100"
          >
			 <div
            class="left d-flex flex-column justify-content-between align-items-center"
            style="margin-right: 20px; margin-top: 4px; margin-bottom: 4px"
          >
            <div class="round-bg"></div>
            <div class="round-bg small"></div>
            <div class="round-bg small"></div>
            <div class="round-bg small"></div>
            <div class="round-bg small"></div>
            <div class="round-bg small"></div>
            <div class="round-bg"></div>
          </div>
            <div class="right d-flex flex-column h-100 justify-content-between">
              <div class="left mb-4 d-flex">
                <img src="assets/images/icons/Hotel.svg" alt="" class="pr-3" />

                <div class="content">
                  <p class="mb-0 font-weight-bold text-dark">{{ source }}</p>
                  <!-- <p class="mb-0 text-muted">{{trip_date}}</p> -->
                </div>
              </div>

              <div class="right d-flex">
                <img src="assets/images/icons/Hotel.svg" alt="" />
                <div class="content pl-3">
                  <p class="mb-0 font-weight-bold text-dark">
                    {{ destination }}
                  </p>
                  <!-- <p class="mb-0 text-muted">{{trip_end_date}}</p> -->
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="request_type !== 'day rental'"
            class="right d-flex flex-column h-100 justify-content-between"
          >
            <div class="left mb-4 d-flex">
              <img
                *ngIf="request_type == 'airport drop'"
                src="assets/images/icons/Hotel.svg"
                alt=""
              />
              <img
                *ngIf="request_type == 'airport pickup'"
                src="assets/images/icons/airplane icon.svg"
                alt=""
              />
              <div class="content" style="padding-left: 30px">
                <p class="mb-0 font-weight-bold text-dark">{{ source }}</p>
                <p class="mb-0 text-muted">{{ date }}</p>
              </div>
            </div>
            <div class="right d-flex">
              <img
                *ngIf="request_type == 'airport drop'"
                src="assets/images/icons/airplane icon.svg"
                alt=""
              />

              <img
                *ngIf="request_type == 'airport pickup'"
                src="assets/images/icons/Hotel.svg"
                alt=""
              />

              <div class="content pl-3">
                <p
                  style="margin-left: 25px"
                  class="mb-0 font-weight-bold text-dark"
                >
                  {{ destination }}
                </p>
                <!-- <p class="mb-0 text-muted">{{trip_end_date}}</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Column End -->

  <!-- Column Start -->
  <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
    <div class="card h-100">
      <div class="card-header bg-white">
        <p class="font-weight-bold text-dark mb-0">Passenger Details</p>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6 mb-3">
            <p class="mb-0 text-muted" style="font-size: 13px">
              Passenger Name
            </p>
            <p class="mb-0" style="font-size: 18px">{{ passengerName }}</p>
          </div>
          <div class="col-6 mb-3">
            <p class="mb-0 text-muted" style="font-size: 13px">
              Contact Details
            </p>
            <a
              class="mb-0"
              href="tel:{{ passengerContact }}"
              style="font-size: 18px"
            >
              <i class="fa fa-phone mr-2" aria-hidden="true"></i
              >{{ passengerContact }}
            </a>
            <!-- <p class="mb-0" style="font-size: 18px;">{{passengerContact}}</p> -->
          </div>
          <div class="col-6">
            <p class="mb-0 text-muted" style="font-size: 13px">
              Passenger Count
            </p>
            <p class="mb-0" style="font-size: 18px">{{ passengerCount }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Column End -->

  <div
    class="col-sm-12 col-md-12 col-lg-12 mb-4"
    *ngIf="
      executive_name != '-' ||
      remarks != '-' ||
      (flight_number != '-' &&
        flight_number != '' &&
        executive_name != '' &&
        remarks != '')
    "
  >
    <div class="card h-100">
      <div class="card-header bg-white">
        <p class="text-dark mb-0">Extra Details</p>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6 mb-3" *ngIf="executive_name != '-'">
            <p class="mb-0 text-muted" style="font-size: 13px">
              Executive Name
            </p>
            <p class="mb-0" style="font-size: 18px">{{ executive_name }}</p>
          </div>

          <div class="col-6" *ngIf="remarks != '-'">
            <p class="mb-0 text-muted" style="font-size: 13px">Remark</p>
            <p class="mb-0" style="font-size: 18px">{{ remarks }}</p>
          </div>

          <div
            class="col-6"
            *ngIf="flight_number != '-' && flight_number != ''"
          >
            <p class="mb-0 text-muted" style="font-size: 13px">Flight Number</p>
            <p class="mb-0" style="font-size: 18px">{{ flight_number }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Column Start -->
  <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
    <div class="card h-100">
      <div class="card-header bg-white">
        <p class="font-weight-bold text-dark mb-0">Trip Details</p>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6 mb-3">
            <p class="mb-0 text-muted" style="font-size: 13px">Booking ID</p>
            <p class="mb-0" style="font-size: 18px">{{ booking_id }}</p>
          </div>
          <div class="col-6 mb-3">
            <p class="mb-0 text-muted" style="font-size: 13px">Booking Date</p>
            <p class="mb-0" style="font-size: 18px">{{ booking_date }}</p>
          </div>
          <div class="col-6">
            <p class="mb-0 text-muted" style="font-size: 13px">
              Trip Start Date & Time
            </p>
            <p class="mb-0" style="font-size: 18px">{{ trip_date }}</p>
          </div>
          <div class="col-6">
            <p class="mb-0 text-muted" style="font-size: 13px">Car Type</p>
            <p class="mb-0" style="font-size: 18px">{{ carType }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Column End -->

  <!-- Column Start -->
  <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
    <div class="card h-100">
      <div class="card-header bg-white">
        <p class="font-weight-bold text-dark mb-0">Driver Details</p>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6 mb-3">
            <p class="mb-0 text-muted" style="font-size: 13px">Driver Name</p>
            <p class="mb-0" style="font-size: 18px">{{ driver }}</p>
          </div>
          <div class="col-6 mb-3">
            <p class="mb-0 text-muted" style="font-size: 13px">
              Driver Contact Number
            </p>

            <a
              class="mb-0"
              href="tel:{{ driver_number }}"
              style="font-size: 18px"
            >
              <i class="fa fa-phone" aria-hidden="true"></i> {{ driver_number }}
            </a>

            <!-- <p class="mb-0" style="font-size: 18px;">{{driver_number}}</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Column End -->
</div>
<!-- Row End -->

<a
  style="font-size: 20px"
  [routerLink]="['/rider/bookings/ongoing_map/', id]"
  class="btn btn-block btn-primary sm-btn-block"
  data-toggle="modal"
  data-target="#exampleModal"
  >Track Trip</a
>

<!-- </div> -->

<div class="backdrop"></div>
