import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Notify } from './notify';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  errorData: {} | any;

  // baseUrl = 'https://evcityride.9brainz.store';
  baseUrl = 'https://admin.evcityride.com'

  constructor(private http: HttpClient) {}

  ids: any;
  varData: any;

  getNotifications(): Observable<Notify> {
    var sallu: any = localStorage.getItem('currentUser');
    this.varData = JSON.parse(sallu);
    // var siko = this.varData.agency_id;

    //  = this.varData.agency_id;

    // var skl = chuku.id;

    return this.http.get<Notify>(
      this.baseUrl + '/api/v1/notifications?agency_id=' + this.varData.agency_id
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.',
    };
    return throwError(this.errorData);
  }
}
