import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipePipe implements PipeTransform {


  transform(arr:any[], searchValue:any){
    if(searchValue){
      searchValue = searchValue.toLowerCase();
      return arr.filter(function (value:any) {
        return value.passenger_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      })
    }
    return arr;
  
  }



  // transform(value: any, searchTerm: any): any {

  //   if(value.length === 0){
  //     return value;
  //   }
  //   return value.filter(function(search:any){
      
  //     return search.passenger_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
  //   })

  // }

}
