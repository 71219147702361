import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BillingComponent } from '../featured/billing/billing.component';
import { BookingsComponent } from '../featured/bookings/bookings.component';
import { ContactServiceComponent } from './contact-service/contact-service.component';
import { InvoiceComponent } from '../featured/invoice/invoice.component';
import { RequestARideComponent } from '../featured/request-a-ride/request-a-ride.component';
import { TrackTripComponent } from '../featured/track-trip/track-trip.component';
import { SkguardGuard } from '../skguard.guard';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { InvoiceDetailsComponent } from '../featured/invoice/invoice-details/invoice-details.component';
import { BookingsDetailsComponent } from '../featured/bookings/bookings-details/bookings-details.component';
import { OngoingDetailsComponent } from '../featured/bookings/ongoing-details/ongoing-details.component';
import { UpcomingDetailsComponent } from '../featured/bookings/upcoming-details/upcoming-details.component';
import { PastTripDetailsComponent } from '../featured/bookings/past-trip-details/past-trip-details.component';
import { AirportDropDetailsComponent } from '../featured/billing/airport-drop/airport-drop-details/airport-drop-details.component';
import { AirportDropComponent } from '../featured/billing/airport-drop/airport-drop.component';
import { AirportPickupDetailsComponent } from '../featured/billing/airport-pickup/airport-pickup-details/airport-pickup-details.component';
import { AirportPickupComponent } from '../featured/billing/airport-pickup/airport-pickup.component';
import { BillingDetailsComponent } from '../featured/billing/billing-details/billing-details.component';
import { SelfDriveDetailsComponent } from '../featured/billing/self-drive/self-drive-details/self-drive-details.component';
import { SelfDriveComponent } from '../featured/billing/self-drive/self-drive.component';
// import { OngoingTrackComponent } from '../featured/bookings/ongoing-details/ongoing-track/ongoing-track.component';
import { TrackTripDetailsComponent } from '../featured/track-trip/track-trip-details/track-trip-details.component';
import { UpcomingComponent } from '../featured/bookings/upcoming/upcoming.component';
import { PastTripsComponent } from '../featured/bookings/past-trips/past-trips.component';
import { OngoingComponent } from '../featured/bookings/ongoing/ongoing.component';
import { ReqLayoutComponent } from '../featured/req-layout/req-layout.component';
import { MapsComponent } from '../featured/maps/maps.component';
import { OngoingTrackComponent } from '../featured/bookings/ongoing-track/ongoing-track.component';

const routes: Routes = [
  {path:'rider', component:MainLayoutComponent, children:[

    {path:'', redirectTo:'/rider/request-a-ride', pathMatch:'full' },

    {path:'request-a-ride',canActivate:[SkguardGuard], component:RequestARideComponent},
    // {path:'bookings', canActivate:[SkguardGuard], component:BookingsComponent},
    // {path:'bookings/ongoing' ,canActivate:[SkguardGuard],  component:OngoingComponent},
    // {path:'bookings/upcoming' ,canActivate:[SkguardGuard], component:UpcomingComponent},
    // {path:'bookings/past' ,canActivate:[SkguardGuard], component:PastTripsComponent},

    {path:'billing',canActivate:[SkguardGuard], component:BillingComponent},
    {path:'invoice', canActivate:[SkguardGuard],component:InvoiceComponent},
    {path:'contact-service',canActivate:[SkguardGuard], component:ContactServiceComponent},
    {path:'track-trip', canActivate:[SkguardGuard],component:TrackTripComponent},
 
    {path:'invoice/invoice_details/:id', canActivate:[SkguardGuard], component:InvoiceDetailsComponent},
    // {path:'bookings/ongoing',  canActivate:[SkguardGuard],component:OngoingComponent},
    

    {path:'booking_details/:id', canActivate:[SkguardGuard], component:BookingsDetailsComponent},
    {path:'bookings/ongoing/ongoing_details/:id',canActivate:[SkguardGuard], component:OngoingDetailsComponent},
    {path:'bookings/upcoming/upcoming_details/:id',canActivate:[SkguardGuard], component:UpcomingDetailsComponent},
    {path:'bookings/past/past_trips_details/:id',canActivate:[SkguardGuard], component:PastTripDetailsComponent},
    // {path:'bookings/ongoing_track',canActivate:[SkguardGuard], component:OngoingTrackComponent},
  
  // Bookings Child Routes End
   
  
  // Billings Child Routes Start
  
    {path:'billing/bill_details/:id',canActivate:[SkguardGuard], component:BillingDetailsComponent},
    {path:'billing/airport_pickup',canActivate:[SkguardGuard], component:AirportPickupComponent},
    {path:'billing/airport_pickup/airport-pickup-details/:id',canActivate:[SkguardGuard], component:AirportPickupDetailsComponent},
    {path:'billing/airport_drop',canActivate:[SkguardGuard], component:AirportDropComponent},
    {path:'billing/airport_drop/airport_drop_details/:id',canActivate:[SkguardGuard], component:AirportDropDetailsComponent},
    {path:'billing/self_drive',canActivate:[SkguardGuard], component:SelfDriveComponent },
    
    {path:'billing/self_drive/self_drive_details/:id', canActivate:[SkguardGuard], component:SelfDriveDetailsComponent},
   
    
  // Billings Child Routes End
  
  
  
  // Invoice Child Route Start
  // Invoice Child Route End
  
  // Maps Routers Start
  {path:'bookings/ongoing_map/:id', canActivate:[SkguardGuard], component:OngoingTrackComponent},
  {path:'track-trip/map/:id', canActivate:[SkguardGuard], component:MapsComponent},
  // Maps Routers End
  



  // Track-Trip Child Route Start
    {path:'track-trip/track_trip_details/:id', canActivate:[SkguardGuard], component:TrackTripDetailsComponent},
  // Track-Trip Child Route End

  {path:'bookings', canActivate:[SkguardGuard], component:BookingsComponent, children:[
    {path:'ongoing' ,canActivate:[SkguardGuard],  component:OngoingComponent},
    {path:'upcoming' ,canActivate:[SkguardGuard], component:UpcomingComponent},
    {path:'past' ,canActivate:[SkguardGuard], component:PastTripsComponent},
  ]},
  
  
  
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReqRideLayoutRoutingModule { }
