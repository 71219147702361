<!-- <div class="col-sm-12 col-md-8 col-lg-9 details"> -->
<!-- 
    <h1 *ngIf="nameSearch != '' " >
        Noting Found
    </h1> -->
    <!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->


<div class="preloader"></div>


    <div class="mt-3 tabMain" style="display: flex !important; white-space: nowrap !important;" >
        
<a class="tabUI" routerLink="/rider/bookings/ongoing"  routerLinkActive="active">Ongoing</a>
        <a class="tabUI" routerLink="/rider/bookings/upcoming"  routerLinkActive="active">Upcoming</a>
        <a class="tabUI" routerLink="/rider/bookings/past" routerLinkActive="active">Past trips</a>

      
       
        
    </div> 



    <!-- <div class="d-flex justify-content-between align-items-center mt-3">
        <div class="my-tabs">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item pt-0 pb-0">
                    <a class="nav-link" id="Ongoing-tab" routerLink="/rider/bookings/ongoing"  routerLinkActive="active" data-toggle="tab"  role="tab" aria-controls="Ongoing" aria-selected="true">Ongoing</a>
                </li>
                <li class="nav-item pt-0 pb-0">
                    <a class="nav-link" id="Upcoming-tab" routerLink="/rider/bookings/upcoming"  routerLinkActive="active" data-toggle="tab"  role="tab"  aria-controls="Upcoming" aria-selected="false">Upcoming</a>
                </li>
                <li class="nav-item pt-0 pb-0">
                    <a class="nav-link" id="Pasttrips-tab" routerLink="/rider/bookings/past" routerLinkActive="active" data-toggle="tab"  role="tab" aria-controls="Pasttrips" aria-selected="false">Past trips</a>
                </li>
            </ul>
        </div>
    
    </div> -->
    <!-- <div class="searchStyle" >
        <div   class="d-flex justify-content-end align-items-center h-100">
            <div class="form-group mb-0 mr-3 w-100" id="search-field">
                <input type="text"    [(ngModel)]="searchData"  class="form-control"
                 id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search Something">
            </div>
            <img src="assets/images/icons/search.svg" alt="" class="mr-3" id="search-icon">
            
        </div>
    
         <div   class="d-flex justify-content-end align-items-center h-100">
            <div class="form-group mb-0 mr-3 w-100" id="search-field">
                <input type="text"    [(ngModel)]="statusSearch"  class="form-control"
                 id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search Something">
            </div>
            <img src="assets/images/icons/search.svg" alt="" class="mr-3" id="search-icon">
            
        </div> 
    </div> -->

    <router-outlet></router-outlet>
    <!-- {{nameSearch}} -->

    <!-- <div class="col-md-12" *ngIf="helowBooking">
        <div style="width: 100%; height: 68vh; display: flex;  justify-content: center; align-items: center; flex-direction: column; " >
    
         <img  src="assets/emptyStats/nooBookings.png" style="width:340px;" alt=""><br>
         <h2><b> No Bookings</b></h2><br>
         <h5>Book your ride and you're ready to go</h5>
        </div>
    
    </div> -->
    <!-- <button (click)="oho()" >Click kr</button> -->
    <div class="row">
        <div class="col-sm-12 col-md-12">
            <!-- Tab panes Start -->
            <div class="tab-content">
                <!-- Ongoing Tab Content Start -->
                <!-- <div *ngIf="showOngoing" class="tab-pane  show active" id="Ongoing" role="tabpanel" aria-labelledby="Ongoing-tab">
                   
                  <div *ngIf="(ongoing|filterPipe:nameSearch) as result" >
                   
                 

                    <a style="cursor: pointer;"
                     *ngFor="let On of result"
                      [routerLink]="['/rider/bookings/ongoing_details/', On.id]" class="text-dark">
                        <div   class="card mt-3"  >
                            <div class="card-body">
                                <div class="parent">
                                    <div class="d-flex align-items-center source-and-destination">
                                        <p  class="mb-0 mr-4">{{On.source}}</p>
                                        <img src="assets/images/icons/Line.svg" alt="" class="mr-4">
                                        <p class="mb-0">{{On.destination}}</p>
                                    </div>
                                    <p class="mb-0 mr-4 client-name">{{On.passenger_name}}</p>
                                    <p class="mb-0 trip-date">{{On.booking_date}}</p>
                                    <img src="assets/images/icons/google maps.svg" (click)="watchPosition()" alt="" class="location-image">
                                </div>
                            </div>
                        </div>
                       
                      
                       
                    </a>
                   
                        <div class="col-md-12" *ngIf="result.length === 0 ">
                            <div style="width: 100%; height: 63vh; display: flex;  justify-content: center; align-items: center; flex-direction: column; " >
                     
                             <img  src="assets/emptyStats/nooBookings.png" style="width:340px;" alt=""><br>
                             <h2><b> No Bookings</b></h2><br>
                             <h5>Book your ride and you're ready to go</h5>
                            </div>
                     
                        </div>

                 
                   

                </div> 
                  
                </div> -->

                <!-- <app-ongoing  *ngIf="showOngoing" [searchData]="searchData" ></app-ongoing> -->
                <!-- Ongoing Tab Content End -->

                <!-- <app-upcoming *ngIf="showUpcoming" [searchData]="searchData" ></app-upcoming> -->
                <!-- Upcoming Tab Content Start -->
               <!-- <div class="tab-pane fade" id="Upcoming" role="tabpanel" aria-labelledby="Upcoming-tab">
                    <a style="cursor: pointer;" *ngFor="let Up of upcoming"
                    [routerLink]="['/rider/bookings/upcoming_details/', Up.id]"  class="text-dark">
                        <div   class="card mt-3"  >
                            <div class="card-body">
                                <div class="parent">
                                    <div class="d-flex align-items-center source-and-destination">
                                        <p  class="mb-0 mr-4">{{Up.source}}</p>
                                        <img src="assets/images/icons/Line.svg" alt="" class="mr-4">
                                        <p class="mb-0">{{Up.destination}}</p>
                                    </div>
                                    <p class="mb-0 mr-4 client-name">{{Up.passenger_name}}</p>
                                    <p class="mb-0 trip-date">{{Up.booking_date}}</p>
                               </div>
                            </div>
                        </div>
                    </a>

                
                </div>   -->
                <!-- Upcoming Tab Content End -->
                <!-- <app-past-trips [searchData]="searchData" *ngIf="showPast"></app-past-trips> -->
                <!-- Past trips Tab Content Start -->
               
                <!-- Past trips Tab Content End -->
            </div>
            <!-- Tab panes End -->
        </div>
    </div>

    <!-- <div class="backdrop"></div> -->
    <div class="drower">
        <div class="drower-header d-flex justify-content-between">
            <a style="cursor: pointer;"><i class="fa fa-times text-dark" aria-hidden="true"></i></a>
            <a  id="close" class="text-dark">Choose Plan</a>
            <a  class="clear-all" onClick="check_uncheck_checkbox(this.checked);">Clear All</a>
        </div>

        <div class="drower-body pl-0 pr-0 mt-0 h-100">
            <div class="tabs-container d-flex h-100">
                <div class="drower-left">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link text-dark active" id="v-pills-Source-tab" data-toggle="pill" href="#v-pills-Source" role="tab" aria-controls="v-pills-Source" aria-selected="true">Source</a>
                        <a class="nav-link text-dark" id="v-pills-Destination-tab" data-toggle="pill" href="#v-pills-Destination" role="tab" aria-controls="v-pills-Destination" aria-selected="false">Destination</a>
                        <a class="nav-link text-dark" id="v-pills-Time-tab" data-toggle="pill" href="#v-pills-Time" role="tab" aria-controls="v-pills-Time" aria-selected="false">Time</a>
                    </div>
                </div>
                <div class="drower-right">
                    <div class="tab-content drower-tab-content h-100" id="v-pills-tabContent">
                        <!-- Sourse Tab Content Start -->
                        <div class="tab-pane fade show active" id="v-pills-Source" role="tabpanel" aria-labelledby="v-pills-Source-tab">
                            <div class="d-flex flex-column pl-3 pt-3">
                                <label for="myCheckbox" class="checkbox">
                                    <input type="checkbox" name="firstcheckbox" id="myCheckbox" class="checkbox__input">
                                    <div class="checkbox__box"></div>
                                    Jolly grant Airport
                                </label>
                                <label for="myCheckbox2" class="checkbox">
                                    <input type="checkbox" name="firstcheckbox" id="myCheckbox2" class="checkbox__input">
                                    <div class="checkbox__box"></div>
                                    Hotel Ramada
                                </label>
                                <label for="myCheckbox3" class="checkbox">
                                    <input type="checkbox" name="firstcheckbox" id="myCheckbox3" class="checkbox__input">
                                    <div class="checkbox__box"></div>
                                    Hotel Safron leaf
                                </label>
                            </div>

                        </div>
                        <!-- Sourse Tab Content End -->
                        <div class="tab-pane fade" id="v-pills-Destination" role="tabpanel" aria-labelledby="v-pills-Destination-tab">
                            <!-- Destination Tab Content Start -->

                            <div class="tab-pane fade show active" id="v-pills-Source" role="tabpanel" aria-labelledby="v-pills-Source-tab">
                                <div class="d-flex flex-column pl-3 pt-3">
                                    <label for="1" class="checkbox">
                                        <input type="checkbox" name="firstcheckbox" id="1" class="checkbox__input">
                                        <div class="checkbox__box"></div>
                                        Jolly grant Airport
                                    </label>
                                    <label for="2" class="checkbox">
                                        <input type="checkbox" name="firstcheckbox" id="2" class="checkbox__input">
                                        <div class="checkbox__box"></div>
                                        Hotel Ramada
                                    </label>
                                    <label for="3" class="checkbox">
                                        <input type="checkbox" name="firstcheckbox" id="3" class="checkbox__input">
                                        <div class="checkbox__box"></div>
                                        Hotel Safron leaf
                                    </label>
                                </div>

                            </div>
                            <!-- Destination Tab Content End -->
                        </div>
                        <div class="tab-pane fade" id="v-pills-Time" role="tabpanel" aria-labelledby="v-pills-Time-tab">
                            <div class="d-flex flex-column pl-3 pt-3">
                                <label for="today" class="radio">
                                    <input type="radio" name="time" id="today" class="radio__input">
                                    <div class="radio__radio"></div>
                                    Today
                                </label>
                                <label for="Yesterday" class="radio">
                                    <input type="radio" name="time" id="Yesterday" class="radio__input">
                                    <div class="radio__radio"></div>
                                    Yesterday
                                </label>
                                <label for="30-days" class="radio">
                                    <input type="radio" name="time" id="30-days" class="radio__input">
                                    <div class="radio__radio"></div>
                                    Last 30 Days
                                </label>
                                <label for="6-months" class="radio">
                                    <input type="radio" name="time" id="6-months" class="radio__input">
                                    <div class="radio__radio"></div>
                                    Last 6 Months
                                </label>
                                <label for="2020" class="radio year" id="year">
                                    <input type="radio" name="time" id="2020" class="radio__input">
                                    <div class="radio__radio"></div>
                                    2020
                                </label>
                            </div>

                            <div class="mt-4 pl-3 pr-3 d-none time-fields">
                                <form action="">
                                    <div class="form-layout">
                                        <div class="form-group">
                                            <input type="date" class="input-field" placeholder="Starting date">
                                            <label>Starting date</label>
                                        </div>
                                    </div>
                                    <div class="form-layout">
                                        <div class="form-group">
                                            <input type="date" class="input-field" placeholder="Ending date">
                                            <label>Ending date</label>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                
            </div>
        </div>



        
    </div>

    <section class="backdrop"></section>
    <!-- <router-outlet></router-outlet> -->


    <!-- <button routerLink="/hf"  class="btn btn-danger">Details</button> -->
<!-- </div> -->