import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-our-founders',
  templateUrl: './web-our-founders.component.html',
  styleUrls: ['./web-our-founders.component.css']
})
export class WebOurFoundersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
