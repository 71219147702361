<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
<div class="preloader"></div>

<div
  class="tab-pane"
  id="selfdrive"
  role="tabpanel"
  aria-labelledby="self-drive-tab"
>
  <form [formGroup]="airDayRentalPostForm" (ngSubmit)="airDayPostSubmit()">
    <div class="row">
      <div class="col-md-6">
        <div class="col-sm-12 col-md-12">
          <a
            (click)="clickDay_RentalFares(carsTypeDayRental[0].fares)"
            class="cp-container mb-4"
            style="height: 65px"
            id="choose-plan-tri-3"
          >
            <p class="mb-0" *ngIf="showMeDayRental">Choose Plan</p>
            <p class="mb-0" *ngIf="showMeAfterDayRental">
              {{ dayRentalFareOBJ.kilometer }}KM - ₹{{
                dayRentalFareOBJ.basic_fare
              }}
            </p>
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </a>

          <!-- <div *ngIf="submitted">
            <div
              class="alert alert-danger"
              *ngIf="showMeAfterDayRental == false"
            >
              Choose Plan First
            </div>
          </div> -->
        </div>

        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field"
                placeholder="Source"
                name="source"
                formControlName="source"
                maxlength="50"
              />
              <label>Source</label>
              <div></div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field"
                placeholder="Passenger Name"
                name="passenger_name"
                formControlName="passenger_name"
                (keypress)="keyPressAlphaNumericWithCharacters($event)"
                maxlength="50"
              />
              <label>Passenger Name</label>
              <!-- <div
                    class="alert alert-danger"
                      *ngIf="submitted && f.passenger_name.invalid"
                    >
                      Passenger_Name Field Is Required
                    </div> -->
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field"
                placeholder="Remarks"
                name="remarks"
                formControlName="remarks"
                (keypress)="keyPressAlphaNumericWithCharacters($event)"
                maxlength="50"
              />
              <label>Remarks</label>
              <div></div>
            </div>
          </div>
        </div>

        <!-- <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field"
                placeholder="Flight Number"
                name="flight_number"
                formControlName="flight_number"
                required
                maxlength="50"
              />
              <label>Flight Number</label>
              <div></div>
            </div>
          </div>
        </div> -->

        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <p
                style="
                  position: relative !important;
                  margin-bottom: 11px;
                  margin-left: 2px;
                "
              >
                Passenger Mobile Number
              </p>
              <ngx-intl-tel-input
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="false"
                [searchCountryFlag]="true"
                [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [phoneValidation]="true"
                [separateDialCode]="separateDialCode"
                [numberFormat]="PhoneNumberFormat.National"
                name="passenger_contact_number"
                formControlName="passenger_contact_number"
              >
              </ngx-intl-tel-input>
            </div>
            <div *ngIf="submitted">
              <!-- <div class="alert alert-danger" *ngIf="airDayRentalPostForm.controls['passenger_contact_number']?.errors?.required">
                        Contact Number Required
                      </div>   -->

              <div
                class="alert alert-danger"
                *ngIf="
                  airDayRentalPostForm.controls['passenger_contact_number']
                    ?.errors?.validatePhoneNumber
                "
              >
                Invalid Phone
              </div>
            </div>
            <!-- <div
                class="alert alert-danger"
                *ngIf="submitted&& f.passenger_contact_number.errors"
              >
                <span *ngIf="f.passenger_contact_number.errors.required"
                  >Phone is required & </span
                >
                <span
                  >{{!airDayRentalPostForm.controls['passenger_contact_number'].invalid
                  ? 'Right Number' : 'Wrong Number' }}</span
                >
              </div> -->
          </div>
        </div>

        <div class="col-sm-12 col-md-12 radio-container mb-4">
          <label for="bill-to-agency-3" class="radio">
            <input
              type="radio"
              name="payment_detail"
              id="bill-to-agency-3"
              class="radio__input"
              value="bill_to_agency"
              formControlName="payment_detail"
            />
            <div class="radio__radio"></div>
            Bill to agency
          </label>
          <label for="collect-cash-3" class="radio">
            <input
              type="radio"
              name="payment_detail"
              id="collect-cash-3"
              class="radio__input"
              value="collect_cash"
              formControlName="payment_detail"
            />
            <div class="radio__radio"></div>
            Collect Cash
          </label>
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="footer-action">
            <button
              class="btn btn-primary btn-block submit-button"
              style="height: 65px"
              type="submit"
              [disabled]="airDayRentalPostForm.invalid"
            >
              Submit Request
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <!-- <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4"> -->
        <!-- for google search get source -->
        <!-- ngx-google-places-autocomplete -->
        <!-- (onAddressChange)="handleChange($event)" -->
        <!-- <input
                style="height: 65px"
                type="text"
                class="form-control"
                name="source"
                formControlName="source"
                placeholder="Source"
              />

              <label>Source</label>
            </div>
            <div> -->
        <!-- <div
                      class="alert alert-danger"
                        *ngIf="submitted && f.source.invalid"
                      >
                        Source Field Is Required
                      </div> -->
        <!-- </div>
          </div> -->
        <!-- {{kayam}} -->
        <!-- <pre *ngIf="selectedPlace">
    {{selectedPlace.formatted_address}}
  </pre>
  <textarea *ngIf="selectedPlace" rows="20" cols="100">
    {{json.stringify(selectedPlace)}}
  </textarea> -->
        <!-- </div> -->

        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <label>Start Date</label>
              <div
                class="input-group date"
                data-target-input="nearest"
                NgTempusdominusBootstrap
              >
                <input
                  style="height: 65px"
                  class="form-control"
                  name="start_date"
                  formControlName="start_date"
                  placeholder="Start Date"
                  [options]="Start_Date"
                  NgTempusdominusBootstrapInput
                  [(ngModel)]="start_date"
                  (ngModelChange)="update()"
                  type="text"
                />

                <div class="input-group-append" NgTempusdominusBootstrapToggle>
                  <div class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-sm-12 col-md-12">
          <div class="form-group mb-4">
            <div
              class="input-group date"
              data-target-input="nearest"
              NgTempusdominusBootstrap
            >
              <input
                style="height: 65px"
                class="form-control"
                name="end_date"
                formControlName="end_date"
                [options]="End_Date"
                NgTempusdominusBootstrapInput
                type="text"
                placeholder="End Date"
                [(ngModel)]="end_date"
                (ngModelChange)="update()"
              />
              <div class="input-group-append" NgTempusdominusBootstrapToggle>
                <div class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
            <div class="alert alert-danger" *ngIf="errMsg">
              <span>
                {{ errShow.message }}
              </span>
            </div>
          </div>
        </div> -->

        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field disab"
                name="Destination"
                formControlName="destination"
                placeholder="Destination"
              />

              <label>Destination</label>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                class="input-field"
                placeholder="Passenger Count"
                name="total_passenger"
                formControlName="total_passenger"
                autocomplete="off"
                maxlength="1"
              />
              <!-- <h1>{{dayRentalPlansOBJ.max_passenger}}</h1> -->
              <!-- <div *ngIf="airDayRentalPostForm.get('total_passenger').dirty && airDayRentalPostForm.get('total_passenger').errors && airDayRentalPostForm.get('total_passenger').errors.passenger">
                          Passenger min {{min}} and max {{max}}</div> -->
              <label>Passenger Count</label>
              <!-- <div class="alert alert-danger" *ngIf="airDayRentalPostForm.get('total_passenger').dirty && airDayRentalPostForm.get('total_passenger').errors && airDayRentalPostForm.get('total_passenger').errors.crossVal" >
                          Total Passenger must 7
                        </div> -->
              <div
                class="d-flex w-100 align-items-center text-center justify-content-center mt-2"
              >
                <div
                  *ngIf="
                    dayRentalPlansOBJ.max_passenger > 0 ||
                    airDayRentalPostForm.get('total_passenger').errors?.crossVal
                  "
                >
                  <div
                    *ngIf="
                      airDayRentalPostForm.get('total_passenger').value >
                      dayRentalPlansOBJ.max_passenger
                    "
                  >
                    <p
                      class="text-danger mr-2"
                      style="display: inline-block; text-align: center"
                    >
                      Total Passenger Limit is
                      {{ dayRentalPlansOBJ.max_passenger }}
                    </p>
                  </div>
                </div>
                <div *ngIf="dayRentalPlansOBJ.max_passenger == ''">
                  <div
                    *ngIf="
                      airDayRentalPostForm.get('total_passenger').errors
                        ?.crossVal
                    "
                  >
                    <p
                      class="text-danger"
                      style="display: inline-block; text-align: center"
                    >
                      7
                    </p>
                  </div>
                </div>
              </div>
              <!-- {{airDayRentalPostForm.get('total_passenger').value}} -->

              <!-- <div *ngIf="airDayRentalPostForm.get('total_passenger') " >

                          </div> -->
              <!-- <div *ngIf="f.total_passenger.errors?.max >= dayRentalPlansOBJ.max_passenger">
                          Maximum number can be 5.
                    </div>    -->
              <!-- <div *ngIf="dayRentalPlansOBJ.max_passenger == '' " >
                          Maximum Number can be 7
                    </div> -->

              <!-- <div *ngIf="showImgDayRent" >
                          <div class="alert alert-danger" *ngIf="submitted && f.total_passenger.invalid" >
                              Passenger Required...
                           <br>
                           <span class="alert alert-warning" *ngIf="f.total_passenger.errors.max">
                              Passenger vdhi gya
                           </span>
                       </div>

                      </div> -->
              <!-- <div *ngIf="let skk of carsTypeDayRental" >

                      </div> -->
            </div>
          </div>
        </div>

        <!--<div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field"
                placeholder="Executive Name"
                name="executive_name"
                formControlName="executive_name"
                (keypress)="keyPressAlphaNumericWithCharacters($event)"
                maxlength="50"
              />
              <label>Executive Name</label>
              <div></div>
            </div>
          </div>
        </div>-->

        <div class="col-sm-12 col-md-12">
          <div
            class="card car-card w-50 mb-4"
            style="max-width: 155px !important"
            *ngIf="showImgDayRent"
          >
            <div class="card-body drower-card-body p-0">
              <img
                name="image"
                *ngIf="dayRentalPlansOBJ.image != ''"
                [src]="dayRentalPlansOBJ.image"
                alt="Image J nthi Bhai"
                style="height: 100%; width: 100%"
              />
              <img
                name="image"
                *ngIf="dayRentalPlansOBJ.image == ''"
                src="assets/images/emptyImage.svg"
                alt="Image J nthi Bhai"
                style="height: 100%; width: 100%"
              />

              <a name="Name" class="car-button">{{ dayRentalPlansOBJ.name }}</a>
              <!-- <a  name="Name"  class="car-button">{{dayRentalPlansOBJ.max_passenger}}</a> -->

              <!-- <a class="alert alert-info" >{{dayRentalPlansOBJ.max_passenger}}</a> -->
            </div>
          </div>
        </div>
      </div>

      <!-- (ngModelChange)="changedValueStart($event)" -->

      <!-- <div class="col-sm-12 col-md-6">
        <div class="form-layout"> -->
      <!-- [isLessThanStartDate]="disabledDate"     -->

      <!-- </div>
      </div> -->

      <div class="text-danger" *ngIf="error.isError">
        {{ error.errorMessage }}
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade"
  *ngIf="showMDL"
  [ngClass]="{ classic: classModal }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg modalStyle"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirm Booking</h5>
        <button
          type="button"
          (click)="closeModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="image-container d-flex justify-content-center">
          <ng-lottie
            class="lotieAnim"
            [options]="options"
            (animationCreated)="animationCreated($event)"
          ></ng-lottie>
        </div>
        <h5 class="text-center submitMsg" style="font-weight: bold">
          Booking request submitted successfully...
        </h5>
        <p class="text-center">Admin will assign driver shortly.</p>
        <hr />

        <p class="text-center text-primary" style="font-weight: bold">
          Booking ID : {{ booking_id }}
        </p>
        <div class="row">
          <!-- <div class="col-md-12 col-sm-12">
            <div class="card-body">
              <div class="d-flex h-100">
                <div
                  class="right d-flex flex-column h-100 justify-content-between"
                  *ngIf="destination == ''"
                >
                  <div class="left mb-4 d-flex">
                    <img src="assets/images/icons/Hotel.svg" alt="" />
                    <div class="content" style="padding-left: 30px">
                      <p class="mb-0 font-weight-bold text-dark">
                        {{ source }}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="right d-flex plr-30 flex-column h-100 justify-content-between"
                  *ngIf="destination != ''"
                >
                  <div class="left mb-4 d-flex">
                    <img src="assets/images/icons/Hotel.svg" alt="" />
                    <div class="content" style="padding-left: 30px">
                      <p class="mb-0 font-weight-bold text-dark">
                        {{ source }}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="right d-flex flex-column h-100 justify-content-between"
                  *ngIf="destination != ''"
                >
                  <div class="left mb-4 d-flex">
                    <img src="assets/images/icons/Hotel.svg" alt="" />
                    <div class="content" style="padding-left: 30px">
                      <p class="mb-0 font-weight-bold text-dark">
                        {{ destination }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
			<div class="col-md-12 col-sm-12">
            <div class="card-body">
              <div class="d-flex h-100">
                <div
                  class="left d-flex flex-column justify-content-between align-items-center" *ngIf="destination != ''"
                  style="
                    margin-right: 20px;
                    margin-top: 10px;
                    margin-bottom: 18px;
                  "
                >
                  <div class="round-bg"></div>
                  <div class="round-bg small"></div>
                  <div class="round-bg small"></div>
                  <div class="round-bg small"></div>
                  <div class="round-bg small"></div>
                  <div class="round-bg small"></div>
                  <div class="round-bg"></div>
                </div>
                <div
                  class="right d-flex flex-column h-100 justify-content-between" 
                  style="min-height: 115px"
                >
                  <div class="right d-flex">
                    <img src="assets/images/icons/Hotel.svg" alt="" />
                    <div class="content pl-3">
                      <p class="mb-0 font-weight-bold text-dark">
                        {{ source }}
                      </p>
                      <!-- <p class="mb-0 text-muted">12:00 PM | Sat, 8 Jan 2021</p> -->
                    </div>
                  </div>
                  <div class="left mb-4 d-flex" *ngIf="destination != ''">
                    <img src="assets/pinSmall.png" alt="" />
                    <div class="content" style="padding-left: 30px">
                      <p class="mb-0 font-weight-bold text-dark">
                        {{ destination }}
                      </p>
                      <!-- <p class="mb-0 text-muted">01:00 PM | Sat, 8 Jan 2021</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <p>Car Type</p>
            <p style="font-weight: bold">{{ carType }}</p>
          </div>

          <div class="col-sm-12 col-md-6">
            <p>Start Trip Date & Time</p>
            <p style="font-weight: bold">{{ trip_date }}</p>
          </div>
          <!-- <div class="col-sm-12 col-md-4">
            <p>End Trip Date & Time</p>
            <p style="font-weight: bold">{{ trip_end_date }}</p>
          </div> -->
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <button
          id="hideModel"
          routerLink="/rider/bookings/upcoming"
          type="button"
          class="btn btn-primary btn-block"
        >
          View Bookings
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="drower" id="car-type-3">
    <div class="drower-header">
        <a  id="close" class="text-dark"><i class="fa fa-arrow-left text-dark mr-2">

        </i></a>
        <b>Choose Plan</b>
    </div>

    <div class="drower-body">
        <p>Select Car Type *</p>

        <form [formGroup]="dayRentalForm" (ngSubmit)="postRental()" class="mt-2">
            <div class="d-flex mb-3" style="overflow-x: auto; overflow-y: hidden;">
                <div class="mr-3" style="width: 180px;"   *ngFor="let DayRent of carsTypeDayRental">
                    <label [for]="DayRent.id" class="radio mb-0"   >
                        <input  type="radio" name="dayRentControl"
                         [id]="DayRent.id"
                         class="radio__input car-radio d-none"  [value]="DayRent"
                          formControlName="dayRentControl" >


                        <div class="radio__radio d-none"></div>

                        <div class="card car-card"  (click)="clickDay_RentalFares(DayRent.fares)"  >
                            <div class="card-body drower-card-body p-0">
                                <img [src]="DayRent.image" alt="Aela Bhai Image Nthi" class="img-fluid">
                                <a  class="car-button">{{DayRent.name}}</a>

                            </div>
                        </div>

                    </label>
                </div>

            </div>




    <div class="container-fluid car-plan">
            <div class="row">
              <p>Plan *</p>


                <div class="col-md-12 pl-0 pr-0"  *ngFor="let CarPlanLists of faresPlanDayRental ">
                    <label for="{{CarPlanLists.fare_id}}" class="form-check-label">
                        <div class="card mt-4"  >
                          <div class="card-body">
                              <p style="font-size: 22px;" >{{CarPlanLists.name}}</p>
                                <div class="d-flex justify-content-between">
                                    <h3>{{CarPlanLists.kilometer}} KM - {{CarPlanLists.hours}} Hours</h3>
                                    <h3 class="text-success">₹ {{CarPlanLists.basic_fare}}</h3>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="left d-flex align-items-center">
                                        <img src="assets/images/infoicon.svg" class="svgSize" alt="">
                                        <p class="plan-details">{{CarPlanLists.gst_charge}}% GST Charged @ the time of billing, If {{CarPlanLists.extra_kilometer}} KM exceeds ₹ {{CarPlanLists.rs_per_km}} Per KM &amp; will be Charged</p>
                                    </div>
                                    <div class="right">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="dayRentPlanControl" id="{{CarPlanLists.fare_id}}"
                                           [value]="CarPlanLists" formControlName="dayRentPlanControl" [checked]
                                           >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>



                <button type="submit"  class="btn btn-primary btn-block my-3 drower-button" >Apply</button>
            </div>
        </div>
      </form>
    </div>

  </div> -->

<div class="drower" id="car-type-3">
  <div class="drower-header fixed-top">
    <a id="close" class="text-dark"
      ><i class="fa fa-arrow-left text-dark mr-2"></i
    ></a>
    <b>Choose Plan</b>
  </div>
  <form [formGroup]="dayRentalForm" (ngSubmit)="postRental()" class="mt-2">
    <div class="drower-body">
      <p class="mt-3" style="margin-bottom: 10px !important">
        Select Car Type *
      </p>
      <div class="d-flex mb-4" style="overflow-x: auto; overflow-y: hidden">
        <div class="mr-3 card-width" *ngFor="let DayRent of carsTypeDayRental">
          <label [for]="DayRent.id" class="radio mb-0">
            <input
              type="radio"
              name="dayRentControl"
              [id]="DayRent.id"
              class="radio__input car-radio d-none"
              [value]="DayRent"
              formControlName="dayRentControl"
            />
            <div class="radio__radio d-none"></div>
            <div
              class="card car-card"
              (click)="clickDay_RentalFares(DayRent.fares)"
            >
              <div class="card-body drower-card-body p-0">
                <img
                  *ngIf="DayRent.image != ''"
                  [src]="DayRent.image"
                  alt="Sorry, Image is not available"
                  class="img-fluid"
                />
                <img
                  *ngIf="DayRent.image == ''"
                  src="assets/images/emptyImage.svg"
                  alt="Sorry, Image is not available"
                  class="img-fluid"
                />

                <a class="car-button">{{ DayRent.name }}</a>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="container-fluid car-plan">
        <div class="row">
          <p>Plan *</p>
          <div
            class="col-md-12 pl-0 pr-0"
            *ngFor="let CarPlanLists of faresPlanDayRental"
          >
            <label for="{{ CarPlanLists.fare_id }}" class="form-check-label">
              <div class="card mb-4">
                <div class="card-body">
                  <p style="font-size: 22px">{{ CarPlanLists.name }}</p>
                  <div class="d-flex justify-content-between">
                    <h3>
                      {{ CarPlanLists.kilometer }} KM -
                      {{ CarPlanLists.hours }} Hours
                    </h3>
                    <h3 class="text-success">
                      ₹ {{ CarPlanLists.basic_fare }}
                    </h3>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="left d-flex align-items-center">
                      <img
                        src="assets/images/infoicon.svg"
                        class="svgSize"
                        alt=""
                      />
                      <p class="plan-details">
                        {{ CarPlanLists.gst_charge }}% GST will be charged the
                        time of billing, If
                        {{ CarPlanLists.extra_kilometer }} KM exceeds then ₹
                        {{ CarPlanLists.rs_per_km }} Per KM &amp; ₹{{
                          CarPlanLists.rs_per_hour
                        }}
                        for every hour will be Charged
                      </p>
                    </div>
                    <div class="right">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="dayRentPlanControl"
                          id="{{ CarPlanLists.fare_id }}"
                          [value]="CarPlanLists"
                          formControlName="dayRentPlanControl"
                          [checked]
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="drower-footer fixed-bottom">
      <button
        type="submit"
        [disabled]="this.dayRentalForm.invalid"
        class="btn btn-primary btn-block"
      >
        Apply
      </button>
    </div>
  </form>
</div>

<section class="backdrop"></section>
