// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'invoiceFilter'
// })
// export class InvoiceFilterPipe implements PipeTransform {

//   transform(value: unknown, ...args: unknown[]): unknown {
//     return null;
//   }

// }


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceFilter'
})
export class InvoiceFilterPipe implements PipeTransform {


  transform(value:any, args?:any):any{
    // console.log(args);

    if(!args){
      return value
    }
    // if(statusValue){
    //   statusValue = statusValue.toLowerCase();
    //   return arr.filter(function (value:any) {
    //     return value.invoice_id.toLowerCase().indexOf(statusValue.toLowerCase()) > -1;
    //   })
    // }
    return value.filter((item: { invoice_id: string | any[]; })=>item.invoice_id.indexOf(args) > -1)
  
  }





}
