import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-web-download-app',
  templateUrl: './web-download-app.component.html',
  styleUrls: ['./web-download-app.component.css']
})
export class WebDownloadAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      $(".preloader").css('display','none');
      $('.preloader').fadeOut('slow');
    }, 1000);

    $("#navbarSupportedContent").removeClass('show');

    // this.spinner.show();

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);
  }

}
