import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReqRideLayoutRoutingModule } from './req-ride-layout-routing.module';
import { HeaderComponent } from './header/header.component';
import { MainSidebarComponent } from './main-sidebar/main-sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthsService } from '../shared/auths.service';
import { SkguardGuard } from '../skguard.guard';
import { HttpInterceptorInterceptor } from '../http-interceptor.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { FeaturedModule } from '../featured/featured.module';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { ContactServiceComponent } from './contact-service/contact-service.component';
// import { RouterModule } from '@angular/router';
// import { AgmCoreModule } from '@agm/core';
// import { AgmDirectionModule } from 'agm-direction';
// console.warn('Req Ride Load');


@NgModule({
  declarations: [
    HeaderComponent,
    MainSidebarComponent,
    MainLayoutComponent,
    ContactServiceComponent
  ],
  imports: [
  CommonModule,
    ReqRideLayoutRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FeaturedModule,
    // RouterModule,
    ToastrModule.forRoot({
      timeOut:2000,
        progressBar:true,
        progressAnimation:'increasing',
        preventDuplicates:true
    })

  ],
  providers:[SkguardGuard,AuthsService,{
    provide:HTTP_INTERCEPTORS,
    useClass:HttpInterceptorInterceptor,
    multi:true
  }]
})
export class ReqRideLayoutModule { }
