<section class="offer-style-one">
    <div class="container">
        <div class="block-title text-center">
            <div class="dot-line"></div>
            <p>Welcome to EV cityride</p>
            <h2>Numbers speak</h2>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-3">
                <div class="single-offer-one hvr-float-shadow">
                    <div class="text-block">
                        <img src="assets/images/Evcity/km-driven.svg" alt="" class="mb-4 km">
                        <h3 class="mb-0"><a href="#">8700</a></h3>
                        <p class="mb-0">KM Driven</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="single-offer-one hvr-float-shadow">
                    <div class="text-block">
                        <img src="assets/images/Evcity/people-dropped.svg" alt="" class="mb-4 pe">
                        <h3 class="mb-0"><a href="#">4978</a></h3>
                        <p class="mb-0">People Dropped</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="single-offer-one hvr-float-shadow">
                    <div class="text-block">
                        <img src="assets/images/Evcity/Taxis-&-Drivers.svg" alt="" class="mb-4 ta">
                        <h3 class="mb-0"><a href="#">800</a></h3>
                        <p class="mb-0">Taxis & Drivers</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="single-offer-one hvr-float-shadow">
                    <div class="text-block">
                        <img src="assets/images/Evcity/Happy-People.svg" alt="" class="mb-4 hp">
                        <h3 class="mb-0"><a href="#">2480</a></h3>
                        <p class="mb-0">Happy People</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>