import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { BookingListing } from '../booking-listing';
import { BookingListingServiceService } from '../booking-listing-service.service';
import * as $ from 'jquery';
import { TrackServiceService } from '../../track-trip/track-service.service';
// import { TrackServiceService } from 'Temp/src/app/featured/track-trip/track-service.service';

@Component({
  selector: 'app-ongoing-details',
  templateUrl: './ongoing-details.component.html',
  styleUrls: ['./ongoing-details.component.css'],
})
export class OngoingDetailsComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleHeader: Title,
    private Service: BookingListingServiceService,
    public ServiCE: TrackServiceService
  ) {}
  id: any;

  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }

  ngOnInit(): void {
    this.ServiCE.anonymousLogin();

    this.setTitle('Bookings Ongoing Details | Evcityride');

    this.id = this.route.snapshot.params.id;
    // console.log(this.id);

    this.getOngoingDetails();

    $(document).ready(function () {
      $('.menu-icon').click(function () {
        $('.aside-menu').show();
      });

      $('.backdrop').click(function () {
        $('.aside-menu').hide();
        $('.backdrop').hide();
      });
    });
  }

  detailsOngoing: BookingListing | any;
  source: any;
  destination: any;
  trip_date: any;
  trip_end_date: any;
  passengerName: any;
  passengerContact: any;
  passengerCount: any;
  booking_id: any;
  booking_date: any;
  carType: any;
  driver: any;
  driver_number: any;
  date: any;
  request_type: any;
  executive_name: any = '';
  remarks: any = '';
  flight_number: any = '';

  getOngoingDetails() {
    // this.spinner.show();

    this.Service.getOngoingBookingsDetails(this.id).subscribe((res) => {
      this.detailsOngoing = res.data;

      this.source = this.detailsOngoing.source;
      this.destination = this.detailsOngoing.destination;
      this.trip_date = this.detailsOngoing.trip_date;
      this.trip_end_date = this.detailsOngoing.trip_end_date;
      this.passengerName = this.detailsOngoing.passenger_name;
      this.passengerContact = this.detailsOngoing.passenger_contact_number;
      this.passengerCount = this.detailsOngoing.total_passenger;
      this.booking_id = this.detailsOngoing.booking_id;
      this.booking_date = this.detailsOngoing.booking_date;
      this.carType = this.detailsOngoing.carType;
      this.driver = this.detailsOngoing.driver;
      this.driver_number = this.detailsOngoing.driver_number;
      this.date = this.detailsOngoing.date;
      this.request_type = this.detailsOngoing.request_type;
      this.executive_name = this.detailsOngoing.executive_name;
      this.remarks = this.detailsOngoing.remarks;
      this.flight_number = this.detailsOngoing.flight_number;
      // console.log(this.detailsOngoing);
      setTimeout(() => {
        $('.preloader').css('display', 'none');
        $('.preloader').fadeOut('slow');
      }, 1000);
      // this.spinner.hide();
    });
  }

  gotoBooking() {
    this.router.navigateByUrl('/rider/bookings/ongoing');
    // console.log("Booking Enter...");
  }
}
