import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Billing } from './billing';

@Injectable({
  providedIn: 'root',
})
export class BillingServiceService {
  baseUrl = "https://admin.evcityride.com";
  // baseUrl = 'https://evcityride.9brainz.store/';

  constructor(private http: HttpClient) {}

  getBill(agency_id: any): Observable<Billing> {
    return this.http
      .post<Billing>(this.baseUrl + '/api/v1/billing', agency_id)
      .pipe(retry(0), catchError(this.errorHandle));
  }

  requestTypePick(): Observable<Billing> {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);
    var std = <any>localStorage.getItem('sDate');
    var etd = <any>localStorage.getItem('eDate');

    // let stDate = JSON.stringify(std);
    // let etDate = JSON.stringify(etd);

    return this.http
      .get<Billing>(
        this.baseUrl +
          '/api/v1/trips?agency_id=' +
          localStore.agency_id +
          '&type=airport_pickup&start_date=' +
          std +
          '&end_date=' +
          etd
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  requestTypeDrop(): Observable<Billing> {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);
    var std = <any>localStorage.getItem('sDate');
    var etd = <any>localStorage.getItem('eDate');
    return this.http
      .get<Billing>(
        this.baseUrl +
          '/api/v1/trips?agency_id=' +
          localStore.agency_id +
          '&type=airport_drop&start_date=' +
          std +
          '&end_date=' +
          etd
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  requestTypeDayRental(): Observable<Billing> {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);
    var std = <any>localStorage.getItem('sDate');
    var etd = <any>localStorage.getItem('eDate');
    return this.http
      .get<Billing>(
        this.baseUrl +
          '/api/v1/trips?agency_id=' +
          localStore.agency_id +
          '&type=day_rental&start_date=' +
          std +
          '&end_date=' +
          etd
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  errorHandle(error: HttpErrorResponse) {
    return throwError(error.message || 'Serve Error');
  }
}
