<!-- <div class="col-sm-12 col-md-8 col-lg-9 details"> -->
          
    

    <!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->


<div class="preloader"></div>

<!-- <h4>
    Linked datepickers
  </h4>
  <div class="row">
      <form [formGroup]="billPostForm" (ngSubmit)="onSubmit()">

    <div class="col-sm-12">


        <div class="form-group">
            <div class="input-group date" data-target-input="nearest" NgTempusdominusBootstrap>
                <input
                  class="form-control"
                  [(ngModel)]="startDate"
                  (ngModelChange)="update()"
                  [options]="startOptions"
                  formControlName="startDate"
                  NgTempusdominusBootstrapInput
                  type="text"
                  name="startDate"
                />
                <div class="input-group-append" NgTempusdominusBootstrapToggle>
                    <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12">
        <div class="form-group">
            <div class="input-group date" data-target-input="nearest" NgTempusdominusBootstrap>
                <input
                  class="form-control"
                  [(ngModel)]="endDate"
                  (ngModelChange)="update()"
                  [options]="endOptions"
                  NgTempusdominusBootstrapInput
                  formControlName="endDate"
                  type="text"
                  name="endDate"
                />
                <div class="input-group-append" NgTempusdominusBootstrapToggle>
                    <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                </div>
            </div>
        </div>
    </div>
    <button type="submit" class="btn btn-success btn-block" >Click To Go</button>
</form>
<div *ngIf="hallo"  >
    {{crazy.startDate}} -- {{crazy.endDate}} 

</div>
  </div>  -->

    <!-- Trips Start -->

    <div class="row mt-3 mb-3 d-flex align-items-center">
        <div class="col-10 col-md-6">
            <a style="cursor: pointer;" (click)="gotoBill()" class="text-dark d-flex">
                <img src="assets/images/icons/arrow-left.svg" alt="" class="mr-2"> 
                <div class="details">
                    <p class="text-dark font-weight-bold mb-0">Airport Pickup</p>
                    <!-- <p class="text-dark mb-0">01/01/2021 to 07/01/2020</p> -->
                </div>
            </a>
        </div>
        <!-- <div class="col-2 col-md-6">
            <div class="right d-flex justify-content-end">
                <div class="form-group mb-0 mr-3 w-100" id="search-field">
                    <input type="email" class="form-control" (ngModelChange)="changeEvent($event)"
                      [(ngModel)]="searchBillData" id="exampleInputEmail1" 
                      aria-describedby="emailHelp" placeholder="Search Something"
                     >
                </div>
                <img src="assets/images/icons/search.svg"  alt="" class="mr-3" id="search-icon">
            </div>
        </div> -->
    </div>
    <!-- Trips End -->
    <h1 class="alert alert-danger" *ngIf="showMsg" >{{nodataMsg}}</h1>

    <!-- Row Start -->
    <div class="row pickup-details-card">
        <!-- <div class="col-sm-12 col-md-12">
            <p style="color: #5D5D5D;" class="pl-3">01/01/2021</p>
        </div> -->
        <div class="col-sm-12 col-md-12" style="height: 66vh; overflow-y: auto;" >
            <!-- <div  *ngIf="(pickBill|filterPipe:searchBillData) as result"> -->

   
            <a *ngFor="let AirPick of pickBill " [routerLink]="['/rider/billing/airport_pickup/airport-pickup-details/', AirPick.id]" class="text-dark">

                <!-- Change Card for Solve Layout Problem -->
                <div class="card mt-3 trip-card" data-toggle="modal" data-target="#exampleModal">
                    <div class="card-body">
                        <div class="Source-and-destination">
                            <p class="text-truncate w-50 font-weight-bold">{{AirPick.source}}</p>
                            <img src="assets/images/icons/Line.svg" alt="" class="ml-4 mr-4">
                            <p class="text-truncate w-50 font-weight-bold">{{AirPick.destination}}</p>
                        </div>
                        <div class="trip-details">
                            <p class="client-name text-truncate w-25">{{AirPick.passenger_name}}</p>
                            <p class="trip-date text-truncate w-50">{{AirPick.booking_date}}</p>
                            <p class="text-success">{{AirPick.final_amount}}</p>
                        </div>
                    </div>
                </div>
                <!-- Change Card for Solve Layout Problem -->
            </a>
            <!-- <div class="col-md-12" *ngIf="result.length === 0 ">
                <div style="width: 100%; height: 71vh; display: flex;  justify-content: center; align-items: center; flex-direction: column; " >
         
                 <img  src="assets/emptyStats/nooBookings.svg" style="width:300px;" alt=""><br>
                 <h2><b> No Bookings</b></h2><br>
                 <h5>Book your ride and you're ready to go</h5>
                </div>
         
            </div> -->
            <!-- <pagination-controls style=" margin-top: 18px !important;" *ngIf="result.length != 0"  class="alignStyles w-100" (pageChange)="p = $event"></pagination-controls> -->
       

           

        </div>

    </div>

    
    <!-- Row End -->
    
<!-- </div> -->

<!-- <div class="backdrop"></div> -->
<div class="drower">
    <div class="drower-header d-flex justify-content-between">
        <a style="cursor: pointer;"><i class="fa fa-times text-dark" aria-hidden="true"></i></a>
        <a  id="close" class="text-dark">Choose Plan</a>
        <a  class="clear-all" onClick="check_uncheck_checkbox(this.checked);">Clear All</a>
    </div>

    <div class="drower-body pl-0 pr-0 mt-0 h-100">
        <div class="tabs-container d-flex h-100">
            <div class="drower-left">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link text-dark active" id="v-pills-Source-tab" data-toggle="pill" href="#v-pills-Source" role="tab" aria-controls="v-pills-Source" aria-selected="true">Source</a>
                    <a class="nav-link text-dark" id="v-pills-Destination-tab" data-toggle="pill" href="#v-pills-Destination" role="tab" aria-controls="v-pills-Destination" aria-selected="false">Destination</a>
                    <a class="nav-link text-dark" id="v-pills-Time-tab" data-toggle="pill" href="#v-pills-Time" role="tab" aria-controls="v-pills-Time" aria-selected="false">Time</a>
                </div>
            </div>
            <div class="drower-right">
                <div class="tab-content drower-tab-content h-100" id="v-pills-tabContent">
                    <!-- Sourse Tab Content Start -->
                    <div class="tab-pane fade show active" id="v-pills-Source" role="tabpanel" aria-labelledby="v-pills-Source-tab">
                        <div class="d-flex flex-column pl-3 pt-3">
                            <label for="myCheckbox" class="checkbox">
                                <input type="checkbox" name="firstcheckbox" id="myCheckbox" class="checkbox__input">
                                <div class="checkbox__box"></div>
                                Jolly grant Airport
                            </label>
                            <label for="myCheckbox2" class="checkbox">
                                <input type="checkbox" name="firstcheckbox" id="myCheckbox2" class="checkbox__input">
                                <div class="checkbox__box"></div>
                                Hotel Ramada
                            </label>
                            <label for="myCheckbox3" class="checkbox">
                                <input type="checkbox" name="firstcheckbox" id="myCheckbox3" class="checkbox__input">
                                <div class="checkbox__box"></div>
                                Hotel Safron leaf
                            </label>
                        </div>

                    </div>
                    <!-- Sourse Tab Content End -->
                    <div class="tab-pane fade" id="v-pills-Destination" role="tabpanel" aria-labelledby="v-pills-Destination-tab">
                        <!-- Destination Tab Content Start -->

                        <div class="tab-pane fade show active" id="v-pills-Source" role="tabpanel" aria-labelledby="v-pills-Source-tab">
                            <div class="d-flex flex-column pl-3 pt-3">
                                <label for="1" class="checkbox">
                                    <input type="checkbox" name="firstcheckbox" id="1" class="checkbox__input">
                                    <div class="checkbox__box"></div>
                                    Jolly grant Airport
                                </label>
                                <label for="2" class="checkbox">
                                    <input type="checkbox" name="firstcheckbox" id="2" class="checkbox__input">
                                    <div class="checkbox__box"></div>
                                    Hotel Ramada
                                </label>
                                <label for="3" class="checkbox">
                                    <input type="checkbox" name="firstcheckbox" id="3" class="checkbox__input">
                                    <div class="checkbox__box"></div>
                                    Hotel Safron leaf
                                </label>
                            </div>

                        </div>
                        <!-- Destination Tab Content End -->
                    </div>
                    <div class="tab-pane fade" id="v-pills-Time" role="tabpanel" aria-labelledby="v-pills-Time-tab">
                        <div class="d-flex flex-column pl-3 pt-3">
                            <label for="today" class="radio">
                                <input type="radio" name="time" id="today" class="radio__input">
                                <div class="radio__radio"></div>
                                Today
                            </label>
                            <label for="Yesterday" class="radio">
                                <input type="radio" name="time" id="Yesterday" class="radio__input">
                                <div class="radio__radio"></div>
                                Yesterday
                            </label>
                            <label for="30-days" class="radio">
                                <input type="radio" name="time" id="30-days" class="radio__input">
                                <div class="radio__radio"></div>
                                Last 30 Days
                            </label>
                            <label for="6-months" class="radio">
                                <input type="radio" name="time" id="6-months" class="radio__input">
                                <div class="radio__radio"></div>
                                Last 6 Months
                            </label>
                            <label for="2020" class="radio year" id="year">
                                <input type="radio" name="time" id="2020" class="radio__input">
                                <div class="radio__radio"></div>
                                2020
                            </label>
                        </div>

                        <div class="mt-4 pl-3 pr-3 d-none time-fields">
                            <form action="">
                                <div class="form-layout">
                                    <div class="form-group">
                                        <input type="date" class="input-field" placeholder="Starting date">
                                        <label>Starting date</label>
                                    </div>
                                </div>
                                <div class="form-layout">
                                    <div class="form-group">
                                        <input type="date" class="input-field" placeholder="Ending date">
                                        <label>Ending date</label>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    </div>



    
</div>

<section class="backdrop"></section>