import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Track } from './track';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
// import { AngularFireAuth } from 'angularfire2/auth';
// import { auth } from '../../../../node_modules/firebase';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
// import { AngularFireAuth } from 'angularfire2/auth';
// import { AngularFirestore } from '@angular/fire/firestore';
// import { auth, database, messaging, storage, firestore, functions } from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class TrackServiceService {
  // baseUrl = 'https://evcityride.9brainz.store/';
  baseUrl = 'https://admin.evcityride.com'

  private dbPath = '/bookingid';

  tutorialsRef: AngularFireList<Track> | any;

  authState: any = null;

  constructor(
    private http: HttpClient,
    private db: AngularFireDatabase,
    private router: Router,
    private afAuth: AngularFireAuth
  ) {
    this.tutorialsRef = db.list(this.dbPath);

    // console.log(this.tutorialsRef, 'TutorialRefs');

    this.afAuth.authState.subscribe((auth) => {
      this.authState = auth;
    });
  }

  getAll(): AngularFireList<Track> {
    console.log(this.tutorialsRef);

    return this.tutorialsRef;
  }

  getTrackTrip(pg: number): Observable<Track> {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);
    return this.http
      .get<Track>(
        this.baseUrl +
          '/api/v1/booking-listing?type=ongoing&agency_id=' +
          localStore.agency_id +
          '&page=' +
          1
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  // authentication method for anony with firebase

  get isUserAnonymousLoggedIn(): boolean {
    return this.authState !== null ? this.authState.isAnonymous : false;
  }

  get currentUserId(): string {
    return this.authState !== null ? this.authState.uid : '';
  }

  anonymousLogin() {
    return this.afAuth.auth
      .signInAnonymously()
      .then((user) => {
        this.authState = user;
        // console.log(this.authState, 'From Firebase');
      })
      .catch((error) => alert(error + 'From Firebase Anonymously'));

    // return this.afAuth
    //   .signInAnonymously()
    //   .then((user: any) => {
    //     this.authState = user;
    //     console.log(this.authState, 'anonylogin Service call');
    //   })
    //   .catch((error: any) => alert('error'));
    // .catch((error: any) => console.log(error))
  }

  signOut(): void {
    this.afAuth.auth.signOut();
    // console.log("logout");
  }

  // fireUrl = "https://mobile-42e78-default-rtdb.firebaseio.com/Sachin"

  // firebase api config

  // getDataFire(){
  // return this.http.get('https://skproducts-4d5b3.firebaseio.com/products');
  //   return this.firestore.
  // }

  errorHandle(error: HttpErrorResponse) {
    return throwError(error.message || 'Serve Error');
  }
}
