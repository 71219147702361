<!-- <div class="col-sm-12 col-md-8 col-lg-9 details"> -->
    <!-- <ngx-spinner bdColor="white" color="black"
  template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
  <div class="preloader"></div>
    <div class="row mt-3">
        <div class="col-sm-12 col-md-12">
            <!-- Back Link -->
            <div class="d-flex justify-content-between w-100">
                <a (click)="gotoInvoice()" style="cursor: pointer;" class="text-dark font-weight-bold"><img src="assets/images/icons/arrow-left.svg" alt="" class="mr-2">Invoice
                </a>
                <!-- <h5 class="mb-0 text-success">₹ 1200</h5> -->
            </div>
            <p [style.color]="status == 'Payment Received' ? 'green' : 'red' " style="text-align: center;"  ><b>{{status}}</b></p>
            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <div  class="card mt-3" data-toggle="modal" data-target="#exampleModal">
                        <div class="card-body">
                            <div class="parent"  >
                                <div  class="d-flex align-items-center source-and-destination">
                                    <p *ngIf="destination != '' " class="mb-0 mr-4">{{source}}</p>
                                    <img *ngIf="destination != '' " src="assets/images/icons/Line.svg" alt="" class="mr-4">
                                    <p *ngIf="destination != '' " class="mb-0">{{destination}}</p>
                                    <p *ngIf="destination == '' " class="mb-0">{{source}}</p>

                                </div>
                                <p class="mb-0 mr-4 client-name">{{passengerName}}</p>
                                <!-- <p class="mb-0 trip-date">{{invoice_detail.Time}} | {{invoice_detail.Date}}</p> -->
                                <!-- <img src="assets/images/icons/google maps.svg" alt="" class="location-image"> -->
                                <p class="text-success location-image">{{amount}}</p>
                            </div>
                        </div>
                    </div>

                  
               

               
                </div>
            </div>

            <div class="d-flex justify-content-between align-items-center invoice-footer mt-4">
                <div class="left" *ngIf="status != 'Payment Pending'" >
                    <!-- <iframe *ngIf="showI" [src]="invoiceDownload"  frameborder="0"></iframe> -->
                    <a target="_blank" [href]="invoiceDownload"   style="margin-right: 16px !important;"
                     class="btn btn-outline-primary mt-4 mr-md-0 mb-4 mr-3" download>View</a>
                    <a  (click)="download()"  class="btn btn-primary mt-4 mb-4" >Download</a>
                    
                </div>
                <div class="right d-flex justify-content-end" style="margin-top: 15px;" >
                    <h5 class="mr-4">Total</h5>
                    <h5>{{amount}}</h5>
                </div>
            </div>
            
        </div>
    </div>
<!-- </div> -->

<div class="backdrop"></div>