import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tripFilter'
})
export class TripFilterPipe implements PipeTransform {

  transform(value: any, ...args: any): any {

    if(!args){
      return value
    }
    return value.filter((item:{tripId:string | any[];})=>item.tripId.indexOf(args) > -1);
  }

}
