<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->

<div class="preloader"></div>

<div class="row">
  <div class="col-md-12">
    <form
      [formGroup]="billingDatePost"
      id="formClear"
      (ngSubmit)="billingSubmit()"
      class="row"
      style="margin-top: 20px;"
    >
      <div class="col-sm-12 col-md-3">
        <div class="form-layout">
          <div class="form-group mb-4">
            <input
              class="form-control datetimepicker-input jqueryStartDate"
              name="start_date"
              formControlName="start_date"
              placeholder="Start Date"
              [options]="Start_Date"
              NgTempusdominusBootstrapInput
              [(ngModel)]="start_date"
              (ngModelChange)="update()"
              type="text"
              autocomplete="off"
             
              
            />
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-3">
        <div class="form-layout">
          <div class="form-group mb-4">
            <input
              class="form-control datetimepicker-input jqueryEndDate"
              name="end_date"
              formControlName="end_date"
              [options]="End_Date"
            

              NgTempusdominusBootstrapInput
              type="text"
              placeholder="End Date"
              (ngModelChange)="update()"
              autocomplete="off"
             
            />  
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <button
          type="submit"
          class="btn btn-outline-primary btn-block mb-4"
          [disabled]="billingDatePost.invalid"
        >
          Apply Filter
        </button>
      </div>
      <div class="col-md-3 col-sm-6">
        <button
          type="button"
          (click)="getClear()"
          class="btn btn-outline-warning btn-block mb-4"
        >
          Clear
        </button>
      </div>
    </form>
    <br />
    <!-- <form [formGroup]="billingDatePost" (ngSubmit)="billingSubmit()" 
            class="row" style="margin-top: 20px;" >

            <div class="col-sm-12 col-md-6">
                <div class="form-layout">
                    <div class="form-group mb-4">
                        <div class="input-group date" data-target-input="nearest"
                        NgTempusdominusBootstrap>
                         <input style="height: 65px !important;"
                         class="form-control"
                         name="start_date" 
                         formControlName="start_date"
                        placeholder="Start Date"
                       
                         [options]="Start_Date"
                         NgTempusdominusBootstrapInput
                         [(ngModel)]="start_date"
                         (ngModelChange)="update()"
                       
                         
                         type="text"
                         
                         
                         />
                         <div class="input-group-append" NgTempusdominusBootstrapToggle>
                             <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                         </div>
       
       
                     </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6">
                <div class="form-layout">
      <div class="form-group  mb-4">
                                      
                        <div class="input-group date" data-target-input="nearest"
                        NgTempusdominusBootstrap>
                         <input style="height: 65px !important;"
                         class="form-control"
                         name="end_date" 
                         formControlName="end_date"
                        
                         [options]="End_Date"
                         NgTempusdominusBootstrapInput
                         type="text"
                         placeholder="End Date"
                          
                        
                         (ngModelChange)="update()"
                        (click)="billingSubmit()"
                           
                         />
                         <div class="input-group-append" NgTempusdominusBootstrapToggle>
                             <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                         </div>
       
                         
       
                     </div>
                    
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <button class="btn btn-outline-primary btn-block">Submit</button>
                
            </div><br>
        </form><br> -->
  </div>
</div>

<!-- <div class="col-sm-12 col-md-8 col-lg-9 details"> -->
<!-- Row Start -->
<div class="row">
  <!-- Column Start -->
  <div *ngIf="isEnable" class="col-sm-12 col-md-12 col-lg-4">
    <a routerLink="/rider/billing/airport_pickup">
      <!-- <div *ngIf=""  >

            </div> -->
      <div class="card card-1 mt-5 mb-sm-3 mt-sm-0">
        <div
          class="card-body d-flex justify-content-between align-items-center"
        >
          <div class="left">
            <h4>Airport Pickup</h4>
            <p class="mb-0">
              Trips <span class="font-weight-bold">{{pickTrips}}</span>
            </p>
          </div>
          <div class="right">
            <h4 class="mb-0">{{picAmounts}}</h4>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div *ngIf="isDisable" class="col-sm-12 col-md-12 col-lg-4">
    <a (click)="noDATA()" disabled>
      <div class="card card-1 mt-5 mb-sm-3 mt-sm-0">
        <div
          class="card-body d-flex justify-content-between align-items-center"
        >
          <div class="left">
            <h4>Airport Pickup</h4>
            <p class="mb-0">Trips <span class="font-weight-bold">0</span></p>
          </div>
          <div class="right">
            <h4 class="mb-0">₹0</h4>
          </div>
        </div>
      </div>
    </a>
  </div>
  <!-- Column End -->

  <!-- Column Start -->
  <div *ngIf="showDataDrop" class="col-sm-12 col-md-12 col-lg-4">
    <a routerLink="/rider/billing/airport_drop">
      <div class="card card-1 mt-5 mb-sm-3 mt-sm-0">
        <div
          class="card-body d-flex justify-content-between align-items-center"
        >
          <div class="left">
            <h4>Airport Drop</h4>
            <p class="mb-0">
              Trips <span class="font-weight-bold">{{dropTrips}}</span>
            </p>
          </div>
          <div class="right">
            <h4 class="mb-0">{{dropAmounts}}</h4>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div *ngIf="emptyDataDrop" class="col-sm-12 col-md-12 col-lg-4">
    <a (click)="noDATA()">
      <div class="card card-1 mt-5 mb-sm-3 mt-sm-0">
        <div
          class="card-body d-flex justify-content-between align-items-center"
        >
          <div class="left">
            <h4>Airport Drop</h4>
            <p class="mb-0">Trips <span class="font-weight-bold">0</span></p>
          </div>
          <div class="right">
            <h4 class="mb-0">₹0</h4>
          </div>
        </div>
      </div>
    </a>
  </div>
  <!-- Column End -->

  <!-- Column Start -->
  <div *ngIf="showData" class="col-sm-12 col-md-12 col-lg-4">
    <a routerLink="/rider/billing/self_drive">
      <div class="card card-1 mt-5 mb-sm-3 mt-sm-0">
        <div
          class="card-body d-flex justify-content-between align-items-center"
        >
          <div class="left">
            <h4>Day Rental</h4>
            <p class="mb-0">
              Trips <span class="font-weight-bold">{{dayRentalTrips}}</span>
            </p>
          </div>
          <div class="right">
            <h4 class="mb-0">{{dayRentalAmounts}}</h4>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div *ngIf="emptyData" class="col-sm-12 col-md-12 col-lg-4">
    <a (click)="noDATA()">
      <div class="card card-1 mt-5 mb-sm-3 mt-sm-0">
        <div
          class="card-body d-flex justify-content-between align-items-center"
        >
          <div class="left">
            <h4>Self Drive</h4>
            <p class="mb-0">Trips <span class="font-weight-bold">0</span></p>
          </div>
          <div class="right">
            <h4 class="mb-0">₹0</h4>
          </div>
        </div>
      </div>
    </a>
  </div>
  <!-- Column End -->
</div>
<!-- Row End -->

<!-- Trips Start -->
<div class="row mt-3 mb-3">
  <div class="col">
    <p class="text-dark font-weight-bold mb-0">Trips</p>
  </div>
  <div class="col">
    <div class="">
      <div class="d-flex justify-content-end align-items-center h-100">
        <div class="form-group mb-0 mr-3 w-100" id="search-field">
          <input
            type="text"
            [(ngModel)]="nameSearch"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Search Something"
          />
        </div>
        <img
          src="assets/images/icons/search.svg"
          alt=""
          class="mr-3"
          id="search-icon"
        />
      </div>
    </div>
  </div>
</div>
<!-- <div class="d-flex w-100 justify-content-between mt-3 mb-3">
    </div> -->
<!-- Trips End -->
<div class="col-md-12" *ngIf="noBill">
  <div
    style="
      width: 100%;
      height: 23vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    "
  >
    <h2>No Trips Available</h2>
    <!-- <img  src="assets/emptyStats/nobilling.svg" style="width:340px;" alt=""><br> -->
  </div>
</div>
<!-- Row Start -->
<div class="row">
  <div class="col-sm-12 col-md-12">
    <div *ngIf="(data|filterPipe:nameSearch) as result">
      <div
        class="billing-wrapper"
        [style.display]="result.length === 0 ? 'none' : 'block' "
      >
        <a
          *ngFor="let trip of result"
          [routerLink]="['/rider/billing/bill_details/', trip.id]"
          class="text-dark"
        >
          <!-- <div class="card mt-3" data-toggle="modal" data-target="#exampleModal">
                    <div class="card-body">
                        <div class="parent">
                            <div class="d-flex align-items-center source-and-destination">
                                <p class="mb-0 mr-4">{{trip.source}}</p>
                                <img src="assets/images/icons/Line.svg" alt="" class="mr-4">
                                <p class="mb-0">{{trip.destination}}</p>
                            </div>
                            <p class="mb-0 mr-4 client-name">{{trip.passenger_name}}</p>
                            <p class="mb-0 trip-date">{{trip.start_trip_time}} | {{trip.start_trip_date}}</p>
                            <p class="text-success location-image">{{trip.final_amount}}</p>
                        </div>
                    </div>
                </div> -->

          <!-- Change Card for Solve Layout Problem -->
          <div *ngIf="hidey" class="card mb-3 trip-card">
            <div class="card-body">
              <div
                *ngIf="trip.request_type != 'day rental'"
                class="Source-and-destination"
              >
                <p class="text-truncate w-50 font-weight-bold">
                  {{trip.source}}
                </p>
                <img
                  src="assets/images/icons/Line.svg"
                  alt=""
                  class="ml-4 mr-4"
                />
                <p class="text-truncate w-50 font-weight-bold">
                  {{trip.destination}}
                </p>
              </div>

              <div *ngIf="trip.request_type == 'day rental'">
                <p class="font-weight-bold">Day Rental</p>
              </div>
              <div class="trip-details">
                <p class="client-name text-truncate w-25">
                  {{trip.passenger_name}}
                </p>
                <p class="trip-date text-truncate w-50">
                  {{trip.start_trip_date}}
                </p>
                <p class="text-success">{{trip.final_amount}}</p>
              </div>
            </div>
          </div>
          <!-- Change Card for Solve Layout Problem -->
        </a>
      </div>
      <div class="col-md-12" *ngIf="result.length === 0">
        <div
          style="
            width: 100%;
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <!-- <img  src="assets/emptyStats/nooBookings.svg" style="width:300px;" alt=""><br> -->
          <!-- <h2><b> No Bookings</b></h2><br> -->
          <!-- <h5>Book your ride and you're ready to go</h5> -->
          <h2>No Trips Available</h2>
        </div>
      </div>
      <!-- <pagination-controls style=" margin-top: 18px !important;" *ngIf="hidey && result.length != 0 "  class="alignStyles w-100" (pageChange)="p = $event"></pagination-controls> -->
    </div>
    <!-- <a routerLink="/billdetail" class="text-dark">
                <div class="card mt-3" data-toggle="modal" data-target="#exampleModal">
                    <div class="card-body">
                        <div class="parent">
                            <div class="d-flex align-items-center source-and-destination">
                                <p class="mb-0 mr-4">Saffron Leaf</p>
                                <img src="assets/images/icons/Line.svg" alt="" class="mr-4">
                                <p class="mb-0">Jolly Grant Airport</p>
                            </div>
                            <p class="mb-0 mr-4 client-name">Arjun Katariya</p>
                            <p class="mb-0 trip-date">04:00 PM | Sat, 8 Jan 2021</p>
                            <p class="text-success location-image">₹ 1200</p>
                        </div>
                    </div>
                </div>
            </a>

            <a routerLink="/billdetail" class="text-dark">
                <div class="card mt-3" data-toggle="modal" data-target="#exampleModal">
                    <div class="card-body">
                        <div class="parent">
                            <div class="d-flex align-items-center source-and-destination">
                                <p class="mb-0 mr-4">Saffron Leaf</p>
                                <img src="assets/images/icons/Line.svg" alt="" class="mr-4">
                                <p class="mb-0">Jolly Grant Airport</p>
                            </div>
                            <p class="mb-0 mr-4 client-name">Arjun Katariya</p>
                            <p class="mb-0 trip-date">04:00 PM | Sat, 8 Jan 2021</p>
                            <p class="text-success location-image">₹ 1200</p>
                        </div>
                    </div>
                </div>
            </a>

            <a routerLink="/billdetail" class="text-dark">
                <div class="card mt-3" data-toggle="modal" data-target="#exampleModal">
                    <div class="card-body">
                        <div class="parent">
                            <div class="d-flex align-items-center source-and-destination">
                                <p class="mb-0 mr-4">Saffron Leaf</p>
                                <img src="assets/images/icons/Line.svg" alt="" class="mr-4">
                                <p class="mb-0">Jolly Grant Airport</p>
                            </div>
                            <p class="mb-0 mr-4 client-name">Arjun Katariya</p>
                            <p class="mb-0 trip-date">04:00 PM | Sat, 8 Jan 2021</p>
                            <p class="text-success location-image">₹ 1200</p>
                        </div>
                    </div>
                </div>
            </a>

            <a routerLink="/billdetail" class="text-dark">
                <div class="card mt-3" data-toggle="modal" data-target="#exampleModal">
                    <div class="card-body">
                        <div class="parent">
                            <div class="d-flex align-items-center source-and-destination">
                                <p class="mb-0 mr-4">Saffron Leaf</p>
                                <img src="assets/images/icons/Line.svg" alt="" class="mr-4">
                                <p class="mb-0">Jolly Grant Airport</p>
                            </div>
                            <p class="mb-0 mr-4 client-name">Arjun Katariya</p>
                            <p class="mb-0 trip-date">04:00 PM | Sat, 8 Jan 2021</p>
                            <p class="text-success location-image">₹ 1200</p>
                        </div>
                    </div>
                </div>
            </a> -->
  </div>
</div>
<!-- Row End -->

<!-- </div> -->

<!-- <div class="backdrop"></div> -->

<div class="drower">
  <div class="drower-header d-flex justify-content-between">
    <a style="cursor: pointer;"
      ><i class="fa fa-times text-dark" aria-hidden="true"></i
    ></a>
    <a id="close" class="text-dark">Choose Plan</a>
    <a class="clear-all" onClick="check_uncheck_checkbox(this.checked);"
      >Clear All</a
    >
  </div>

  <div class="drower-body pl-0 pr-0 mt-0 h-100">
    <div class="tabs-container d-flex h-100">
      <div class="drower-left">
        <div
          class="nav flex-column nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            class="nav-link text-dark active"
            id="v-pills-Source-tab"
            data-toggle="pill"
            href="#v-pills-Source"
            role="tab"
            aria-controls="v-pills-Source"
            aria-selected="true"
            >Source</a
          >
          <a
            class="nav-link text-dark"
            id="v-pills-Destination-tab"
            data-toggle="pill"
            href="#v-pills-Destination"
            role="tab"
            aria-controls="v-pills-Destination"
            aria-selected="false"
            >Destination</a
          >
          <a
            class="nav-link text-dark"
            id="v-pills-Time-tab"
            data-toggle="pill"
            href="#v-pills-Time"
            role="tab"
            aria-controls="v-pills-Time"
            aria-selected="false"
            >Time</a
          >
        </div>
      </div>
      <div class="drower-right">
        <div
          class="tab-content drower-tab-content h-100"
          id="v-pills-tabContent"
        >
          <!-- Sourse Tab Content Start -->
          <div
            class="tab-pane fade show active"
            id="v-pills-Source"
            role="tabpanel"
            aria-labelledby="v-pills-Source-tab"
          >
            <div class="d-flex flex-column pl-3 pt-3">
              <label for="myCheckbox" class="checkbox">
                <input
                  type="checkbox"
                  name="firstcheckbox"
                  id="myCheckbox"
                  class="checkbox__input"
                />
                <div class="checkbox__box"></div>
                Jolly grant Airport
              </label>
              <label for="myCheckbox2" class="checkbox">
                <input
                  type="checkbox"
                  name="firstcheckbox"
                  id="myCheckbox2"
                  class="checkbox__input"
                />
                <div class="checkbox__box"></div>
                Hotel Ramada
              </label>
              <label for="myCheckbox3" class="checkbox">
                <input
                  type="checkbox"
                  name="firstcheckbox"
                  id="myCheckbox3"
                  class="checkbox__input"
                />
                <div class="checkbox__box"></div>
                Hotel Safron leaf
              </label>
            </div>
          </div>
          <!-- Sourse Tab Content End -->
          <div
            class="tab-pane fade"
            id="v-pills-Destination"
            role="tabpanel"
            aria-labelledby="v-pills-Destination-tab"
          >
            <!-- Destination Tab Content Start -->

            <div
              class="tab-pane fade show active"
              id="v-pills-Source"
              role="tabpanel"
              aria-labelledby="v-pills-Source-tab"
            >
              <div class="d-flex flex-column pl-3 pt-3">
                <label for="1" class="checkbox">
                  <input
                    type="checkbox"
                    name="firstcheckbox"
                    id="1"
                    class="checkbox__input"
                  />
                  <div class="checkbox__box"></div>
                  Jolly grant Airport
                </label>
                <label for="2" class="checkbox">
                  <input
                    type="checkbox"
                    name="firstcheckbox"
                    id="2"
                    class="checkbox__input"
                  />
                  <div class="checkbox__box"></div>
                  Hotel Ramada
                </label>
                <label for="3" class="checkbox">
                  <input
                    type="checkbox"
                    name="firstcheckbox"
                    id="3"
                    class="checkbox__input"
                  />
                  <div class="checkbox__box"></div>
                  Hotel Safron leaf
                </label>
              </div>
            </div>
            <!-- Destination Tab Content End -->
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-Time"
            role="tabpanel"
            aria-labelledby="v-pills-Time-tab"
          >
            <div class="d-flex flex-column pl-3 pt-3">
              <label for="today" class="radio">
                <input
                  type="radio"
                  name="time"
                  id="today"
                  class="radio__input"
                />
                <div class="radio__radio"></div>
                Today
              </label>
              <label for="Yesterday" class="radio">
                <input
                  type="radio"
                  name="time"
                  id="Yesterday"
                  class="radio__input"
                />
                <div class="radio__radio"></div>
                Yesterday
              </label>
              <label for="30-days" class="radio">
                <input
                  type="radio"
                  name="time"
                  id="30-days"
                  class="radio__input"
                />
                <div class="radio__radio"></div>
                Last 30 Days
              </label>
              <label for="6-months" class="radio">
                <input
                  type="radio"
                  name="time"
                  id="6-months"
                  class="radio__input"
                />
                <div class="radio__radio"></div>
                Last 6 Months
              </label>
              <label for="2020" class="radio year" id="year">
                <input
                  type="radio"
                  name="time"
                  id="2020"
                  class="radio__input"
                />
                <div class="radio__radio"></div>
                2020
              </label>
            </div>

            <div class="mt-4 pl-3 pr-3 d-none time-fields">
              <form action="">
                <div class="form-layout">
                  <div class="form-group">
                    <input
                      type="date"
                      class="input-field"
                      placeholder="Starting date"
                    />
                    <label>Starting date</label>
                  </div>
                </div>
                <div class="form-layout">
                  <div class="form-group">
                    <input
                      type="date"
                      class="input-field"
                      placeholder="Ending date"
                    />
                    <label>Ending date</label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="backdrop"></section>
