<header class="site-header header-two">
        
    <nav class="navbar navbar-expand-md navbar-light bg-light">
        <div class="container">
            <!-- ../images/icons/EV City Logo.svg -->
            <a class="navbar-brand" routerLink="/" ><img src="../../../assets/images/icons/EV City Logo.svg" alt=""></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link" (click)="setTitle('Home | EVcityride')" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="setTitle('About-us | EVcityride')" routerLink="/about" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">About</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="setTitle('Download App | EVcityride')"  routerLink="/download_app" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Download App</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="setTitle('Contact-us | EVcityride')" routerLink="contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" >Contact</a>
                    </li>
                    <li class="nav-item">
                        <a style="cursor: pointer;" (click)="setTitle('Login | EVcityride')" class="nav-link" data-toggle="modal" (click)="loginClick()" data-target="#login" >Login</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

</header>

<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->


<div  class="modal fade login" [ngClass]="[ isSuccess ? 'displyNone' : 'Block']" 
 id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document" >
        <div class="modal-content">
            <!-- <button (click)="stopCounter()" class="btn btn-warning" >Stop</button> -->
            <a  data-dismiss="modal" id="closeTrigger" (click)="closeMdl()" style="cursor: pointer;">
                <img src="assets/close.svg" height="50px" width="50px" style="float:right" alt=""></a>
            <div class="modal-body">
                
                <!-- <app-login></app-login> -->
                <form *ngIf="loginIN" [formGroup]="loginForm" (ngSubmit)="onSubmit()"  >
                    <div class="login">
                        <img src="assets/images/Evcity/logo-navigation.svg" alt="" class="mt-3 mb-3 d-flex" style="margin-left: auto !important; margin-right: auto !important;">
                        <h5 class="font-weight-bold text-center mb-4">Welcome</h5>
                        <div class="form-layout">
                            <div class="form-group">
                                <input type="email" formControlName="username"  class="input-field" placeholder="Enter email" required>
                                <label>Email Id / Username</label>

<div *ngIf="submi && username.invalid">
    <p class="alert alert-danger"> Username/Email Required...</p>
    
    
</div>
                               
                            </div>
                        </div>

                        <div class="form-layout">
                            <div class="form-group">
                                <input [type]="fieldTextTypeLogin ? 'text' : 'password'" 
                                formControlName="password"
                                 class="input-field" id="password" placeholder="Password" required >
                                <label for="password">Password</label>
                                <i class="fa eye"
                                [ngClass]="{
                                  'fa-eye-slash': !fieldTextTypeLogin,
                                  'fa-eye': fieldTextTypeLogin
                                }"
                                (click)="toggleFieldLogin()"></i>
                                <div *ngIf="submi && password.invalid" >
                                   <p class="alert alert-danger"> Password Required</p>
                                  
                                </div>
                                
                            </div>
                        </div>
                       
  
  
  
                        <div class="d-flex justify-content-between">
                            <!-- <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label" for="exampleCheck1"  >Remember me</label>
                            </div> -->
                            <a  class="text-primary forget-password"
                             style="cursor: pointer; margin-bottom: 20px;"
                              (click)="forgetPasswordSection()" >Forgot Password?</a>
                        </div>
                        <!-- [disabled]="!loginForm.valid"  -->
                        <button type="submit"  class="btn btn-primary btn-block kurkure" >Login</button>
                    </div>
  
                       
  
                </form>

                <form id="Lg"  [formGroup]="forgot_form" (ngSubmit)="onForgot()"  >
                    <div *ngIf="forgotpassEmail" >
                            <img src="assets/images/Evcity/logo-navigation.svg" alt="" class="mt-3 mb-3 d-flex" style="margin-left: auto !important; margin-right: auto !important;">
                            <h5 class="font-weight-bold text-center mb-4">Forgot password</h5>
                            <p class="text-center">Enter email ID associated with your account. </p>
                            <div class="form-layout mt-3">
                                <div class="form-group">
                                    <input type="email" id="emailClear" formControlName="email" class="input-field" placeholder="Enter email">
                                    <label style="z-index: 0 !important;"  >Email address</label>
                                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
                                        <div *ngIf="email.errors">
                                            Email Required...
                                        </div>
                                </div>
                                </div>
                            </div>
                            <button type="submit" [disabled]="!forgot_form.valid" class="btn btn-primary btn-block">Continue</button>
                            <a (click)="bckTologin()" class="text-center text-primary mt-5 mb-4 back-to-login" style="cursor: pointer;"  >Back To Login</a>
                        </div>
                     
                      
                    </form>

                    
                    <form  class="otp-verification">
                        <div *ngIf="otpSec">
                     <img src="assets/images/Evcity/logo-navigation.svg" alt="" class="mt-3 mb-3 d-flex" style="margin-left: auto !important; margin-right: auto !important;">
                     <h5 class="font-weight-bold text-center mb-4">OTP Verification</h5>
                     <p>Please type the verification code sent to 
                         <span class="font-weight-bold text-dark">{{emailStored}}</span> 
                          <a (click)="goMail()" style="cursor: pointer;" class="text-primary change"> Change?</a></p>
  
                     <div class="d-flex justify-content-between mt-3">
                         <!-- <h3>{{userDisplayName | json}}</h3> -->
                         <div style="width: 100%; ">
                             <ng-otp-input [style.display]="'flex'" [style.width]="'100%'" [style.justify-content]="'space-around'"
                               #ngOtpInput (onInputChange)="onOtpChange($event)"
                                                             *ngIf="showOtpComponent" [config]="config"></ng-otp-input>
                         </div>
                        
                     </div>


                     <div class="inform d-flex justify-content-between mt-4 mb-4">
                        
                        <button class="btns"  [disabled]="!showHideResendMail"  
                         (click)="resetOTP()" style="cursor: pointer;background-color: transparent; border: none;" >Resend OTP</button>
                        
                         {{counter}}
                     </div>


                     <button type="submit" (click)="SendOtp()"  class="btn btn-primary btn-block mt-5 otp-go ">Continue</button>
                 </div> 
             </form> 
  

             <form  class="forgot-password"  [formGroup]="confirmPasswordForm" (ngSubmit)="Pass_submit()">
                <div *ngIf="confPass" class="forgot-password" >
                    <img src="assets/images/Evcity/logo-navigation.svg" alt="" class="mt-3 mb-3 d-flex" 
                    style="margin-left: auto !important; margin-right: auto !important;">
                    <h5 class="font-weight-bold text-center mb-4">Create New Strong Password</h5>
                    <p class="text-center">Create a new, strong password that you don't use for other.</p>
  
                    <div class="mt-3">
                        <div class="form-layout">
                            <div class="form-group">
                                <input [type]="fieldTextType ? 'text' : 'password'" formControlName="password" class="input-field" id="pavdo"  placeholder="Password">
                                <label style="z-index: 0 !important;" >Password</label>
                                <!-- <i class="fa eye"
                                [ngClass]="{
                                  'fa-eye-slash': !fieldTextType,
                                  'fa-eye': fieldTextType
                                }"
                                (click)="toggleFieldTextType()"></i> -->
                                <!-- <div *ngIf="subb && password.invalid">  
                                       <p class="alert alert-danger"> Password Required</p>

                                </div> -->

                                <!-- <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
                                    <div *ngIf="password.errors">
                                        password Required...
                                    </div>
                            </div> -->
                                <!-- <div *ngIf="confirmPasswordForm.password.touched && confirmPasswordForm.password.invalid" class="alert alert-danger">
  
                                    <div *ngIf="confirmPasswordForm.password.errors?.required">Password is required.</div>
                    
                                </div> -->
                            </div>
                        </div>
                        <div class="form-layout">
                            <div class="form-group">
                                <input [type]="repeatFieldTextType ? 'text' : 'password'" formControlName="confirm_password" class="input-field" id="takila" placeholder="Confirm-Password">
                                <label style="z-index: 0 !important;" >Confirm Password</label>
                                <!-- <i  class="fa eye"
                                [ngClass]="{
                                  'fa-eye-slash': !repeatFieldTextType,
                                  'fa-eye': repeatFieldTextType
                                }"
                                (click)="toggleRepeatFieldTextType()"></i> -->
                                <!-- <div *ngIf="subb &&confirm_password.invalid ">  
                                <p class="alert alert-danger"> Confirm Password Required</p>

                            </div> -->

                                <!-- <div *ngIf="confirm_password.invalid && (confirm_password.dirty || confirm_password.touched)" class="alert alert-danger">
                                    <div *ngIf="confirm_password.errors">
                                        confirm_password Required...
                                    </div>
                            </div> -->
                                <!-- <div *ngIf="confirmPasswordForm.confirm_password.touched && confirmPasswordForm.confirm_password.invalid" class="alert alert-danger">
  
                                    <div *ngIf="confirmPasswordForm.confirm_password.errors?.required">Password is required.</div>
                    
                                    <div *ngIf="confirmPasswordForm.confirm_password.errors?.confirmedValidator">Password and Confirm Password must be match.</div>
                    
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="form-layout">
                            <div class="form-group">
                                
                                <input type="text" formControlName="agency_id" class="input-field"  placeholder="agency id" hidden>
  
                            </div>
                        </div> -->
                        <div class="form-group form-check" >
                            <input type="checkbox" class="form-check-input" id="remember-me" (click)="toggleDual()">
                            
                            <label class="form-check-label" for="remember-me" >Show password</label>
                        </div>
                    </div>
    
                    <button type="submit" [disabled]="confirmPasswordForm.invalid" class="btn btn-primary btn-block mt-3" >Continue</button>
                </div>
            </form>
            </div>
</div>
</div>
</div>


<!-- <div class="backdrop" (click)="closeMdl()" ></div> -->