import { Component, OnInit } from '@angular/core';
// import { NgxSpinnerService } from 'ngx-spinner';
import { Contact } from './contact';
import { ContactServiceService } from './contact-service.service';
import * as $ from 'jquery';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-service',
  templateUrl: './contact-service.component.html',
  
  styleUrls: ['./contact-service.component.css']
})
export class ContactServiceComponent implements OnInit {

  constructor(private Service:ContactServiceService, 
   private titleHeader:Title) { }

    public setTitle(newTitle: string) {
      this.titleHeader.setTitle(newTitle);
    }
  ngOnInit(): void {
  this.setTitle('Contact-Service | Evcityride');

    localStorage.removeItem('sDate');
    localStorage.removeItem('eDate');
    localStorage.removeItem('searchOngoing');
    localStorage.removeItem('searchUpcoming');
    localStorage.removeItem('searchPast');
    this.getContacts();

    

    $(document).ready(function(){
      $(".aside-menu").hide();
      $(".backdrop").hide();
      $(".menu-icon").click(function(){
        $(".aside-menu").show();
        $(".backdrop").show();
      });
      $(".backdrop").click(function(){
        $(".aside-menu").hide();
        $(".backdrop").hide();
      });

      // $('#invoiceActive').hide();
      // $('#invoiceInactive').show();
    });
  }

  varData:Contact | any;

  getContacts(){
    // this.spinner.show();

    this.Service.getContact().subscribe((res)=>{

      if(res.success == true){
        // console.log(res);
        // this.spinner.hide();
        setTimeout(() => {
          $(".preloader").css('display','none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      }else{
        // this.spinner.hide();
        setTimeout(() => {
          $(".preloader").css('display','none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      //  console.log(res)
      }
    })
  }

}
