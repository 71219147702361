// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

 
export const environment = {
  production: false,
   firebaseConfig : {
    apiKey: "AIzaSyC8_eysYR8orUM3zpcqhjkXSHrwed9oiPA",
  authDomain: "evcitybeta.firebaseapp.com",
  databaseURL: "https://evcitybeta-default-rtdb.firebaseio.com",
  projectId: "evcitybeta",
  storageBucket: "evcitybeta.appspot.com",
  messagingSenderId: "148105644342",
  appId: "1:148105644342:web:af14751f33203e0ffacb97",
  measurementId: "G-1Y18XRXQQD"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
