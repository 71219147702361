<section class="taxi-style-one">
    <div class="container">
        <div class="block-title text-center">
            <div class="dot-line"></div><!-- /.dot-line -->
            <p>Our best cars</p>
            <h2>Choose taxi</h2>
        </div><!-- /.block-title -->
        <ul class="nav nav-tabs tab-title texi-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" href="#hybrid" role="tab" data-toggle="tab">hybrid taxi</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#town" role="tab" data-toggle="tab">town taxi</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#limousine" role="tab" data-toggle="tab">LIMOUSINE taxi</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#suv" role="tab" data-toggle="tab">suv taxi</a>
            </li>
        </ul>
        <div class="tab-content">
            <div role="tabpanel" class="tab-pane show active  animated fadeInUp" id="hybrid">
                <div class="row">
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-1.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-bmw"></i>
                                </div><!-- /.icon-block -->
                                <h3>M5 2008 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-2.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-mercedes-benz"></i>
                                </div><!-- /.icon-block -->
                                <h3>E Class 2010 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-3.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-toyota"></i>
                                </div><!-- /.icon-block -->
                                <h3>Yaris 2014 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                </div><!-- /.row -->
            </div>
            <div role="tabpanel" class="tab-pane animated fadeInUp" id="town">
                <div class="row">
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-1.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-bmw"></i>
                                </div><!-- /.icon-block -->
                                <h3>M5 2008 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-2.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-mercedes-benz"></i>
                                </div><!-- /.icon-block -->
                                <h3>E Class 2010 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-3.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-toyota"></i>
                                </div><!-- /.icon-block -->
                                <h3>Yaris 2014 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                </div><!-- /.row -->
            </div>
            <div role="tabpanel" class="tab-pane animated fadeInUp" id="limousine">
                <div class="row">
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-1.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-bmw"></i>
                                </div><!-- /.icon-block -->
                                <h3>M5 2008 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-2.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-mercedes-benz"></i>
                                </div><!-- /.icon-block -->
                                <h3>E Class 2010 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-3.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-toyota"></i>
                                </div><!-- /.icon-block -->
                                <h3>Yaris 2014 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                </div><!-- /.row -->
            </div>
            <div role="tabpanel" class="tab-pane animated fadeInUp" id="suv">
                <div class="row">
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-1.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-bmw"></i>
                                </div><!-- /.icon-block -->
                                <h3>M5 2008 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-2.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-mercedes-benz"></i>
                                </div><!-- /.icon-block -->
                                <h3>E Class 2010 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                    <div class=" col-md-6 col-lg-4">
                        <div class="single-taxi-one">
                            <div class="inner-content">
                                <img src="assets/images/pricing/pricing-1-3.png" alt="Awesome Image" />
                                <div class="icon-block">
                                    <i class="conexi-icon-toyota"></i>
                                </div><!-- /.icon-block -->
                                <h3>Yaris 2014 Model</h3>
                                <ul class="feature-list">
                                    <li>
                                        <span class="name-line">Base Rate:</span>
                                        <span class="price-line">$4.30</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Per Mile/KM:</span>
                                        <span class="price-line">$2.00</span>
                                    </li>
                                    <li>
                                        <span class="name-line">Passengers:</span>
                                        <span class="price-line">4</span>
                                    </li>
                                </ul><!-- /.feature-list -->
                                <a href="#" class="book-taxi-btn">Book Taxi</a>
                            </div><!-- /.inner-content -->
                        </div><!-- /.single-taxi-one -->
                    </div><!-- /.col-lg-4 -->
                </div><!-- /.row -->
            </div>
        </div><!-- /.tab-content -->
    </div><!-- /.container -->
</section>