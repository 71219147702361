<div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <form>
                    <div class="login">
                        <img src="assets/images/Evcity/logo-navigation.svg" alt="" class="mt-3 mb-3 d-flex" style="margin-left: auto !important; margin-right: auto !important;">
                        <h5 class="font-weight-bold text-center mb-4">Welcome</h5>
                        <div class="form-layout">
                            <div class="form-group">
                                <input type="email" class="input-field" placeholder="Enter email">
                                <label>Email address</label>
                            </div>
                        </div>
                        <div class="form-layout">
                            <div class="form-group">
                                <input type="password" class="input-field" id="password" placeholder="Password">
                                <label for="password">Password</label>
                                <i class="fa fa-eye eye" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label" for="exampleCheck1">Remember me</label>
                            </div>
                            <a href="#" class="text-primary forget-password">Forgot Password?</a>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">Login</button>
                    </div>

                    <div class="forgot-password-details">
                        <img src="assets/images/Evcity/logo-navigation.svg" alt="" class="mt-3 mb-3 d-flex" style="margin-left: auto !important; margin-right: auto !important;">
                        <h5 class="font-weight-bold text-center mb-4">Forgot password</h5>
                        <p class="text-center">Enter email ID associated with your account. </p>
                        <div class="form-layout mt-3">
                            <div class="form-group">
                                <input type="email" class="input-field" placeholder="Enter email">
                                <label>Email address</label>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary btn-block forget-continue">Continue</button>
                        <a href="#" class="text-center text-primary mt-5 mb-4 back-to-login">Back To Login</a>
                    </div>

                    <div class="otp-verification">
                        <img src="assets/images/Evcity/logo-navigation.svg" alt="" class="mt-3 mb-3 d-flex" style="margin-left: auto !important; margin-right: auto !important;">
                        <h5 class="font-weight-bold text-center mb-4">OTP Verification</h5>
                        <p>Please type the verification code sent to <span class="font-weight-bold text-dark">rishabh@appigizer.com</span>  <a href="#" class="text-primary change"> Change?</a></p>

                        <div class="d-flex justify-content-between mt-3">
                            <div class="w-25 text-center">
                                <input type="text" class="opt-text-field w-100">
                            </div>
                            <div class="w-25 text-center">
                                <input type="text" class="opt-text-field w-100">
                            </div>
                            <div class="w-25 text-center">
                                <input type="text" class="opt-text-field w-100">
                            </div>
                            <div class="w-25 text-center">
                                <input type="text" class="opt-text-field w-100">
                            </div>
                        </div>
                        <div class="inform d-flex justify-content-between mt-4 mb-4">
                            <a href="#" class="text-muted">Resend Mail</a>
                            <p>30s</p>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block mt-5">Continue</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
    </div>