import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as $ from 'jquery';
import { ReqRideService } from '../req-ride.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';
import { RequestRide } from '../request-ride';
import { HttpClient } from '@angular/common/http';
// import { passengerMax } from '../passenger.validator';
// import { EndDate } from '../endDate.validator';
// import { MapsAPILoader } from '@agm/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { event } from 'jquery';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-day-rent',
  templateUrl: './day-rent.component.html',
  styleUrls: ['./day-rent.component.css'],
  providers: [DatePipe],
})
export class DayRentComponent implements OnInit {
  // form: FormGroup | any;
  dayRentalForm: FormGroup | any;
  // airportDropForm:FormGroup | any;
  airDayRentalPostForm: FormGroup | any;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.UnitedKingdom,
  ];
  public submitted: boolean = false;

  selectedPlace: any;
  json = JSON;
  optionsi = {
    componentRestrictions: {
      country: 'US',
    },
  };

  errMsg: boolean = false;
  maxData: boolean = false;

  constructor(
    private Service: ReqRideService,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private ref: ChangeDetectorRef,

    private toast: ToastrService,
    private titleHeader: Title
  ) {
    this.dayRentalForm = this.fb.group({
      dayRentControl: new FormControl(
        this.carsTypeDayRental,
        Validators.required
      ),

      dayRentPlanControl: new FormControl('', Validators.required),
    });
    this.DayRentalPostForm();
  }

  // private maxDateValidator(frm:FormGroup){
  //   if(frm.get('start_date').value < this)

  // }
  onChange(event: any) {
    // console.log(event.target.value);
  }

  placeChanged(place: any) {
    this.selectedPlace = place;
    this.ref.detectChanges();
    // console.log(this.selectedPlace);
  }

  disabledDate: [''] | any;

  changeDate(e: any) {
    // console.log(e.format('DD-MM-YYYY HH:mm' ));
    this.disabledDate = e.format('DD-MM-YYYY HH:mm');
    // console.log(this.disabledDate);
  }

  dateValidator() {
    if (this.dateStart <= this.dateEnd) {
      // console.log("Validator Work");
    } else {
      // console.log("Not Work");
    }
  }

  start_date = new Date();
  end_date = new Date();

  Start_Date: any = {
    locale: moment.locale('ru'),
    // format: 'DD/MM/YYYY HH:mm',
  };

  End_Date: any = {
    // format: 'DD/MM/YYYY HH:mm',
  };

  update() {
    this.Start_Date.minDate = new Date();
    this.End_Date.minDate = this.start_date;
  }

  get f() {
    return this.airDayRentalPostForm.controls;
  }

  min = 1;
  max = 7;
  model: any = [];
  error: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  obama: boolean = false;

  DayRentalPostForm() {
    // console.log(this.maxPass, "kkkk");
    //
    this.airDayRentalPostForm = this.fb.group({
      start_date: ['', Validators.required],
      // end_date: ['', [Validators.required]],
      passenger_name: ['', Validators.required],

      total_passenger: ['', [crossVal]],
      passenger_contact_number: ['', [Validators.required]],
      km: [''],
      basicFare: [''],

      source: ['', Validators.required],
      destination: [''],

      img: [''],
      car_name: [''],
      country_code: [''],

      payment_detail: ['bill_to_agency'],
      // model_id:[''],
      fare_id: [''],
      executive_name: [''],
      remarks: [''],
      // flight_number: [''],
    });
    // console.log("This is max passenger" + this.maxPass);
  }

  // Only Alphabets with Some Characters [-_ ]
  keyPressAlphaNumericWithCharacters(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow alpahbets, space, underscore
    if (/[a-zA-Z-_ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  dateStart: any;

  changedValueStart(eve: any) {
    this.dateStart = event;
    // console.log(eve);
  }

  dateEnd: any;

  changedValueEnd(ev: any) {
    this.dateEnd = ev;
    // console.log(ev);
  }

  dRentObj: any;

  // airDayPostSubmit(){
  //   console.log('Party');
  // }

  bkId: any;
  showMDL: boolean = false;
  classModal: boolean = false;
  errShow: any;

  // validateDates(sDate:string, eDate:string){
  //   this.isValidDate = true;
  //   if((sDate == null || eDate == null)){
  //     this.error={isError:true, errorMessage:'BothDate Required'};
  //     console.log(this.error);
  //     this.isValidDate = false;
  //   }

  //   if((sDate != null && eDate != null) && (eDate)< (sDate)){
  //     this.error={isError:true, errorMessage:'End Date Should be grater then Start Date'};
  //     console.log(this.error);

  //     this.isValidDate = false;
  //   }
  //   return this.isValidDate;
  // }

  airDayPostSubmit() {
    // console.log();

    this.submitted = true;

    if (!this.showMeAfterDayRental) {
      this.toast.info('Choose First Plan.');
    } else {
      // console.log(this.maxPass);
      this.dRentObj = this.airDayRentalPostForm.value;

      this.dRentObj.start_date = this.datePipe.transform(
        this.dRentObj.start_date,
        'dd-MM-YYYY HH:mm'
      );

      // this.dRentObj.end_date = this.datePipe.transform(
      //   this.dRentObj.end_date,
      //   'dd-MM-YYYY HH:mm'
      // );

      // this.isValidDate = this.validateDates(this.dRentObj.StartDate, this.dRentObj.EndDate);

      this.dRentObj.country_code =
        this.dRentObj.passenger_contact_number.dialCode;
      // data.passenger_contact_number = data.passenger_contact_number.number.slice(1).split(" ").join("");

      this.dRentObj.passenger_contact_number =
        this.dRentObj.passenger_contact_number.number
          .slice(1)
          .split(' ')
          .join('');
      this.dRentObj.km = this.dayRentalFareOBJ.kilometer;
      this.dRentObj.basicFare = this.dayRentalFareOBJ.basic_fare;
      // for google search get source
      // this.dRentObj.source = this.add;
      this.dRentObj.img = this.dayRentalPlansOBJ.image;
      this.dRentObj.name = this.dayRentalPlansOBJ.name;
      this.dRentObj.model_id = this.dayRentalPlansOBJ.id;
      this.dRentObj.fare_id = this.dayRentalFareOBJ.fare_id;
      // console.log(this.dRentObj);
      // console.log("Test");
      // console.log(this.airDayRentalPostForm.value);
      if (this.dRentObj.start_date != this.dRentObj.end_date) {
        this.Service.dayRentPost(this.dRentObj).subscribe((res) => {
          if (this.airDayRentalPostForm.valid) {
            if (res.success == true) {
              this.bkId = res.booking_id;
              this.showMDL = true;
              this.classModal = true;
              this.airDayRentalPostForm.reset();
              this.getBookings();
              // console.log(res);
              this.getCarsModelsDayRental();
              this.toast.success(res.message, 'Success');
            } else {
              // console.warn(res);
              this.toast.error(res.message, 'Failed');

              // this.toast.error(res.message,"Something Wrong");
            }
          } else {
            // console.log(res);
            this.toast.error(res.message);
          }
          this.toast.error(res.message);

          // console.log(res);
        });
      } else {
        // console.log('Sry Bros');
        this.toast.error('Start Date and End Date Are Same..', 'Error');
      }
    }
  }

  closeModal() {
    this.showMDL = false;
    this.showMeAfterDayRental = false;
    this.showImgDayRent = false;
    this.showMeDayRental = true;
    this.submitted = false;
  }

  latitude: any;
  longitude: any;
  zoom: any;
  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }

  ngOnInit(): void {
    this.setTitle('Day Rental | Evcityride');
    // this.spinner.show();

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);

    setTimeout(() => {
      $('.preloader').css('display', 'none');
      $('.preloader').fadeOut('slow');
    }, 1000);

    this.End_Date.minDate = this.start_date;
    this.Start_Date.minDate = new Date();

    // var input:any = document.getElementById('search_input');
    // var autocomplete = new google.maps.places.Autocomplete(input);

    // let place :google.maps.places.PlaceResult = autocomplete.getPlace();
    // this.latitude = place.geometry?.location.lat();
    // this.longitude = place.geometry?.location.lng();
    // this.zoom = 12;

    // console.log(input);

    // this.mapsAPILoader.load().then(() => {
    //   this.setCurrentLocation();
    //   this.geoCoder = new google.maps.Geocoder;

    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       this.latitude = place.geometry.location.lat();
    //       this.longitude = place.geometry.location.lng();
    //       this.zoom = 12;
    //     });
    //   });
    // });

    // var searchInput = 'search_input';

    $(document).ready(function () {
      $('#phone').css('height', '65px');
      $('#phone').css('font-size', '18px');
      $('.backdrop').hide();
      $('.drower-menu').hide();
      $('.iti--allow-dropdown').css('width', '100%');
      // ----------------------------------------------------

      // Chose Plan
      $('#choose-plan-tri-3').click(function () {
        $('#car-type-3').addClass('show-drower');
        $('.backdrop').show();
        // alert("Drower-3");
      });

      $('#close').click(function () {
        $('#car-type-3').removeClass('show-drower');
        $('.backdrop').hide();
      });

      $('.backdrop').click(function () {
        $('#car-type-3').removeClass('show-drower');
        $('.backdrop').hide();
      });

      $('#close, .backdrop').click(function () {
        $('.drower').removeClass('show-drower');
        $('.backdrop').hide();
      });
      // ----------------------------------------------------

      // ----------------------------------------------------
      // This code is reserved for aside menu in mobile screen
      $('aside').hide();
      $('.backdrop').hide();

      $('.backdrop').click(function () {
        $('aside').hide();
        $('.backdrop').hide();
        $('aside').removeClass('show-drower');
      });
      $('.menu-icon').click(function () {
        $('aside').show();
        $('.backdrop').show();
      });
      // ----------------------------------------------------
    });

    // this.getCarsModelsPickup();
    // this.getSrcDestiAirPickup();
    // this.getSrcDestiAirDrop();
    this.getCarsModelsDayRental();
    // this.getCarsModelsDrop();
  }

  add: any;

  handleChange(address: any) {
    this.add = address.formatted_address;

    // console.log(this.add);
  }

  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;

  //       this.zoom = 8;
  //       this.getAddress(this.latitude, this.longitude);
  //     });
  //   }
  // }
  // geoCoder:any;
  // address:any;

  //   getAddress(latitude: any, longitude: any) {
  //     this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: { formatted_address: any; }[], status: string) => {
  //       if (status === 'OK') {
  //         if (results[0]) {
  //           this.zoom = 12;
  //           this.address = results[0].formatted_address;
  //         } else {
  //           window.alert('No results found');
  //         }
  //       } else {
  //         window.alert('Geocoder failed due to: ' + status);
  //       }

  //     });
  //   }

  dayRentalPlansOBJ: any = { max_passenger: 7 };
  dayRentalFareOBJ: any;

  showingImgDayRent: any;
  showImgDayRent: boolean = false;

  showMeDayRental: boolean = true;
  showMeAfterDayRental: boolean = false;
  maxPass: any;

  postRental() {
    // console.log(this.dayRentalForm.value);

    if (this.dayRentalForm.valid) {
      this.dayRentalPlansOBJ = this.dayRentalForm.value.dayRentControl;
      this.dayRentalFareOBJ = this.dayRentalForm.value.dayRentPlanControl;
      this.maxPass = this.dayRentalPlansOBJ.max_passenger;
      // console.log(this.maxPass);
      // crossVal(this.maxPass)
      // console.log(this.dayRentalPlansOBJ, "fromTime");
      // console.log(this.dayRentalFareOBJ);

      this.showMeDayRental = false;
      this.showMeAfterDayRental = true;
      this.showImgDayRent = true;
      this.obama = true;
      // this.DayRentalPostForm();
      $('.drower').removeClass('show-drower');
      $('.backdrop').hide();
      // console.log("right close");
    } else {
      // console.log("Error bhura");
    }
  }

  formatAdd: any;

  public handleAddressChange(address: any) {
    this.formatAdd = address.format_Add;
  }

  closy() {
    $('.drower').removeClass('show-drower');
    $('.backdrop').hide();
    // console.log("right close");
  }

  carsTypeDayRental: RequestRide | any;
  fareDrop: RequestRide | any;

  getCarsModelsDayRental() {
    this.Service.choosePlanDay_Rental().subscribe((res) => {
      // console.log(res);
      this.carsTypeDayRental = res.models;
      // console.log(this.carsTypeDayRental);
      if (this.carsTypeDayRental.length > 0) {
        this.dayRentalForm.controls['dayRentControl'].setValue(
          this.carsTypeDayRental[0]
        );
        this.dayRentalForm.controls['dayRentPlanControl'].setValue(
          this.carsTypeDayRental[0].fares[0]
        );
      }
    });
  }

  faresPlanDayRental: RequestRide | any;

  clickDay_RentalFares(faresDay_Rental: any) {
    this.faresPlanDayRental = faresDay_Rental;
    // console.log(this.faresPlanDayRental[0]);
    this.dayRentalForm.controls['dayRentPlanControl'].setValue(
      this.faresPlanDayRental[0]
    );
  }
  // this is choose_plan---> day_rental section End

  // Hide and Show Choose Plan And Images DAY_RENTAL Start
  // selectedDayRent:any;
  // showingImgDayRent:any;
  // showImgDayRent:boolean=false;

  // dayRentalIMGchange(event:any){
  //   this.selectedDayRent = event.target.value;
  // this.showingImgDayRent = JSON.parse(this.selectedDayRent);
  // this.showImgDayRent = true;
  // console.log("Show Image"  + this.showingImgDayRent.image);

  // }

  // fareSelectedDayRent:any;
  // fareParseDayRent:any;

  // fareDayRentalChanger(event:any){
  //   this.fareSelectedDayRent = event.target.value;
  //   this.fareParseDayRent = JSON.parse(this.fareSelectedDayRent);
  //   console.log(this.fareParseDayRent);
  //   this.showMeDayRental = false;
  //   this.showMeAfterDayRental = true;

  // }

  // Hide and Show Choose Plan And Images DAY_RENTAL End

  // Hide and Show Choose Plan And Images AIRPORT_DROP Start
  // selectedDROP:any;
  // showingImgDROP:any;
  // showImgDROP:boolean=false;

  // DROPIMGchange(event:any){
  //   this.selectedDROP = event.target.value;
  // this.showingImgDROP = JSON.parse(this.selectedDROP);
  // this.showImgDROP = true;
  // console.log(this.showingImgDROP.image);

  // }

  // showMeDROP:boolean = true;
  // showMeAfterDROP:boolean = false;

  // fareSelectedDROP:any;
  // fareParseDROP:any;

  // fareDROPChanger(event:any){
  //   this.fareSelectedDROP = event.target.value;
  //   this.fareParseDROP = JSON.parse(this.fareSelectedDROP);
  //   console.log(this.fareParseDROP);
  //   this.showMeDROP = false;
  //   this.showMeAfterDROP = true;

  // }

  // Hide and Show Choose Plan And Images AIRPORT_DROP End

  // Hide and Show Choose Plan And Images AIRPORT_PICKUP Start
  // selectedPICK:any;
  // showingImgPICK:any;
  // showImgPICK:boolean=false;

  // PICKIMGchange(event:any){
  //   this.selectedPICK = event.target.value;
  // this.showingImgPICK = JSON.parse(this.selectedPICK);
  // this.showImgPICK = true;
  // console.log(this.showingImgPICK.image);

  // }

  // showMePICK:boolean = true;
  // showMeAfterPICK:boolean = false;

  // fareSelectedPICK:any;
  // fareParsePICK:any;

  // farePICKChanger(event:any){
  //   this.fareSelectedPICK = event.target.value;
  //   this.fareParsePICK = JSON.parse(this.fareSelectedPICK);
  //   console.log(this.fareParsePICK);
  //   this.showMePICK = false;
  //   this.showMeAfterPICK = true;

  // }

  // Hide and Show Choose Plan And Images AIRPORT_PICKUP End

  srcPickup: RequestRide | any;
  destiPickup: RequestRide | any;
  // airport_pickup:'' | any;
  // skk:any;

  getSrcDestiAirPickup() {
    this.Service.srcDestiAirportPickup().subscribe((res) => {
      this.srcPickup = res.source;
      // console.log(this.srcPickup);
      this.destiPickup = res.destination;
      // console.log(this.destiPickup);
    });
  }

  srcDrop: RequestRide | any;
  destiDrop: RequestRide | any;

  getSrcDestiAirDrop() {
    this.Service.srcDestiAirportDrop().subscribe((res) => {
      this.srcDrop = res.source;
      this.destiDrop = res.destination;
    });
  }

  submitForm() {
    // console.log();
  }

  options: AnimationOptions = {
    path: '../../../assets/checkmark.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    // console.log(animationItem);
  }

  bookingData: RequestRide | any;
  booking_id: any;
  carType: any;
  // hours:any;

  trip_date: any;
  trip_end_date: any;

  // plan_price:any;
  source: any;
  destination: any;

  getBookings() {
    this.Service.getBookingScreen(this.bkId).subscribe((res) => {
      var bookingDetail = res.data;
      this.booking_id = bookingDetail.booking_id;

      this.carType = bookingDetail.carType;
      this.trip_date = bookingDetail.trip_date;
      this.source = bookingDetail.source;
      this.destination = bookingDetail.destination;
      this.trip_end_date = bookingDetail.trip_end_date;
      // console.log(bookingDetail);
    });
  }
}
function e(e: any) {
  throw new Error('Function not implemented.');
}

function crossVal(
  this: any,
  control: AbstractControl
): { [key: string]: any } | null {
  // console.log(this.dayRentalPlansOBJ.max_,"thisddd")
  if (
    control.value !== undefined &&
    (isNaN(control.value) || control.value > 7)
  ) {
    // console.log("this.maxPass", this);
    return { crossVal: true };
  }
  return null;
}
