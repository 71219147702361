<div class="preloader"></div>
<a
  (click)="showTrackTrip()"
  style="cursor: pointer"
  class="text-dark font-weight-bold mt-3 d-block mb-2"
>
  <img src="assets/images/icons/arrow-left.svg" alt="" class="mr-2" />
  Bookings</a
>

<div class="row styleMap">
  <div class="col-md-12 col-sm-12">
    <!-- <div class="container"> -->

    <!-- <agm-map [latitude]="lat" [longitude]="lng" [disableDefaultUI]="false">
      <agm-direction
        [renderOptions]="renderOptions"
        [markerOptions]="markerOptions"
        [waypoints]="waypoints"
        [origin]="origin"
      >
      </agm-direction>
    </agm-map> -->

    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="16">
      <agm-marker
        iconUrl="assets/carESmall.png"
        [latitude]="lat"
        [longitude]="lng"
      ></agm-marker>

      <!-- <agm-direction
        [renderOptions]="renderOptions"
        [markerOptions]="markerOptions"
        [waypoints]="waypoints"
        [origin]="origin"
      >
      </agm-direction> -->
    </agm-map>
    <!-- <h1>{{ lat }} {{ lng }}</h1> -->

    <!-- </div> -->
    <div class="col-md-12 col-sm-12" style="margin-top: 17px">
      <a
        class="btn btn-primary btn-block"
        href="tel:{{ driver_number }}"
        style="
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        Call To Driver</a
      >
    </div>
  </div>
</div>
