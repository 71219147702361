import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BookingListingServiceService } from '../bookings/booking-listing-service.service';

@Component({
  selector: 'app-req-layout',
  templateUrl: './req-layout.component.html',
  styleUrls: ['./req-layout.component.css']
})

export class ReqLayoutComponent implements OnInit {
  // billingDatePost: FormGroup | any;

  
  // startDate:any;
  // endDate :any;
  // startOptions: any = {format: 'DD.MM.YYYY', locale: 'fr'};
  // endOptions: any = {format: 'DD.MM.YYYY'};
  constructor(private ss:BookingListingServiceService) { }

  amazi:any[] = [];
  ngOnInit(): void {
    this.ss.getDataPlaceholder().subscribe(res=>{
      this.amazi = res;
    })

    // this.endOptions.minDate = this.startDate;
    // this.startOptions.maxDate = this.endDate;
  }

  // update() {
  //   this.startOptions.maxDate = this.endDate;
  //   this.endOptions.minDate = this.startDate;
  // }

  // billingSubmit(){

  // }
}
