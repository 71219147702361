<!-- <div class="col-sm-12 col-md-8 col-lg-9 details"> -->



    <!-- Back Link -->
    <a  style="cursor: pointer;" (click)="gotoBooking()" class="text-dark font-weight-bold mt-3 d-block">
        <img src="assets/images/icons/arrow-left.svg" alt="" class="mr-2">
         Saffron Leaf to Jolly Airport</a>

    <!-- Row Start -->
    <div class="row mt-3">
        <!-- Column Start -->
        <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
            <div class="card">
                <div class="card-header bg-white">
                    <p class="mb-0 text-dark font-weight-bold">Source and Destination.</p>
                </div>
                <div class="card-body">
                    <div class="d-flex h-100">
                        <div class="left d-flex flex-column justify-content-between align-items-center" style="    margin-right: 20px; margin-top: 10px; margin-bottom: 18px;">
                            <div class="round-bg"></div>
                                <div class="round-bg small"></div>
                                <div class="round-bg small"></div>
                                <div class="round-bg small"></div>
                                <div class="round-bg small"></div>
                                <div class="round-bg small"></div>
                            <div class="round-bg"></div>
                        </div>
                        <div class="right d-flex flex-column h-100 justify-content-between">
                            <div class="left mb-4 d-flex">
                                <img src="assets/images/icons/Hotel.svg" alt="">
                                <div class="content" style="padding-left: 30px;">
                                    <p class="mb-0 font-weight-bold text-dark">Jolly Airport</p>
                                    <p class="mb-0 text-muted">01:00 PM | Sat, 9 July 2021</p>
                                </div>
                            </div>
                            <div class="right d-flex">
                                <img src="assets/images/icons/airplane icon.svg" alt=""   >
                                <div class="content pl-3">
                                    <p class="mb-0 font-weight-bold text-dark">Goa Dune</p>
                                    <p class="mb-0 text-muted">04:00 PM | Sat, 9 July 2021</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        <!-- Column End -->

        <!-- Column Start -->
        <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
            <div class="card h-100">
                <div class="card-header bg-white ">
                    <p class="font-weight-bold text-dark mb-0">Passenger Details</p>        
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Passenger Name</p>
                            <p class="mb-0" style="font-size: 18px;">Kareliya Sachin</p>
                        </div>
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Contact Details</p>
                            <p class="mb-0" style="font-size: 18px;">+91-9687856836</p>
                        </div>
                        <div class="col-6">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Passenger Count</p>
                            <p class="mb-0" style="font-size: 18px;">5</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Column End -->

        <!-- Column Start -->
        <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
            <div class="card h-100">
                <div class="card-header bg-white">
                    <p class="font-weight-bold text-dark mb-0">Trip Details</p>        
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Booking ID</p>
                            <p class="mb-0" style="font-size: 18px;">DHR0012</p>
                        </div>
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Booking Date</p>
                            <p class="mb-0" style="font-size: 18px;">31/05/2021 at 11:00 AM</p>
                        </div>
                        <div class="col-6">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Trip Start Date & Time</p>
                            <p class="mb-0" style="font-size: 18px;">09/07/2021 at 05:00 AM</p>
                        </div>
                        <!-- <div class="col-6">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Car Type</p>
                            <p class="mb-0" style="font-size: 18px;">Nexon Premium</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Column End -->

        <!-- Column Start -->
        <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
            <div class="card h-100">
                <div class="card-header bg-white">
                    <p class="font-weight-bold text-dark mb-0">Pailot Details</p>        
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Driver Name</p>
                            <p class="mb-0" style="font-size: 18px;">Ramesh Sanchaniya</p>
                        </div>
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Driver Contact Number</p>
                            <p class="mb-0" style="font-size: 18px;">+91-9870654321</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Column End -->
    </div>
    <!-- Row End -->

    <a routerLink="/rider/track_trip_details" class="btn btn-primary sm-btn-block" data-toggle="modal" data-target="#exampleModal">Track Trip</a>
    
<!-- </div> -->