import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-web-login',
  templateUrl: './web-login.component.html',
  styleUrls: ['./web-login.component.css']
})
export class WebLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function(){
           

      $(".back-to-login").click(function(){
          $(".forgot-password-details").hide();
          $(".login").show();
      });
      
      $(".forget-password").click(function(){
          $(".forgot-password-details").show();
          $(".login").hide();
      });

      $(".forget-continue").click(function(){
          $(".otp-verification").show();
          $(".login").hide();
          $(".forgot-password-details").hide();
      });

      $(".change").click(function(){
          $(".otp-verification").hide();
          $(".login").hide();
          $(".forgot-password-details").show();
      });

      const password = $("#password");

      $(".eye").click(function(){
          if(password.prop('type') == 'password'){
              $(this).addClass('fa-eye-slash');                
              password.attr('type', 'text');
          }else{
              $(this).removeClass("fa-eye-slash");
              password.attr('type', 'password');
          }
      });

  });
  }

}
