import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css'],
})
export class MainLayoutComponent implements OnInit {
  constructor(private netcheck: ConnectionService) {}
  isConnected = true;
  nointerNet: boolean = true;
  showNowNot: boolean = true;

  noInternetConnection: boolean | undefined;

  ngOnInit(): void {
    this.netcheck.monitor().subscribe((isConnect) => {
      this.isConnected = isConnect;

      if (this.isConnected) {
        this.noInternetConnection = false;
        this.nointerNet = true;
      } else {
        this.noInternetConnection = true;
        this.nointerNet = false;
      }
    });

    $(document).ready(function () {
      $('.backdrop').hide();
    });

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);
  }
}
