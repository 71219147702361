import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';
import { TrackServiceService } from '../../track-trip/track-service.service';
import { BookingListing } from '../booking-listing';
import { BookingListingServiceService } from '../booking-listing-service.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-ongoing',
  templateUrl: './ongoing.component.html',
  styleUrls: ['./ongoing.component.css'],
})
export class OngoingComponent implements OnInit {
  searchIcon: boolean = true;
  nameSearch: string = '';
  hellow: boolean = false;
  hidePages: boolean = true;

  constructor(
    private router: Router,
    private Service: BookingListingServiceService,
    private titleHeader: Title,
    private http: HttpClient,
    public Service_Track: TrackServiceService
  ) {}
  // @Input() searchData:string | undefined;
  searchData = '';

  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }

  totalItems: any;
  localGetOngoing: any;
  ngOnInit(): void {
    localStorage.removeItem('searchPast');
    localStorage.removeItem('searchUpcoming');
    // localStorage.setItem('searchOngoing','')
    setTimeout(() => {
      $('.preloader').css('display', 'none');
      $('.preloader').fadeOut('slow');
    }, 1000);
    this.setTitle('Bookings - Ongoing | Evcityride');

    if (localStorage.searchOngoing == null) {
      this.getOngoing();
      // this.searchIcon = true;
    } else {
      this.localGetOngoing = localStorage.getItem('searchOngoing');

      this.Service.getSearchResultOngoing(
        this.localGetOngoing,
        this.pg
      ).subscribe((res) => {
        this.ongoing = res.bookings;
        this.totalItems = res.total_items;
        // console.log('LocalStorage get');
      });
    }

    this.Service_Track.anonymousLogin();

    //   if(!navigator.geolocation){
    //     console.log('Location Not supported');
    // }
    // navigator.geolocation.getCurrentPosition((ongoing:any)=>{
    //     console.log(
    //         `lat :${ongoing.coords.latitude}, lon:${ongoing.coords.longitude}`
    //     )
    // });

    //   For Search Icon Jquery...
    $(document).ready(function () {
      $('#search-field').hide();

      $('#search-icon').click(function () {
        $('#search-field').show();
        $('#search-icon').hide();
      });

      $('#search-field').focusin(function () {
        $('#search-icon').hide();
      });

      $('#search-field').focusout(function () {
        $('#search-icon').show();
        $('#search-field').hide();
      });
    });
  }

  ongoing: BookingListing | any;

  laty: any;
  longi: any;
  noBookings: boolean = false;
  pg: any;
  p: number | any;

  getOngoing() {
    //    this.spinner.show();
    // this.spinner.show();

    this.Service.getOngoingBookings(this.pg).subscribe((res) => {
      if (res.bookings != 0) {
        this.ongoing = res.bookings;
        this.totalItems = res.total_items;
        // console.log(res,'Data Che');

        // this.spinner.hide();
        this.searchIcon = false;
        // $("#search-icon").show();

        // $("#search-field").hide();
        setTimeout(() => {
          $('.preloader').css('display', 'none');
          $('.preloader').fadeOut('slow');
        }, 1000);
        $('.searchStyles').removeClass('dynamicClassAdd');
      } else {
        // this.spinner.hide();
        // console.log(res,'Data Nthi');
        this.searchIcon = true;
        this.noBookings = true;
        this.hidePages = false;
        // this.spinner.hide();

        // $("#search-icon").hide();
        // $("#search-field").hide();
        setTimeout(() => {
          $('.preloader').css('display', 'none');
          $('.preloader').fadeOut('slow');
        }, 1000);
        $('.searchStyles').addClass('dynamicClassAdd');
      }

      // this.ongoing.forEach((value:any) =>{
      //     // console.log(value);
      //     this.laty = value.source_latitude;
      //     this.longi = value.source_longitude
      //     console.log(this.laty);
      //     console.log(this.longi);
      // });
    });
  }

  searchRes: any = '';

  getPage(page: any) {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);

    // if(localStorage.searchOngoing === ''){
    //   const baseUrl = `https://evcityride.9brainz.store/api/v1/booking-listing?type=ongoing&agency_id=${localStore.agency_id}&page=${page}`

    //   this.http.get(baseUrl).subscribe((data: any) => {
    //     console.log(data);
    //     this.ongoing =  data.bookings;
    //     this.totalItems = data.total_items;

    //   });
    // }else{
    const baseUrl = `https://admin.evcityride.com/api/v1/booking-search?type=ongoing&agency_id=${localStore.agency_id}&page=${page}&search${this.searchRes}`
    // const baseUrl = `https://evcityride.9brainz.store/api/v1/booking-search?type=ongoing&agency_id=${localStore.agency_id}&page=${page}&search${this.searchRes}`;

    this.http.get(baseUrl).subscribe((data: any) => {
      // console.log(data);
      this.ongoing = data.bookings;
      this.totalItems = data.total_items;
    });
    // }
    //   const baseUrl = `https://evcityride.9brainz.store/api/v1/booking-listing?type=ongoing&agency_id=${localStore.agency_id}&page=${page}`

    // this.http.get(baseUrl).subscribe((data: any) => {
    //   console.log(data);
    //   this.ongoing =  data.bookings;
    //   this.totalItems = data.total_items;

    // });
  }

  onSearchChange(searchValue: any) {
    this.searchRes = searchValue.target.value;
    localStorage.setItem('searchOngoing', this.searchRes);

    this.Service.getSearchResultOngoing(this.searchRes, this.p).subscribe(
      (res) => {
        // console.log(res);
        this.ongoing = res.bookings;
        this.totalItems = res.total_items;
      }
    );
  }
}
