import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
// import { NgxSpinnerService } from 'ngx-spinner';
import { Billing } from '../billing';
import { BillingServiceService } from '../billing-service.service';

@Component({
  selector: 'app-self-drive',
  templateUrl: './self-drive.component.html',
  styleUrls: ['./self-drive.component.css'],
})
export class SelfDriveComponent implements OnInit {
  p: number = 1;

  searchBillData = '';
  Airport_Self_Drive: any = [
    {
      id: '1',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
    {
      id: '2',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
    {
      id: '3',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
  ];

  Airport_Self_Drive_Two: any = [
    {
      id: '1',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
    {
      id: '2',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
    {
      id: '3',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
  ];

  constructor(
    private router: Router,
    private Service: BillingServiceService,
    private http: HttpClient,
    private titleHeader: Title
  ) {}
  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  ngOnInit(): void {
    this.setTitle('Billings-Self Drive | Evcityride');

    setTimeout(() => {
      $('.preloader').css('display', 'none');
      $('.preloader').fadeOut('slow');
    }, 2000);

    this.getDayRentalRequest();
    $(document).ready(function () {
      $('body').css('overflow', 'auto');
      $('#search-field').hide();
      $('.ngx-pagination').css('padding', '0');

      // $(".tri").click(function(){
      //     $(".backdrop").show();
      //     $(".drower").show();
      //     $("body").css("overflow", "hidden");
      // });

      $('#close').click(function () {
        $('.backdrop').hide();
        $('.drower').removeClass('show-drower');
        $('body').css('overflow', 'auto');
      });

      $('.backdrop').click(function () {
        $('.backdrop').hide();
        $('.drower').removeClass('show-drower');
      });

      $('.radio').click(function () {
        $('.time-fields').addClass('d-none');
      });

      $('#year').click(function () {
        $('.time-fields').removeClass('d-none');
      });

      $('.clear-all').click(function () {
        $('.time-fields').addClass('d-none');
      });

      $('#search-icon').click(function () {
        $('#search-field').show();
        $('#search-icon').hide();
      });

      $('#search-field').focusin(function () {
        $('#search-icon').hide();
      });

      $('#search-field').focusout(function () {
        $('#search-icon').show();
        $('#search-field').hide();
      });

      $('aside').hide();
      $('.backdrop').hide();

      $('.backdrop').click(function () {
        $('aside').hide();
        $('.backdrop').hide();
      });

      $('.menu-icon').click(function () {
        $('aside').show();
        $('.backdrop').show();
      });

      $('.fa-times').click(function () {
        $('.drower').removeClass('show-drower');
        $('.backdrop').hide();
      });

      $('.tri').click(function () {
        $('.drower').addClass('show-drower');
        $('.backdrop').show();
      });

      $('#close').click(function () {
        $('.drower').removeClass('show-drower');
        $('.backdrop').hide();
      });

      $('.backdrop').click(function () {
        $('.drower').removeClass('show-drower');
        $('.backdrop').hide();
      });
    });
  }
  // url = 'https://evcityride.9brainz.store';
  url = 'https://admin.evcityride.com'

  dayRentalBill: Billing | any;
  nodataMsg: any;
  showMsg: boolean = false;

  getDayRentalRequest() {
    // this.spinner.show();

    if (localStorage.sDate != null) {
      this.Service.requestTypeDayRental().subscribe((res) => {
        if (res.trips != '') {
          this.dayRentalBill = res.trips;

          // this.spinner.hide();
        } else {
          this.showMsg = true;

          // this.nodataMsg= "Sorry, No Data Found, Have a good day....";
          this.nodataMsg =
            '../../../../assets/images/background/booking-bg-1-1.png';
          // this.spinner.hide();
        }
      });
    } else {
      var local = <any>localStorage.getItem('currentUser');
      let localStore = JSON.parse(local);
      this.http
        .get<Billing>(
          this.url +
            '/api/v1/trips?agency_id=' +
            localStore.agency_id +
            '&type=day_rental'
        )
        .subscribe((res) => {
          this.dayRentalBill = res.trips;

          // this.spinner.hide();
        });
    }
  }

  gotoBill() {
    this.router.navigateByUrl('/rider/billing');
  }
}
