import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";


@Component({
  selector: "app-request-a-ride",
  templateUrl: "./request-a-ride.component.html",
  styleUrls: ["./request-a-ride.component.css"],
})
export class RequestARideComponent implements OnInit {
 
  constructor(
    
  ) {
 




  }



  ngOnInit(): void {
    localStorage.removeItem('sDate');
    localStorage.removeItem('eDate');
    localStorage.removeItem('searchOngoing');
    localStorage.removeItem('searchUpcoming');
    localStorage.removeItem('searchPast');
    // this.spinner.show();
    setTimeout(() => {
      $(".preloader").css('display','none');
      $('.preloader').fadeOut('slow');
    }, 1000);

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);

    // setTimeout(() => {
    //   $(".preloader").css('display','none');
    //   $('.preloader').fadeOut('slow');
    // }, 1000);
  
    $(document).ready(function () {
      $(".drower-menu").hide();
      $(".backdrop").hide();
    $("#navbarSupportedContent").removeClass('show');

    $("aside").removeClass("show-drower");


     

      // Select Car type
      $("#choose-plan-tri-1").click(function () {
        $("#car-type-1").addClass("show-drower");
        $(".backdrop").show();
        // alert("Drower-1");
      });

      $("#close").click(function () {
        $("#car-type-1").removeClass("show-drower");
        $(".backdrop").hide();
      });

      $(".backdrop").click(function () {
        $("#car-type-1").removeClass("show-drower");
        $(".backdrop").hide();
      });

      // Select Car type
      $("#choose-plan-tri-2").click(function () {
        $("#car-type-2").addClass("show-drower");
        $(".backdrop").show();
        // alert("Drower-2");
      });

      $("#close").click(function () {
        $("#car-type-2").removeClass("show-drower");
        $(".backdrop").hide();
      });

      $(".backdrop").click(function () {
        $("#car-type-2").removeClass("show-drower");
        $(".backdrop").hide();
      });

      // Chose Plan
      $("#choose-plan-tri-3").click(function () {
        $("#car-type-3").addClass("show-drower");
        $(".backdrop").show();
        // alert("Drower-3");
      });

      $("#close").click(function () {
        $("#car-type-3").removeClass("show-drower");
        $(".backdrop").hide();
      });

      $(".backdrop").click(function () {
        $("#car-type-3").removeClass("show-drower");
        $(".backdrop").hide();
      });

      // Have  1 lu batav

      //   $(".broken").click(function(){
      //     $(".garba").addClass("show-drower");
      //     $(".backdrop").show();
      // });

      $("#close").click(function () {
        $(".drower").removeClass("show-drower");
        $(".backdrop").hide();
      });

      $(".backdrop").click(function () {
        $(".drower").removeClass("show-drower");
        $(".backdrop").hide();
      });

      $("aside").hide();
      $(".backdrop").hide();

      $(".backdrop").click(function () {
        $(".backdrop").hide();
        $("aside").hide();
        $("aside").removeClass("show-drower");
      });

      $(".menu-icon").click(function () {
        $("aside").show();
        $(".backdrop").show();
      });

      $(".fa-times").click(function () {
        $(".drower").removeClass("show-drower");
        $(".backdrop").hide();
      });

      $(".tri").click(function () {
        $(".drower").addClass("show-drower");
        $(".backdrop").show();
        //   alert('Second');
      });

      $("#close").click(function () {
        $(".drower").removeClass("show-drower");
        $(".backdrop").hide();
      });

      $(".backdrop").click(function () {
        $(".drower").removeClass("show-drower");
        $(".backdrop").hide();
      });

      $("#hideModel").click(function () {
        $(".drower").removeClass("show-drower");
        $(".backdrop").hide();
        // alert('heyy');
      });

      $("#hideModel").click(function () {
        var viewBooking = "yes";
        localStorage.setItem("tabValue", viewBooking);
        // window.location.
        // window.location.href="bookings.html";
      });
    });

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);
  }

  condi:boolean=true;

  condition(){
    this.condi= true;
    this.condiDrop = false;
    this.condiRent = false;
  }

  condiDrop:boolean=false;

  conditionDrop(){
    this.condiDrop = true;
    this.condi= false;
    this.condiRent = false;
  }


  condiRent:boolean = false;
  conditionRent(){
    this.condiRent = true;
    this.condiDrop = false;
    this.condi= false;
  }
 




  options: AnimationOptions = {
    path: "../../../assets/checkmark.json",
  };

  animationCreated(animationItem: AnimationItem): void {
    // console.log(animationItem);
  }
}
