<div class="preloader"></div>
     <!-- <a (click)="showTrackTrip()"  *ngIf="mapShow" style="cursor: pointer;" class="text-dark font-weight-bold mt-3 d-block mb-2">
        <img src="assets/images/icons/arrow-left.svg" alt="" class="mr-2">
         Booking Details</a>

 <div *ngIf="mapShow"  class="row styleMap">
    <div class="col-md-12 col-sm-12" >
          
            <agm-map  [latitude]="lat" [longitude]="lng" [disableDefaultUI]="false" >
             

            <agm-direction  
            [renderOptions]="renderOptions" 
[markerOptions]="markerOptions"
[waypoints]="waypoints"
[origin]="origin"
                           [destination]="destinationss"

  >
  </agm-direction>

            </agm-map>
           
        <div class="col-md-12 col-sm-12" style="margin-top: 17px;" >
            <a class="btn btn-primary btn-block" href="tel:{{driver_number}}" style="color: white; display: flex; align-items: center; justify-content: center;">
            Call To Driver</a>
        </div>
    </div>

</div> -->

<!-- <button  class="btn btn-danger btn-block" >Get Firebase</button> -->
<!-- <div class="col-sm-12 col-md-8 col-lg-9 details"> -->

    <!-- <div class="row mt-3">
        <div class="col-sm-12 col-md-12">

            <a (click)="gotoTrackTrip()" style="cursor: pointer;" class="text-dark font-weight-bold d-flex"><img src="assets/images/icons/arrow-left.svg" alt="" class="mr-2"><h5 class="mb-0"> Saffron Leaf</h5><span class="text-muted mr-2 ml-2"> to</span><h5 class="mb-0"> Jolly Airport</h5></a> 

            <div class="mt-3 position-relative w-100">
                <iframe
                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1767638.4179803252!2d78.19075001951867!3d30.082190378879904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3909dcc202279c09%3A0x7c43b63689cc005!2sUttarakhand!5e0!3m2!1sen!2sin!4v1613992442426!5m2!1sen!2sin" style="border:0;" allowfullscreen="" loading="lazy" class="map"></iframe>

                <div class="location-card">
                    <div class="card">
                        
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="left d-flex flex-column justify-content-between align-items-center" style="margin: 10px 20px 18px 0;">
                                    <div class="round-bg"></div>
                                        <div class="round-bg small"></div>
                                        <div class="round-bg small"></div>
                                        <div class="round-bg small"></div>
                                        <div class="round-bg small"></div>
                                        <div class="round-bg small"></div>
                                    <div class="round-bg"></div>
                                </div>
                                <div class="right d-flex flex-column h-100 justify-content-between">
                                    <div class="left mb-4 d-flex">
                                        <img src="assets/images/icons/Hotel.svg" alt="">
                                        <div class="content" style="padding-left: 30px;">
                                            <p class="mb-0 font-weight-bold text-dark">Saffron Leaf</p>
                                            <p class="mb-0 text-muted">01:00 PM | Sat, 8 Jan 2021</p>
                                        </div>
                                    </div>
                                    <div class="right d-flex">
                                        <img src="assets/images/icons/airplane icon.svg" alt="">
                                        <div class="content pl-3">
                                            <p class="mb-0 font-weight-bold text-dark">Jolly Airport</p>
                                            <p class="mb-0 text-muted">12:00 PM | Sat, 8 Jan 2021</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary btn-block mt-3 mb-0">Call To Driver</button>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
    </div> -->
<!-- </div> -->

<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->




<!-- <div class="col-sm-12 col-md-8 col-lg-9 details"> -->

    <!-- Back Link -->
    <a  style="cursor: pointer;" (click)="gotoTrackTrip()" class="text-dark font-weight-bold mt-3 d-block">
        <img src="assets/images/icons/arrow-left.svg" alt="" class="mr-2">
         Booking Details</a>

         <!-- <br><br>
         <table  border="1">
            <tr>
                <th>Source & Destination</th>
                <th>TripID</th>
                <th>Latitude</th>
                <th>Longitude</th>
        
            </tr>
            <tr *ngFor="let sopi of fireData | tripFilter:tripIDS">
                <td>Source Firebase</td>
                <td>{{sopi.tripId}}</td>
                <td>{{sopi.source.lattitude}}</td>
                <td>{{sopi.source.longtitude}}</td>
        
        
            </tr>
            <tr>
                <td>Destination</td>
                <td>{{id}}</td>
                
                <td>{{destination_latitude}}</td>
                <td>{{destination_longitude}}</td>

            </tr>
        </table> -->



        <!-- <agm-map [latitude]="destination_latitude" [longitude]="destination_longitude" [zoom]="zoom" >
            <agm-marker [latitude]="destination_latitude" [longitude]="destination_longitude" [iconUrl]="icon" [title]="'Sachin'" ></agm-marker>
            <agm-marker *ngFor="let sopi of fireData | tripFilter:tripIDS" [latitude]="sopi.source.lattitude" [longitude]="sopi.source.longtitude" [title]="'Jaimin'" ></agm-marker>
    </agm-map> -->
         <!-- <div class="row mt-3">
            <div class="col-sm-12 col-md-12" >
                <p [style.color]="booking_status == 'Driver Not Assigned' ? 'red' : 'green' " style="text-align: center;" ><b>{{booking_status}}</b></p>
            </div>
         </div> -->

    <!-- Row Start -->
    <div class="row mt-3">
        <!-- Column Start -->
        <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
            <div class="card">
                <div class="card-header bg-white">
                    <p *ngIf="request_type == 'airport pickup' " class="mb-0 text-dark font-weight-bold">Airport Pickup</p>
                    <p *ngIf="request_type == 'airport drop' " class="mb-0 text-dark font-weight-bold">Airport Drop</p>
                    <p *ngIf="request_type == 'day rental' " class="mb-0 text-dark font-weight-bold">Day Rental</p>

                </div>
                <div class="card-body">
                    <div class="d-flex h-100">
                        <!-- <div *ngIf="request_type == 'day rental' " class="left d-flex flex-column justify-content-between align-items-center" style="    margin-right: 20px; margin-top: 10px; margin-bottom: 18px;">
                          
                        </div> -->
                        <div *ngIf="request_type !== 'day rental' " class="left d-flex flex-column justify-content-between align-items-center" style="    margin-right: 20px; margin-top: 10px; margin-bottom: 18px;">
                            <div class="round-bg"></div>
                                <div class="round-bg small"></div>
                                <div class="round-bg small"></div>
                                <div class="round-bg small"></div>
                                <div class="round-bg small"></div>
                                <div class="round-bg small"></div>
                            <div class="round-bg"></div>
                        </div>
                        <div *ngIf="request_type == 'day rental' "  class="right d-flex flex-column h-100 justify-content-between" >
                            <div class="left mb-4 d-flex">
                                <img src="assets/images/icons/Hotel.svg" alt="">
                                <div class="content" style="padding-left: 30px;">
                                    <p class="mb-0 font-weight-bold text-dark">{{source}}</p>
                                    <p class="mb-0 text-muted">{{date}}</p>
                                </div>
                            </div>
                            <!-- <div class="right d-flex">
                                <img src="assets/images/icons/airplane icon.svg" alt=""   >
                                <div class="content pl-3">
                                    <p class="mb-0 font-weight-bold text-dark">{{destination}}</p>
                                    <p class="mb-0 text-muted">{{trip_end_date}}</p>
                                </div>
                            </div> -->
                        </div>

                        <div *ngIf="request_type !== 'day rental' " class="right d-flex flex-column h-100 justify-content-between" >
                            <div class="left mb-4 d-flex">
                                <img src="assets/images/icons/airplane icon.svg" alt=""   >

                                <div class="content" style="padding-left: 30px;">
                                    <p class="mb-0 font-weight-bold text-dark">{{source}}</p>
                                    <p class="mb-0 text-muted">{{date}}</p>
                                </div>
                            </div>
                            <div class="right d-flex">
                                <img src="assets/images/icons/Hotel.svg" alt="">

                                <div class="content pl-3">
                                    <p style="margin-left: 22px;" class="mb-0 font-weight-bold text-dark">{{destination}}</p>
                                    <!-- <p class="mb-0 text-muted">{{trip_end_date}}</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        <!-- Column End -->

        <!-- Column Start -->
        <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
            <div class="card h-100">
                <div class="card-header bg-white ">
                    <p class="font-weight-bold text-dark mb-0">Passenger Details</p>        
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Passenger Name</p>
                            <p class="mb-0" style="font-size: 18px;">{{passengerName}}</p>
                        </div>
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Contact Details</p>
                            <a class="mb-0" href="tel:{{passengerContact}}"  style="font-size: 18px;"> <i class="fa fa-phone" aria-hidden="true"></i> {{passengerContact}}
                           </a>
                        </div>
                        <div class="col-6">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Passenger Count</p>
                            <p class="mb-0" style="font-size: 18px;">{{passengerCount}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Column End -->

        <!-- Column Start -->
        <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
            <div class="card h-100">
                <div class="card-header bg-white">
                    <p class="font-weight-bold text-dark mb-0">Trip Details</p>        
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Booking ID</p>
                            <p class="mb-0" style="font-size: 18px;">{{booking_id}}</p>
                        </div>
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Booking Date</p>
                            <p class="mb-0" style="font-size: 18px;">{{booking_date}}</p>
                        </div>
                        <div class="col-6">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Trip Start Date & Time</p>
                            <p class="mb-0" style="font-size: 18px;">{{trip_date}}</p>
                        </div>
                        <div class="col-6">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Car Type</p>
                            <p class="mb-0" style="font-size: 18px;">{{carType}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Column End -->


        <!-- Column Start -->
        <div *ngIf="driver != '' " class="col-sm-12 col-md-12 col-lg-6 mb-4">
            <div class="card h-100">
                <div class="card-header bg-white">
                    <p class="font-weight-bold text-dark mb-0">Driver Details</p>        
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Driver Name</p>
                            <p class="mb-0" style="font-size: 18px;">{{driver}}</p>
                        </div>
                        <div class="col-6 mb-3">
                            <p class="mb-0 text-muted" style="font-size: 13px;">Driver Contact Number</p>
                            <a class="mb-0" href="tel:{{driver_number}}"  style="font-size: 18px;"><i class="fa fa-phone" aria-hidden="true"></i> {{driver_number}}
                                </a>
                            <!-- <p class="mb-0"  style="font-size: 18px;">{{driver_number}}</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Column End -->
        <a (click)="anonymousLogins()" [routerLink]="['/rider/track-trip/map/', id]"   class="btn btn-block btn-primary sm-btn-block mt-0 mb-0 track-trip-btn" 
        data-toggle="modal" >Track Trip</a>
    </div>
    <!-- Row End -->


    <!-- <a *ngIf="booking_status == 'Driver Not Assigned' "  class="btn btn-outline-primary mt-0 mb-0 mr-3" data-toggle="modal" (click)="delete()"
     >Cancle trip</a> -->
     
    <!-- <a *ngIf="ServiCE.isUserAnonymousLoggedIn" (click)="logout()" class="btn btn-block btn-primary sm-btn-block mt-0 mb-0 " style="padding: 15px; font-size: 20px; color:white   ;" data-toggle="modal" >Logout From Firebase Track Trip</a> -->
    <!-- {{tripIDS}} -->
    <br>
<!-- 
    <div class="row styleMap">
        <div class="col-md-12 col-sm-12" >
            <div class="container">
              
                <div #map id="map-canvas"></div>
            </div>
        </div>

    </div> -->
    <!-- <br><br>
    <table border="1">
       <tr>
           <th>
            Distance
        </th>
        <th>
            Time
        </th>
    </tr>
        <tr>
            <td>{{distance}}</td>
            <td>{{time}}</td>
        </tr>
    </table> -->

    <!-- <agm-map [latitude]="lat" [longitude]="lng">

    </agm-map> -->
    
<!-- </div> -->

<div class="backdrop"></div>
