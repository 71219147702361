
    <aside class="aside-menu">
        
        <div class="welcome-message-card">
            <div class="card border border-0">
                <div class="card-body">
                    <div class="welcome-message-container">
                        <div class="message-details">
                            <p class="welcome-massage text-truncate mb-0">Welcome, </p>
                            <strong class="agency-name text-truncate">{{agency.agency_name}}</strong>
                        </div>
                        <div class="action-container">
                            <a (click)="logout()"  class="logout-btn">
                                <img src="assets/images/icons/logout.svg" alt="" class="img-fluid">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <a (click)="setTitle('Request a ride | EVcityride')" class="link-container" routerLinkActive="active"  routerLink="/rider/request-a-ride">
            <!-- <img src="assets/images/Aside/Active-request-a-ride-icon.svg" alt=""> -->
            <img *ngIf="router.url !== '/rider/request-a-ride' " src="assets/images/Aside_new_icons/Request a Ride.svg" alt="">
            <img *ngIf="router.url == '/rider/request-a-ride'  " src="assets/images/Aside_new_icons/Request a Ride Active.svg" alt="">


            <p>Request a ride</p>
        </a>
        
            <a (click)="clickToOngoing()" (click)="setTitle('Bookings | EVcityride')"  class="link-container"
             routerLinkActive="active" routerLink="/rider/bookings" >
           
                <!-- <img  src="assets/images/Aside/Bookings.svg" alt=""> -->
                <img *ngIf="getTle != 'Bookings | EVcityride' " src="assets/images/Aside_new_icons/Bookings.svg" alt="">
                <img *ngIf="getTle == 'Bookings | EVcityride' " src="assets/images/Aside_new_icons/Bookings Active.svg" alt="">
                <p >Bookings</p>
            </a>
      
        

    
        <a (click)="setTitle('Billings | EVcityride')" class="link-container" routerLinkActive="active"   routerLink="/rider/billing">
            <!-- <img src="assets/images/Aside/Billing.svg" alt=""> -->
            <img *ngIf="getTle != 'Billings | EVcityride' " src="assets/images/Aside_new_icons/Billing.svg" alt="">
            <img *ngIf="getTle == 'Billings | EVcityride' " src="assets/images/Aside_new_icons/Billing Active.svg" alt="">
            <p>Billing</p>
        </a>
        <a (click)="setTitle('Invoice | EVcityride')"  class="link-container" routerLinkActive="active"   routerLink="/rider/invoice">
            <!-- <img src="assets/images/Aside/Invoice.svg" alt=""> -->
            <img *ngIf="getTle != 'Invoice | EVcityride' " src="assets/images/Aside_new_icons/Invoice.svg" alt="">
            <img  *ngIf="getTle == 'Invoice | EVcityride' " src="assets/images/Aside_new_icons/Invoice Active.svg" alt="">
            <p>Invoice</p>
        </a> 
        <a (click)="setTitle('Contact-Service | EVcityride')" class="link-container" routerLinkActive="active"   routerLink="/rider/contact-service">
            <!-- <img src="assets/images/Aside/Contact Service.svg" alt=""> -->
            <img *ngIf="router.url !== '/rider/contact-service' " src="assets/images/Aside_new_icons/Contact Service.svg" alt="">
            <img *ngIf="router.url == '/rider/contact-service' " src="assets/images/Aside_new_icons/Contact Service Active.svg" alt="">
            <p>Contact Service</p>
        </a>
        <a  (click)="setTitle('Track Trip | Evcityride')" class="link-container" routerLinkActive="active"   routerLink="/rider/track-trip">
            <img *ngIf="getTle != 'Track Trip | Evcityride' " src="assets/images/Aside_new_icons/Track Trip.svg" alt="">
            <img *ngIf="getTle == 'Track Trip | Evcityride' " src="assets/images/Aside_new_icons/Track Trip Active.svg" alt="">
            <p>Track Trip</p>
        </a> 
    </aside>
