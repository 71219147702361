import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginServiceService } from 'src/app/shared/web-header/login-service.service';
import { NotificationsService } from '../header/notifications.service';
// import { NotificationsService } from 'Temp/src/app/req-ride-layout/header/notifications.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-main-sidebar',
  templateUrl: './main-sidebar.component.html',
  styleUrls: ['./main-sidebar.component.css']
})
export class MainSidebarComponent implements OnInit {

  constructor(public router:Router,
    public titleHead:Title, private loginSer:LoginServiceService, private notifyService:NotificationsService) { }
    agency:any;
  localdetails:any;

titles:any;
  ngOnInit(): void {

    var sk = localStorage.currentUser;
    this.agency = JSON.parse(sk);
    


    $(document).ready(function(){
      $('#invoiceActive').attr('src','assets/images/Aside_new_icons/Invoice Active.svg');
      $('#invoiceInactive').hide();
      
    });
    

  }

  getTle:any;

  public setTitle(newTitle: any) {
    this.titleHead.setTitle(newTitle);
    this.titles = this.titleHead.setTitle(newTitle);
    this.getTle = this.titleHead.getTitle();
    // 
  }

  logout(){
    this.router.navigateByUrl('/');
    localStorage.clear();

    window.location.reload();
  }

  clickToOngoing(){
    this.router.navigateByUrl('/rider/bookings/ongoing');
  }

  // clearLocalStorage(){
  
  // }

}
