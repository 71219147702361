<!-- Main Front EVCITYRIDE Site Layout -->

<!-- <div  class="page-wrapper" *ngIf="nointerNet" >


  <app-web-header *ngIf="router.url !== '/rider'"></app-web-header>
  <router-outlet></router-outlet>
  <app-web-slider *ngIf="router.url=='/'"></app-web-slider>
  <app-web-your-first-choice *ngIf="router.url=='/'"></app-web-your-first-choice>
  <div class="container-fluid" *ngIf="router.url=='/'">
      <div class="row">
          <div class="col-sm-12 p-0">
              <div class="divider"></div>
          </div>
      </div>
  </div>
  <app-web-numbers-speak *ngIf="router.url=='/'"></app-web-numbers-speak>
  <div class="container-fluid" *ngIf="router.url=='/'">
      <div class="row">
          <div class="col-sm-12 p-0">
              <div class="divider"></div>
          </div>
      </div>
  </div>
  <app-web-our-founders *ngIf="router.url=='/' "></app-web-our-founders>
  <div class="container-fluid" *ngIf="router.url=='/'">
      <div class="row">
          <div class="col-sm-12 p-0">
              <div class="divider"></div>
          </div>
      </div>
  </div>

  <app-web-choose-texi *ngIf="router.url=='/'"></app-web-choose-texi>
  <app-web-footer *ngIf="router.url == '/' || router.url == '/about'
  || router.url == '/download_app' || router.url == '/contact' "  ></app-web-footer>

  <a  class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>

  </div> -->

<!-- Main Front EVCITYRIDE Site Layout -->

<!-- <div class="preloader"></div> -->
<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
<div class="preloader"></div>

<div
  *ngIf="router.url == '/' && !noInternetConnection"
  class="wrapper nav-collapsed menu-collapsed"
>
  <div class="main-panel">
    <section id="login">
      <div class="row">
        <div class="col-sm-12 col-md-7">
          <div class="swiper-container swiper-container-login">
            <swiper
              class="swiper-wrapper"
              [slidesPerView]="1"
              [speed]="700"
              [loop]="true"
              [autoplay]="{ delay: 3000 }"
              [parallax]="true"
              [navigation]="true"
            >
              <ng-template swiperSlide>
                <div
                  class="slide"
                  style="
                    background: url('../../assets/images/slide-one.jpg')
                      no-repeat center center / cover;
                  "
                ></div>
              </ng-template>
              <ng-template swiperSlide>
                <div
                  class="slide"
                  style="
                    background: url('../../assets/images/slide-two.jpg')
                      no-repeat center center / cover;
                  "
                ></div>
              </ng-template>
              <ng-template swiperSlide>
                <div
                  class="slide"
                  style="
                    background: url('../../assets/images/slide-three.jpg')
                      no-repeat center center / cover;
                  "
                ></div>
              </ng-template>
            </swiper>
          </div>
        </div>
        <div class="col-sm-12 col-md-5 display-table">
          <!-- Right Content Start -->

          <div class="right-wrapper">
            <div *ngIf="loginIN" class="right-inner-wrapper">
              <div class="logo">
                <img src="assets/loginLogo.svg" alt="logo" />
                <h3 class="bold">Welcome!</h3>
                <h3 class="bold">Login to EVcityride Partner</h3>
              </div>
              <div class="form-layout">
                <!-- Login Form Start -->
                <form
                  [formGroup]="loginForm"
                  (ngSubmit)="onSubmit()"
                  id="login-form"
                >
                  <div class="form-group">
                    <input
                      type="text"
                      formControlName="username"
                      class="input-field"
                      name="user_name"
                      id="inputEmail"
                      placeholder="Email/Username"
                    />
                    <label>Email Id / Username</label>
                    <!-- <div *ngIf="submi && username.invalid">
                      <p class="alert alert-danger"> Email Id / Username Required...</p>


                  </div> -->
                  </div>
                  <div class="form-group pass">
                    <input
                      type="password"
                      [type]="fieldTextTypeLogin ? 'text' : 'password'"
                      formControlName="password"
                      class="input-field"
                      id="inputPass"
                      name="password"
                      placeholder="Password"
                    />
                    <label>Password</label>
                    <i
                      class="fa eye"
                      [ngClass]="{
                        'fa-eye-slash': !fieldTextTypeLogin,
                        'fa-eye': fieldTextTypeLogin
                      }"
                      (click)="toggleFieldLogin()"
                    ></i>
                    <!-- <div *ngIf="submi && password.invalid" >
                                   <p class="alert alert-danger"> Password Required</p>

                                </div> -->
                  </div>
                  <div class="form-group d-flex justify-content-between">
                    <a
                      class="forgot-pwd forgot-password-tri text-primary"
                      (click)="DualComboForgotPassword()"
                      style="cursor: pointer"
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-primary ripple"
                      [disabled]="loginForm.invalid"
                    >
                      <div class="loader">
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                      </div>
                      Next
                    </button>
                  </div>
                </form>
                <!-- Login Form End -->
              </div>
            </div>

            <div
              *ngIf="forgotpassEmail"
              class="right-inner-wrapper forgot-password"
            >
              <div class="logo">
                <img src="assets/loginLogo.svg" alt="logo" />
                <h3 class="bold">Welcome!</h3>
                <h3 class="bold">Forgot Password</h3>
              </div>
              <div class="form-layout">
                <form
                  [formGroup]="forgot_form"
                  (ngSubmit)="onForgot()"
                  id="forgot-form"
                >
                  <div class="form-group">
                    <input
                      type="email"
                      formControlName="email"
                      name="email"
                      value=""
                      class="input-field valid"
                      placeholder="Email"
                    />
                    <label>Email</label>
                    <div
                      *ngIf="email.invalid && (email.dirty || email.touched)"
                      class="alert alert-danger"
                    >
                      <div *ngIf="email.errors">Email Required...</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-primary ripple otp-verification-tri"
                      [disabled]="!forgot_form.valid"
                    >
                      <div class="loader" style="display: none">
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                      </div>
                      Send OTP
                    </button>
                    <a
                      (click)="DualComboBackToLogin()"
                      class="btn btn-link btn-raised back-to-login-tri text-primary"
                    >
                      Back to login
                    </a>
                  </div>
                </form>
              </div>
            </div>

            <div *ngIf="otpSec" class="right-inner-wrapper">
              <div class="logo">
                <img src="assets/loginLogo.svg" alt="logo" />
                <h3 class="bold">OTP Verification</h3>
                <div class="d-flex">
                  <p id="otp-title">
                    We have sent and OTP to your email-id {{ emailStored
                    }}<a
                      (click)="DualComboBacktoChangeEmail()"
                      class="forgot-password-tri text-primary"
                      style="cursor: pointer"
                      >Change Email Id</a
                    >
                  </p>
                </div>
              </div>
              <div class="form-layout mt-0">
                <form id="otp-form">
                  <div class="form-group">
                    <div style="width: 100%">
                      <label
                        class="position-relative p-0"
                        style="margin-bottom: 26px; left: 0"
                        >Enter OTP Received</label
                      >

                      <ng-otp-input
                        [style.display]="'flex'"
                        [style.width]="'100%'"
                        [style.justify-content]="'flex-start'"
                        #ngOtpInput
                        (onInputChange)="onOtpChange($event)"
                        *ngIf="showOtpComponent"
                        [config]="config"
                      ></ng-otp-input>
                    </div>
                    <p id="errorblock"></p>
                  </div>
                  <div class="inform d-flex justify-content-between mt-4 mb-4">
                    <button
                      class="btns"
                      [disabled]="!showHideResendMail"
                      (click)="resetOTP()"
                      style="
                        cursor: pointer;
                        background-color: transparent;
                        border: none;
                      "
                    >
                      Resend OTP
                    </button>

                    {{ counter }}
                  </div>

                  <button
                    type="submit"
                    (click)="SendOtp()"
                    class="btn btn-primary reset-password-tri"
                  >
                    Verify OTP
                  </button>
                  <a
                    (click)="DualComboBackToLogin()"
                    class="btn btn-link btn-raised back-to-login-tri text-primary"
                  >
                    Back to login
                  </a>
                </form>
              </div>
            </div>

            <div *ngIf="confPass" class="right-inner-wrapper">
              <div class="logo">
                <img src="assets/loginLogo.svg" alt="logo" />
                <h3 class="bold">Welcome!</h3>
                <h3 class="bold">Reset Password</h3>
              </div>
              <div class="form-layout">
                <form
                  [formGroup]="confirmPasswordForm"
                  (ngSubmit)="Pass_submit()"
                  id="login-form"
                >
                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      value="{{ emailStored }}"
                      class="input-field reset-email"
                      placeholder="Email"
                      readonly
                    />
                    <label>Email</label>
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      name="password"
                      class="input-field"
                      [type]="fieldTextTypeLogino ? 'text' : 'password'"
                      placeholder="Password"
                      formControlName="password"
                    />
                    <label>Password</label>
                    <i
                      class="fa eye"
                      [ngClass]="{
                        'fa-eye-slash': !fieldTextTypeLogino,
                        'fa-eye': fieldTextTypeLogino
                      }"
                      (click)="skLogin()"
                    ></i>
                    <!-- <div class="toggle-password">
                      <img src="assets/eye-show.svg" alt="" class="show-eye">
                      <img src="assets/eye-hide.svg" alt="" class="hide-eye">
                    </div> -->
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      name="password_confirmation"
                      class="input-field"
                      [type]="fieldTextTypeLoginos ? 'text' : 'password'"
                      placeholder="Confirm Password"
                      formControlName="confirm_password"
                    />
                    <label>Confirm Password</label>
                    <i
                      class="fa eye"
                      [ngClass]="{
                        'fa-eye-slash': !fieldTextTypeLoginos,
                        'fa-eye': fieldTextTypeLoginos
                      }"
                      (click)="skLogins()"
                    ></i>
                    <!-- <div class="toggle-password">
                      <img src="assets/eye-show.svg" alt="" class="show-eye">
                      <img src="assets/eye-hide.svg" alt="" class="hide-eye">
                    </div> -->
                  </div>
                  <!-- <div class="form-group form-check" >
                    <input type="checkbox" class="form-check-input" id="remember-me" (click)="toggleDual()">

                    <label class="form-check-label" for="remember-me" >Show password</label>
                </div> -->

                  <div class="form-group">
                    <button
                      type="submit"
                      [disabled]="confirmPasswordForm.invalid"
                      class="btn btn-primary ripple login-tri"
                    >
                      <div class="loader">
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                      </div>
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Right Content End -->
        </div>
      </div>
    </section>
    <!--Login Page Ends-->
  </div>
</div>
<router-outlet></router-outlet>

<div
  *ngIf="noInternetConnection"
  style="text-align: center; display: block"
  class="noInternet"
>
  <img
    width="300px"
    src="assets/emptyStats/Nointernet.svg"
    alt="Wifi Connecred"
  />
  <br />
  <div>
    <h1 style="font-size: 4.5rem !important">No Internet</h1>

    <h4 style="font-size: 1.5rem !important">
      Please check your internet connection
    </h4>
  </div>
</div>

<div *ngIf="!noInternetConnection"></div>
