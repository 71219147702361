<!-- <div class="col-sm-12 col-md-8 col-lg-9"> -->
<!-- <ngx-spinner bdColor="white" color="black"
    template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
<div class="preloader"></div>
<div class="row mt-3">
  <div class="col-sm-12 col-md-12">
    <div class="row mt-3 mb-3">
      <div class="col">
        <p class="text-dark font-weight-bold mb-0">Track Trips</p>
      </div>

      <div class="searchStyles mt-3" *ngIf="hidePages">
        <div class="d-flex justify-content-end align-items-center h-100">
          <div class="d-flex align-items-center">
            <div class="form-group mb-0" id="search-field">
              <input
                type="text"
                [value]="localGetPast == null ? '' : localGetPast "
                style="width: 314px;"
                (input)="onSearchChange($event)"
                name="searchName"
                autocomplete="off"
                class="form-control  searchField"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Search Something"
              />
            </div>
            <!-- <button style="height: 50px; margin-right: 15px;" id="search-wrap" [disabled]="airpickPostForm.invalid" type="submit" class="btn btn-warning">Search</button> -->
          </div>

          <img
            src="assets/images/icons/search.svg"
            alt=""
            class="mr-3 searchIcons"
            id="search-icon"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-md-12" *ngIf="noBookings">
  <div
    style="
      width: 100%;
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    "
  >
    <img
      src="assets/emptyStats/nooBookings.svg"
      style="width: 285px;"
      alt=""
    /><br />
    <h2><b> No Bookings</b></h2>
    <br />
    <h5>Book your ride and you're ready to go</h5>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 col-md-12">
    <!-- <div *ngIf="(varTrack|filterPipe:searchData) as result"> -->
      <a
        *ngFor="let trip of varTrack | paginate: { itemsPerPage: 10 , currentPage: p, totalItems: totalItems }"
        (click)="anonymous()"
      >
        <div class="card mt-3" data-toggle="modal" data-target="#exampleModal">
          <div class="card-body">
            <div class="parent">
              <div
                [routerLink]="['/rider/track-trip/track_trip_details/', trip.id]"
                class="font-weight-bold"
                *ngIf="trip.request_type == 'day rental'"
                style="cursor: pointer;"
              >
                <p class="mb-0">Day Rental</p>
              </div>
              <div
                [routerLink]="['/rider/track-trip/track_trip_details/', trip.id]"
                *ngIf="trip.request_type != 'day rental'"
                style="cursor: pointer;"
                class="d-flex align-items-center source-and-destination"
              >
                <p class="mb-0 mr-4">{{trip.source}}</p>
                <img src="assets/images/icons/Line.svg" alt="" class="mr-4" />
                <p class="mb-0">{{trip.destination}}</p>
              </div>
              <p
                [routerLink]="['/rider/track-trip/track_trip_details/', trip.id]"
                class="mb-0 mr-4 client-name"
                style="cursor: pointer;"
              >
                {{trip.passenger_name}}
              </p>
              <p
                [routerLink]="['/rider/track-trip/track_trip_details/', trip.id]"
                class="mb-0 trip-date"
                style="cursor: pointer;"
              >
                {{trip.booking_date}}
              </p>
              <a
                [routerLink]="['/rider/track-trip/map/', trip.id]"
                style="cursor: pointer;"
              >
                <img
                  src="assets/images/icons/google maps.svg"
                  alt=""
                  class="location-image"
                />
              </a>
            </div>
          </div>
        </div>
      </a>
      <div class="col-md-12" *ngIf="totalItems === 0 ">
        <div
          style="
            width: 100%;
            height: 58vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <img
            src="assets/emptyStats/nooBookings.svg"
            style="width: 300px;"
            alt=""
          /><br />
          <h2><b> No Bookings</b></h2>
          <br />
          <h5>Book your ride and you're ready to go</h5>
        </div>
      </div>
   
    <pagination-controls
      *ngIf="totalItems != 0 && hidePages"
      class="alignStyles"
      (pageChange)="getPage(p = $event)"
    ></pagination-controls>
  </div>
</div>

<!-- <h1 style="justify-content: center; display: flex; align-items: center;" >This Page will not fix yet, fixed soon</h1> -->
<!-- </div> -->

<!-- <button class="btn btn-warning" (click)="getFire()" >Get Data From Firebase</button>
<br><br>
<h1 style="justify-content: center; display: flex; align-items: center;" >This Page will not fix yet, fixed soon</h1>
<table>
    <tr *ngFor="let skss of datos" >
        <td>{{skss.name}}</td>
        <td>{{skss.technology}}</td>

    </tr>
</table> -->

<!-- <div *ngIf="!Service.isUserAnonymousLoggedIn">
	<button (click)="signInAnonymously()">Login</button>
</div> -->
<!-- 
<div *ngIf="Service.isUserAnonymousLoggedIn">
	<h5 class="text-primary">User Information</h5>
	<h4>
		<strong>UID:</strong> {{ Service.currentUserId }}
	</h4>
	<button class=" btn btn-warning btn-block" (click)="logout()">Logout</button>
</div> -->

<div class="backdrop"></div>
