import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
// import { NgxSpinnerService } from 'ngx-spinner';
import { BookingListing } from './booking-listing';
import { BookingListingServiceService } from './booking-listing-service.service';


@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit {

  constructor(private router:Router,
     private Service:BookingListingServiceService
     ,  private titleHeader:Title) { }


//   nameSearch:any;

  passenger_name:any;
  searchData='';

  nameSearch='';
// search = new FormControl();
public setTitle(newTitle: string) {
  this.titleHeader.setTitle(newTitle);
}

  ngOnInit(): void {
    this.setTitle('Bookings | Evcityride');

    localStorage.removeItem('sDate');
    localStorage.removeItem('eDate');
    
    setTimeout(() => {
      $(".preloader").css('display','none');
      $('.preloader').fadeOut('slow');
    }, 1000);


    $(document).ready(function(){
        $("#search-field").hide();
      $("body").css("overflow", "auto");
      $(".backdrop").hide();


      $("#close, .backdrop").click(function(){
          $(".backdrop").hide();
          $(".drower").removeClass("show-drower");
          $("body").css("overflow", "auto");
      });

      // $(".backdrop").click(function(){
      //     $(".backdrop").hide();
      //     $(".drower").removeClass("show-drower");
      // });

      $(".radio").click(function(){
          $(".time-fields").addClass('d-none');
      });

      $("#year").click(function(){
          $(".time-fields").removeClass('d-none');
      });

      $(".clear-all").click(function(){
          $(".time-fields").addClass('d-none');
      });

      $("#search-icon").click(function(){
          $("#search-field").show();
          $("#search-icon").hide();
      });
      
      $("#search-field").focusin(function(){
          $("#search-icon").hide();
      });

      $("#search-field").focusout(function(){
          $("#search-icon").show();
          $("#search-field").hide();
      });

      $("aside").hide();
      $(".backdrop").hide();

      $(".backdrop").click(function(){
          $("aside").hide();
          $(".backdrop").hide();
      });
      
      $(".menu-icon").click(function(){
          $("aside").show();
          $(".backdrop").show();
      });

      $(".fa-times").click(function(){
          $(".drower").removeClass("show-drower");
          $(".backdrop").hide();
      });

      $(".tri").click(function(){
          $(".drower").addClass("show-drower");
          $(".backdrop").show();
      });
      
      $("#close").click(function(){
          $(".drower").removeClass("show-drower");
          $(".backdrop").hide();
      });

      $(".backdrop").click(function(){
          $(".drower").removeClass("show-drower");
          $(".backdrop").hide();
      });

      $("#backdrop").click(function(){
        // $(".drower").removeClass("show-drower");
        $(".backdrop").hide();
    });

    var local = localStorage.getItem("tabValue");
            if(local == "yes"){
                // alert("Working");
                $("#Ongoing-tab").removeClass("active");
                
                $("#Upcoming-tab").addClass("active");
                $(".modal-backdrop").removeClass("show");
                $(".modal-backdrop").css("display" , "none");
            }else{
                // alert("not Working");
                $("#Upcoming-tab").removeClass("active");
                $("#Ongoing-tab").addClass("active");
                // localStorage.clear();
            }
            $("#Ongoing-tab").click(function(){
                // localStorage.clear();
            });
            $("#Pasttrips-tab").click(function(){
                // localStorage.clear();
            });
      
  });



  }

//   oho(){
//       console.log(this.searchData = this.nameSearch);
//   }


  helowBooking:boolean = false;


  showOngoing:boolean = true;

  ongoingClick(){
      this.showOngoing = true;
      this.searchData = this.nameSearch;

    this.showUpcoming = false;
    this.showPast = false;

  }


  showUpcoming:boolean = false;

  upcming(){
    this.showUpcoming = true;
    this.searchData = this.nameSearch;

    this.showOngoing = false;
    this.showPast = false;


  }

//   oho(){
//   this.nameSearch = this.searchData;
// console.log(this.nameSearch = this.searchData)
//   }


  showPast:boolean = false;
  pastClick(){
      this.showPast = true;
      this.searchData = this.nameSearch;

    //   this.nameSearch = this.searchData;
      this.showUpcoming = false;
      this.showOngoing = false;
    //   console.log( this.searchData = this.nameSearch);

  }

//   ongoing:BookingListing | any;
//   laty:any;
//   longi:any;

//    getOngoing(){
//        this.spinner.show();
//        this.Service.getOngoingBookings().subscribe((res)=>{

//         if(res.bookings != 0){
//             this.ongoing = res.bookings;
//             this.spinner.hide();
//             this.helowBooking = false;
//         }else{
//             this.spinner.hide();
//             console.log(res);
//             this.helowBooking = true;
//         }

       
           
//         this.ongoing.forEach((value:any) =>{
//             this.laty = value.source_latitude;
//             this.longi = value.source_longitude
//             console.log(this.laty);
//             console.log(this.longi);
//         });



//        })
//    }




//    watchPosition(){
//     navigator.geolocation.watchPosition(
//         (position:any)=>{
//             console.log(
//                 `lat :${position.coords.latitude}, lon:${position.coords.longitude}`
//             );
//         },
//         (err)=>{
//             console.log(err);
//         },
//         {
//             enableHighAccuracy:true,
//             timeout:3000,
//             maximumAge:0
//         }
//     )
// }

// upcoming:BookingListing  |any

//    getUpcoming(){
//        this.Service.getUpcomingBookings().subscribe((res)=>{
//            this.upcoming = res.bookings;
//            console.log(this.upcoming);
//        })
//    }


// Search(){

//     console.log(this.ongoing);

//     if(this.passenger_name && this.passenger_name !== ""){
//         this.ongoing = this.ongoing.filter((res:{passenger_name:string;})=>{
//          return res.passenger_name?.toLocaleLowerCase().match(this.passenger_name?.toLocalLowerCase());
//         });
//         console.log(this.ongoing);
//     }else if(this.passenger_name == ""){
//         this.ngOnInit();
//     }


  
// }







}
