import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReqLayoutComponent } from './featured/req-layout/req-layout.component';
import { WebAboutComponent } from './featured/web-about/web-about.component';
import { WebContactComponent } from './featured/web-contact/web-contact.component';
import { WebDownloadAppComponent } from './featured/web-download-app/web-download-app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// import { RequestARideComponent } from './featured/request-a-ride/request-a-ride.component';
// import { HomeComponent } from './home/home/home.component';

const routes: Routes = [
  // {path:'', redirectTo:"/home", pathMatch:'full'},
  // {path:'home', component:HomeComponent},

//  {
//        path: 'home',
//        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
//  },

{path:'', redirectTo:'',pathMatch:'full'},
// {path:'about', component:WebAboutComponent},
// {path:'download_app', component:WebDownloadAppComponent},
// {path:'contact', component:WebContactComponent},
{path:'req', component:ReqLayoutComponent},
// {path:'featured', loadChildren:()=>import('./featured/featured.module').then(mod=>mod.FeaturedModule)},
// {path:'req-ride', loadChildren:()=>import('./req-ride-layout/req-ride-layout.module').then(mod=>mod.ReqRideLayoutModule)},
// {path:'',},
// {path:'**', redirectTo:'/'}

// {path:'**', component:PageNotFoundComponent}




  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
