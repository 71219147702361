import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-web-about',
  templateUrl: './web-about.component.html',
  styleUrls: ['./web-about.component.css']
})
export class WebAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      $(".preloader").css('display','none');
      $('.preloader').fadeOut('slow');
    }, 1000);


    $("#navbarSupportedContent").removeClass('show');

    // this.spinner.show();

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);
  }

}
