import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ReqRideService } from '../req-ride.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';
import { RequestRide } from '../request-ride';
import { HttpClient } from '@angular/common/http';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { DatePipe } from '@angular/common';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
// import { passengerMax } from '../passenger.validator';
import { Observable } from 'rxjs';
// import { rejects } from "node:assert";

@Component({
  selector: 'app-air-drop',
  templateUrl: './air-drop.component.html',
  styleUrls: ['./air-drop.component.css'],
  providers: [DatePipe],
})
export class AirDropComponent implements OnInit {
  dayRentalForm: FormGroup | any;
  airportDropForm: FormGroup | any;

  separateDialCode = true;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.India,
    CountryISO.UnitedKingdom,
  ];
  submitted = false;
  airDropPostForm: FormGroup | any;

  constructor(
    private Service: ReqRideService,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private tostMsg: ToastrService,
    private titleHeader: Title
  ) {
    this.airportDropForm = this.fb.group({
      airDropControl: new FormControl('', Validators.required),
      airDropFareControl: new FormControl('', Validators.required),
    });
  }

  optionsDate = {
    locale: moment.locale('ru'),
    // format: 'DD/MM/YYYY HH:mm a',
    buttons: { showClear: true },

    icons: { clear: 'fa fa-trash' },
    minDate: new Date(),
    // inline:true,
  };
  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }

  ngOnInit(): void {
    this.setTitle('Airport Drop | Evcityride');
    // this.spinner.show();

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);
    setTimeout(() => {
      $('.preloader').css('display', 'none');
      $('.preloader').fadeOut('slow');
    }, 1000);

    //   $(window).on('load', function() {
    //     if ($('.preloader').length) {
    //         $('.preloader').fadeOut('slow');
    //     }
    // });

    $(document).ready(function () {
      $('#phone').css('height', '65px');
      $('#phone').css('font-size', '18px');
      $('.iti--allow-dropdown').css('width', '100%');
      $('.drower-menu').hide();
      $('.backdrop').hide();

      // ----------------------------------------------------

      // Select Car type
      $('#choose-plan-tri-2').click(function () {
        $('#car-type-2').addClass('show-drower');
        $('.backdrop').show();
      });

      $('#close').click(function () {
        $('#car-type-2').removeClass('show-drower');
        $('.backdrop').hide();
      });

      $('.backdrop').click(function () {
        $('#car-type-2').removeClass('show-drower');

        $('.backdrop').hide();
      });
      $('#close, .backdrop').click(function () {
        $('.drower').removeClass('show-drower');
        $('.backdrop').hide();
      });

      // ----------------------------------------------------

      // ----------------------------------------------------
      // This code is reserved for aside menu in mobile screen
      $('aside').hide();
      $('.backdrop').hide();

      $('.backdrop').click(function () {
        $('aside').hide();
        $('.backdrop').hide();
        $('aside').removeClass('show-drower');
      });
      $('.menu-icon').click(function () {
        $('aside').show();
        $('.backdrop').show();
      });
      // ----------------------------------------------------
    });

    // this.getCarsModelsPickup();
    // this.getSrcDestiAirPickup();
    this.getSrcDestiAirDrop();
    // this.getCarsModelsDayRental();
    this.getCarsModelsDrop();
    this.AirportDropPostForm();
  }

  AirportDropPostForm() {
    this.airDropPostForm = this.fb.group({
      start_date: ['', Validators.required],

      passenger_name: ['', [Validators.required]],
      total_passenger: ['', [crossVal]],
      passenger_contact_number: [undefined, [Validators.required]],

      km: [''],
      basicFare: [''],
      source: ['', [Validators.required]],
      destination: ['', [Validators.required]],
      img: [''],
      car_name: [''],
      country_code: [''],

      payment_detail: ['bill_to_agency', Validators.required],

      model_id: [''],

      fare_id: [''],
      executive_name: [''],
      remarks: [''],
      // flight_number: [''],
    });
  }

  get f() {
    return this.airDropPostForm.controls;
  }

  get passenger_name() {
    return this.airDropPostForm.get('passenger_name');
  }
  get start_date() {
    return this.airDropPostForm.get('start_date');
  }
  get passenger_contact_number() {
    return this.airDropPostForm.get('passenger_contact_number');
  }

  // Only AlphaNumeric with Some Characters [-_ ]
  keyPressAlphaNumericWithCharacters(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[a-zA-Z-_ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  bookkId: any;
  msgCongr: any;

  obj: any;
  hidenSeek: boolean = false;
  classModal: boolean = false;

  public submi: boolean = false;

  airDropPostSubmit() {
    //  this.submitted = false;
    this.submi = true;

    if (!this.showMeAfterairDrop) {
      this.tostMsg.info('Choose First Plan');
    } else {
      const data = this.airDropPostForm.value;
      data.start_date = this.datePipe.transform(
        data.start_date,
        'dd-MM-YYYY HH:mm'
      );

      data.country_code = data.passenger_contact_number.dialCode;
      // data.passenger_contact_number = data.passenger_contact_number.number.slice(1).split(" ").join("");
      data.passenger_contact_number = data.passenger_contact_number.number
        .slice(1)
        .split(' ')
        .join('');

      data.km = this.airDropfareOBJ.kilometer;
      data.basicFare = this.airDropfareOBJ.basic_fare;
      // data.source = this.srcDrop;
      // data.destination = this.destiDrop;
      data.img = this.airDropPlansOBJ.image;
      data.car_name = this.airDropPlansOBJ.name;
      data.model_id = this.airDropPlansOBJ.id;
      data.fare_id = this.airDropfareOBJ.fare_id;
      // console.log(data);

      this.Service.airportDropPOST(data).subscribe((res) => {
        if (this.airDropPostForm.valid) {
          // console.log("Submit Click...");
          if (res.success == true) {
            // console.log("API Req Click...");

            this.bookkId = res.booking_id;
            // console.log(this.bookkId);
            this.msgCongr = res.message;
            // console.log(this.msgCongr);
            this.tostMsg.success(res.message, 'Success');
            this.hidenSeek = true;
            this.classModal = true;

            this.getBookingDetail();
            this.airDropPostForm.reset();
            this.getSrcDestiAirDrop();
            this.getCarsModelsDrop();
          } else {
            this.tostMsg.error(res.message);
          }
        } else {
          this.tostMsg.error(res.message);
          console.error('Error');
        }
        // console.log(res);
      });
    }
  }
  close() {
    this.hidenSeek = false;
    this.showMeAfterairDrop = false;
    this.showImgairDrop = false;
    this.showMeairDrop = true;
    this.submi = false;
  }

  airDropPlansOBJ: any = { max_passenger: 7 };
  airDropfareOBJ: any;

  // showingImgDayRent: any;
  showImgairDrop: boolean = false;

  showMeairDrop: boolean = true;
  showMeAfterairDrop: boolean = false;
  maxyPass: any;

  tata() {
    // console.log(this.maxyPass);
  }

  airDropSubmit() {
    // console.log(this.airportDropForm.value);

    if (this.airportDropForm.valid) {
      this.airDropPlansOBJ = this.airportDropForm.value.airDropControl;
      this.airDropfareOBJ = this.airportDropForm.value.airDropFareControl;
      // this.maxyPass = this.airDropPlansOBJ.max_passenger;
      // console.log(this.maxyPass,'AirDropSubmit');
      // console.log(crossVal );
      // return sachiny;
      // console.log(this.maxyPass);
      // console.log(this.airDropPlansOBJ);

      this.showMeairDrop = false;
      this.showMeAfterairDrop = true;
      this.showImgairDrop = true;
      $('.drower').removeClass('show-drower');
      $('.backdrop').hide();
      // console.log("right close");

      // this.AirportDropPostForm();
    } else {
      // console.log("error bro");
    }
    // console.log(this.maxyPass);
    // this.naPassenger(myRes);
  }

  closy() {
    $('.drower').removeClass('show-drower');
    $('.backdrop').hide();
    // console.log("right close");
  }

  carsTypeDrop: RequestRide | any;
  fare: RequestRide | any;

  getCarsModelsDrop() {
    this.Service.choosePlanAirDrop().subscribe((res) => {
      this.carsTypeDrop = res.models;
      // console.log(this.carsTypeDrop);
      if (this.carsTypeDrop.length > 0) {
        this.airportDropForm.controls['airDropFareControl'].setValue(
          this.carsTypeDrop[0].fares[0]
        );
        this.airportDropForm.controls['airDropControl'].setValue(
          this.carsTypeDrop[0]
        );
      }
    });
  }

  faresPlanAirportDrop: RequestRide | any;

  clickAirport_DropFares(faresAirport_Drop: any) {
    this.faresPlanAirportDrop = faresAirport_Drop;
    // console.log(this.faresPlanAirportDrop[0]);
    this.airportDropForm.controls['airDropFareControl'].setValue(
      this.faresPlanAirportDrop[0]
    );
  }

  srcDrop: RequestRide | any;
  destiDrop: RequestRide | any;

  getSrcDestiAirDrop() {
    this.Service.srcDestiAirportDrop().subscribe((res) => {
      this.srcDrop = res.source;
      this.destiDrop = res.destination;
    });
  }

  submitForm() {
    // console.log();
  }

  // airPick : boolean = false;

  // airpickClick(){
  //   this.airPick = true;
  // }

  options: AnimationOptions = {
    path: '../../../assets/checkmark.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    // console.log(animationItem);
  }

  bookingData: RequestRide | any;
  booking_id: any;
  carType: any;
  hours: any;

  trip_date: any;

  plan_price: any;
  source: any;
  destination: any;

  getBookingDetail() {
    this.Service.getBookingScreen(this.bookkId).subscribe((res) => {
      var bookingDetail = res.data;
      this.booking_id = bookingDetail.booking_id;
      this.carType = bookingDetail.carType;
      this.trip_date = bookingDetail.trip_date;
      this.source = bookingDetail.source;
      this.destination = bookingDetail.destination;

      // console.log(this.carType);
    });
  }

  // naPassenger(control:FormControl):Promise<any> | Observable<any>{
  //   const myRes = new Promise<any>((resolve, reject)=>{
  //     setTimeout(() => {

  //       if(control.value <= this.maxyPass ){
  //         resolve({'passNotAllowed':true});
  //         console.warn('Thats Right...');
  //       } else{
  //         resolve(null);
  //         console.error('Error..');
  //       }
  //     }, 2000);

  //   })
  //   return myRes;
  // }
}

function crossVal(
  this: any,
  control: AbstractControl
): { [key: string]: any } | null {
  // console.log(this.maxyPass,'Cross Val');

  if (
    control.value !== undefined &&
    (isNaN(control.value) || control.value > 7)
  ) {
    return { crossVal: true };
  }
  return null;
}
