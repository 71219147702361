import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
// import { NgxSpinnerService } from 'ngx-spinner';
import { Billing } from '../billing';
import { BillingServiceService } from '../billing-service.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-airport-pickup',
  templateUrl: './airport-pickup.component.html',
  styleUrls: ['./airport-pickup.component.css'],
  providers: [DatePipe],
})
export class AirportPickupComponent implements OnInit {
  //   billPostForm: FormGroup | any;

  //  startDate:string | undefined;
  //   endDate:string | undefined;
  //   startOptions: any = {format: 'DD-MM-YYYY', locale: 'fr'};
  //   endOptions: any = {format: 'DD-MM-YYYY'};

  p: number = 1;
  Airport_Pickup: any = [
    {
      id: '1',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
    {
      id: '2',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
    {
      id: '3',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
  ];

  Airport_PickupTwo: any = [
    {
      id: '1',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
    {
      id: '2',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
    {
      id: '3',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
  ];

  constructor(
    private router: Router,
    private Service: BillingServiceService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private titleHeader: Title
  ) {
    // this.billPostForm = new FormGroup({
    //   startDate:new FormControl('', Validators.required),
    //   endDate:new FormControl('', Validators.required),
    // });
  }
  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  searchBillData = '';

  //   @Input() searchBillData:string | undefined;

  // chintu:any = localStorage.getItem('searchItem') ;

  // chiki: any = {}

  ngOnInit(): void {
    this.setTitle('Billings-Airport Pickup | Evcityride');

    // var skop = localStorage.setItem('searchAirportBill', this.searchBillData);
    //

    // if(localStorage.searchBillPick == null){
    //
    // }else{
    // this.chiki = localStorage.searchBillPick;
    //
    // }
    // this.billPostForm.controls['startDate'].setValue(localStorage.sDate);
    // this.billPostForm.controls['endDate'].setValue(localStorage.eDate);

    // if(localStorage.sDate === null){
    //   this.endOptions.minDate = null;
    //   this.startOptions.maxDate = null;
    // }else{

    // }

    setTimeout(() => {
      $('.preloader').css('display', 'none');
      $('.preloader').fadeOut('slow');
    }, 2000);

    this.getPickRequest();
    $(document).ready(function () {
      // setTimeout(() => {
      //   $(".preloader").css('display','none');
      //   $('.preloader').fadeOut('slow');
      // }, 1000);

      $('body').css('overflow', 'auto');
      $('#search-field').hide();
      $('.ngx-pagination').css('padding', '0');

      // $(".tri").click(function(){
      //     $(".backdrop").show();
      //     $(".drower").show();
      //     $("body").css("overflow", "hidden");
      // });

      $('#close').click(function () {
        $('.backdrop').hide();
        $('.drower').removeClass('show-drower');
        $('body').css('overflow', 'auto');
      });

      $('.backdrop').click(function () {
        $('.backdrop').hide();
        $('.drower').removeClass('show-drower');
      });

      $('.radio').click(function () {
        $('.time-fields').addClass('d-none');
      });

      $('#year').click(function () {
        $('.time-fields').removeClass('d-none');
      });

      $('.clear-all').click(function () {
        $('.time-fields').addClass('d-none');
      });

      $('#search-icon').click(function () {
        $('#search-field').show();
        $('#search-icon').hide();
      });

      $('#search-field').focusin(function () {
        $('#search-icon').hide();
      });

      $('#search-field').focusout(function () {
        $('#search-icon').show();
        $('#search-field').hide();
      });
    });

    $(document).ready(function () {
      $('aside').hide();
      $('.backdrop').hide();

      $('.backdrop').click(function () {
        $('aside').hide();
        $('.backdrop').hide();
      });

      $('.menu-icon').click(function () {
        $('aside').show();
        $('.backdrop').show();
      });

      $('.fa-times').click(function () {
        $('.drower').removeClass('show-drower');
        $('.backdrop').hide();
      });

      $('.tri').click(function () {
        $('.drower').addClass('show-drower');
        $('.backdrop').show();
        // alert('hello');
      });

      $('#close').click(function () {
        $('.drower').removeClass('show-drower');
        $('.backdrop').hide();
      });

      $('.backdrop').click(function () {
        $('.drower').removeClass('show-drower');
        $('.backdrop').hide();
      });
    });
  }
  hlow: any;

  // onSearchChange(event:any){
  //   this.hlow = event.target.value;
  //   localStorage.setItem('searchItem', this.hlow);

  // }

  // changeEvent(tst:any){
  //
  //   this.hlow = localStorage.setItem('searchBillPick', tst);
  // }

  //   update() {
  //   this.startOptions.maxDate = new Date();
  //   this.endOptions.minDate = this.startDate;
  //   this.endOptions.maxDate = null;
  // }

  // billingSubmit(){
  //
  // }
  // crazy:any;
  // hallo:boolean=false;

  // onSubmit(){

  //  this.crazy = this.billPostForm.value;
  //  this.crazy.startDate = this.datePipe.transform(this.crazy.startDate,'dd-MM-YYYY');
  //  this.crazy.endDate = this.datePipe.transform(this.crazy.endDate,'dd-MM-YYYY');
  //
  //  this.hallo = true;

  // }

  pickBill: Billing | any;

  nodataMsg: any;
  showMsg: boolean = false;
  // url = 'https://evcityride.9brainz.store';
  url ='https://admin.evcityride.com'

  getPickRequest() {
    // this.spinner.show();

    if (localStorage.sDate != null) {
      this.Service.requestTypePick().subscribe((res) => {
        if (res.trips != '') {
          // if(){

          // }

          this.pickBill = res.trips;

          // this.spinner.hide();
          // setTimeout(() => {
          //     $(".preloader").css('display','none');
          //     $('.preloader').fadeOut('slow');
          //   }, 1000);
        } else {
          this.showMsg = true;
          this.nodataMsg = 'Sorry, No Data Found, Have a good day....';

          // this.spinner.hide();
          // setTimeout(() => {
          //   $(".preloader").css('display','none');
          //   $('.preloader').fadeOut('slow');
          // }, 1000);
        }
      });
    } else {
      var local = <any>localStorage.getItem('currentUser');
      let localStore = JSON.parse(local);
      this.http
        .get<Billing>(
          this.url +
            '/api/v1/trips?agency_id=' +
            localStore.agency_id +
            '&type=airport_pickup'
        )
        .subscribe((res) => {
          this.pickBill = res.trips;

          // this.spinner.hide();
        });
    }
  }
  gotoBill() {
    this.router.navigateByUrl('/rider/billing');
  }
}
