import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BookingListing } from './booking-listing';

@Injectable({
  providedIn: 'root',
})
export class BookingListingServiceService {
  constructor(private http: HttpClient) {}

  baseUrl = "https://admin.evcityride.com";
  // baseUrl = 'https://evcityride.9brainz.store';

  getOngoingBookings(pg: number): Observable<BookingListing> {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);
    return this.http
      .get<BookingListing>(
        this.baseUrl +
          '/api/v1/booking-listing?type=ongoing&agency_id=' +
          localStore.agency_id +
          '&page=' +
          1
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  getOngoingBookingsDetails(booking_id: any): Observable<BookingListing> {
    return this.http
      .get<BookingListing>(
        this.baseUrl + '/api/v1/booking-detail?booking_id=' + booking_id
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  getUpcomingBookings(pg: number): Observable<BookingListing> {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);
    return this.http
      .get<BookingListing>(
        this.baseUrl +
          '/api/v1/booking-listing?type=upcoming&agency_id=' +
          localStore.agency_id +
          '&page=' +
          pg
      )
      .pipe(
        retry(0),

        catchError(this.errorHandle)
      );
  }

  getUpcomingBookingsDetails(booking_id: any): Observable<BookingListing> {
    return this.http
      .get<BookingListing>(
        this.baseUrl + '/api/v1/booking-detail?booking_id=' + booking_id
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  getPastBookings(pg: number): Observable<BookingListing> {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);
    return this.http
      .get<BookingListing>(
        this.baseUrl +
          '/api/v1/booking-listing?type=past&agency_id=' +
          localStore.agency_id +
          '&page=' +
          1
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  getPastBookingsDetails(booking_id: any): Observable<BookingListing> {
    return this.http
      .get<BookingListing>(
        this.baseUrl + '/api/v1/booking-detail?booking_id=' + booking_id
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  getSearchResult(searchQuery: any, pg: number): Observable<any> {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);

    return this.http
      .get<any>(
        this.baseUrl +
          '/api/v1/booking-search?type=past&agency_id=' +
          localStore.agency_id +
          '&page=' +
          1 +
          '&search=' +
          searchQuery
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  getSearchResultUpcoming(searchQuery: any, pg: number): Observable<any> {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);

    return this.http
      .get<any>(
        this.baseUrl +
          '/api/v1/booking-search?type=upcoming&agency_id=' +
          localStore.agency_id +
          '&search=' +
          searchQuery +
          '&page=' +
          1
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  getSearchResultOngoing(searchQuery: any, pg: number): Observable<any> {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);

    return this.http
      .get<any>(
        this.baseUrl +
          '/api/v1/booking-search?type=ongoing&agency_id=' +
          localStore.agency_id +
          '&search=' +
          searchQuery +
          '&page=' +
          1
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  deleteBooking(booking_id: any, id: any) {
    return this.http
      .post(
        this.baseUrl + '/api/v1/cancel-request?booking_id=' + id,
        booking_id
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  getDataPlaceholder() {
    return this.http.get<any>('https://jsonplaceholder.typicode.com/posts');
  }

  getPagination() {
    return this.http.get<any>(
      'https://api.instantwebtools.net/v1/passenger?page=0&size=10'
    );
  }

  errorHandle(error: HttpErrorResponse) {
    return throwError(error.message || 'Serve Error');
  }
}
