import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Contact } from './contact';

@Injectable({
  providedIn: 'root',
})
export class ContactServiceService {
  errorData: {} | any;

  // baseUrl = 'https://evcityride.9brainz.store';
  baseUrl = 'https://admin.evcityride.com'

  constructor(private http: HttpClient) {}
  ids: any;
  varData: any;

  getContact(): Observable<Contact> {
    var agId: any = localStorage.getItem('currentUser');
    this.varData = JSON.parse(agId);
    return this.http
      .get<Contact>(
        this.baseUrl +
          '/api/v1/check-agency?agency_id=' +
          this.varData.agency_id
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  errorHandle(error: HttpErrorResponse) {
    return throwError(error.message || 'Serve Error');
  }
}
