import { AbstractControl, NG_VALIDATORS, Validator, Validators } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { DatePipe } from '@angular/common';


@Directive({
    selector:'[isLessThanStartDate]',
    providers:[{provide:NG_VALIDATORS, useExisting:EndDate, multi:true}, DatePipe],


})


export class EndDate implements Validator{

    constructor(private datePipe:DatePipe){

    }
    
    @Input('isLessThanStartDate') shouldbeless:any;


    validate(control: AbstractControl):{[key:string]:any} | null
    {
        console.log(this.shouldbeless);
        console.log(control.value);
        const sDate = new Date();
        var eDate:any = new Date(control.value);
        // eDate = this.datePipe.transform('dd-MM-YYYY HH:mm' );
        console.log((sDate > eDate)? {'StartDateIsMore':true}:null);

        return (sDate > eDate) ? {'StartDateIsMore':true}:null;
    }
}