<div class="preloader" ></div>
<app-web-slider></app-web-slider>

<section class="cta-style-three">
    <div class="container mb-5">
        <div class="row">
            <div class="col-md-6 col-lg-6 d-flex">
                <div class="my-auto image-block-wrapper text-center">
                    <div class="image-block">
                        <img src="assets/images/Evcity/ios-mockup.png" alt="Awesome Image" class="img-fluid" style="height: 500px;" />
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="content-block">
                    <div class="block-title">
                        <div class="dot-line"></div>
                        <p>Book now from application</p>
                        <h2>Get the Evcity Ride app</h2>
                    </div>
                    <p>We understand you don’t have time to go through long news articles everyday. So we cut the clutter and deliver them, in 60-word shorts. Short news for the mobile generation.</p>
                    <p class="font-weight-bold mt-3">We'll send you a link, open it on your phone to download the app</p>
                    <div class="button-block">
                        <a href="#" class="">
                            <img src="assets/images/Evcity/Google-Play-Button.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->