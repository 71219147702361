import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeaturedModule } from './featured/featured.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

import { HomeModule } from './home/home.module';
import { SwiperModule } from 'swiper/angular';
// import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { httpInterceptorProviders } from './index';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ToastrModule } from 'ngx-toastr';

// import { HomeComponent } from './home/home.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { LoginServiceService } from './shared/web-header/login-service.service';
import { AuthsService } from './shared/auths.service';
import { HttpInterceptorInterceptor } from './http-interceptor.interceptor';
import { SkguardGuard } from './skguard.guard';
import { ReqRideLayoutModule } from './req-ride-layout/req-ride-layout.module';
import {ConnectionServiceModule} from 'ng-connection-service';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
 
// import { AngularFireModule  } from '@angular/fire';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { environment } from '../environments/environment';
// import { AngularFirestoreModule } from '@angular/fire/firestore';

export function playerFactory() {
  
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    // HomeComp/onent
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FeaturedModule,
    LottieModule.forRoot({player:playerFactory}),
    HomeModule,
    SwiperModule,
    BrowserAnimationsModule,
    
    NgOtpInputModule,
    ConnectionServiceModule,
    
    ReqRideLayoutModule,
    ToastrModule.forRoot({
      timeOut:2000,
      progressBar:true,
        progressAnimation:'increasing',
        preventDuplicates:true
    }),
    ButtonsModule,

    // AngularFireDatabaseModule,
    // AngularFirestoreModule

  ],
  providers: [
        
    SkguardGuard,

    LoginServiceService,
    AuthsService,
    {provide:HTTP_INTERCEPTORS, useClass:HttpInterceptorInterceptor, multi:true}
    // httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
