import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BookingListing } from '../booking-listing';
import { BookingListingServiceService } from '../booking-listing-service.service';
import * as $ from 'jquery';
// import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-past-trips',
  templateUrl: './past-trips.component.html',
  styleUrls: ['./past-trips.component.css'],
})
export class PastTripsComponent implements OnInit {
  constructor(
    private router: Router,
    private Service: BookingListingServiceService,

    private http: HttpClient,
    private titleHeader: Title,
    private fb: FormBuilder
  ) {}

  //  @Input() searchData:string | undefined;
  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  searchData = '';
  // passenger_namess:any;

  //  p: number = 1;
  hidePage: boolean = false;
  //  apiShow:boolean = false;

  airpickPostForm: FormGroup | any;
  // apiHide:boolean = true;
  statusSearch: any;
  hidePages: boolean = true;
  // dataPastTrip:boolean = false;
  // dataPastTripLocal:boolean = false;

  localGetPast: any;
  ngOnInit(): void {
    this.setTitle('Bookings - Past Trips | Evcityride');
    localStorage.removeItem('searchOngoing');
    localStorage.removeItem('searchUpcoming');
    // localStorage.setItem('searchPast','');

    setTimeout(() => {
      $('.preloader').css('display', 'none');
      $('.preloader').fadeOut('slow');
    }, 1000);

    if (localStorage.searchPast == null) {
      this.getAllData();
      // this.getPage;
      // this.dataPastTrip = true;
      // this.dataPastTripLocal = false;
    } else {
      this.localGetPast = localStorage.getItem('searchPast');

      this.Service.getSearchResult(this.localGetPast, this.p).subscribe(
        (res) => {
          this.past = res.bookings;
          this.totalItems = res.total_items;
          // this.dataPastTripLocal = true;
          // this.dataPastTrip = false;
          // console.log(res);
        }
      );
      // this.getPages;
    }

    //   For Search Icon Jquery...
    $(document).ready(function () {
      $('#search-field').hide();

      $('#search-wrap').hide();

      $('#search-icon').click(function () {
        $('#search-field').show();
        $('#search-wrap').show();

        $('#search-icon').hide();
      });

      $('#search-field').focusin(function () {
        $('#search-icon').hide();
      });

      $('#search-field').focusout(function () {
        $('#search-icon').show();
        $('#search-field').hide();
        $('#search-wrap').hide();
      });
    });
    // this.searchResultsPast();
  }

  clickToHidePage() {
    this.hidePage = false;
    // this.hidePage = false;
    // console.log("click");
  }

  noBookings: boolean = false;
  searchIcon: boolean = false;

  past: BookingListing | any;

  getAllData() {
    //  this.spinner.show();
    this.Service.getPastBookings(this.pg).subscribe((res) => {
      if (res.bookings != 0) {
        this.past = res.bookings;
        this.totalItems = res.total_items;
        this.searchIcon = false;
        //  console.log(this.past,'Hello Get All Data');
        //  this.spinner.hide();

        setTimeout(() => {
          $('.preloader').css('display', 'none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      } else {
        // this.spinner.hide();
        this.noBookings = true;
        this.hidePages = false;
        this.searchIcon = true;

        // console.log(res);

        setTimeout(() => {
          $('.preloader').css('display', 'none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      }
    });
  }

  p: number | any;
  totalItems: any;

  pg: any;
  // baseUrl:any;

  getPage(page: any) {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);
    // console.log(this.searchRes);

    const baseUrl = `https://admin.evcityride.com/api/v1/booking-search?type=past&agency_id=${localStore.agency_id}&page=${page}&search=${this.searchRes}`

    // const baseUrl = `https://evcityride.9brainz.store/api/v1/booking-search?type=past&agency_id=${localStore.agency_id}&page=${page}&search=${this.searchRes}`;

    this.http.get(baseUrl).subscribe((data: any) => {
      // console.log(data);
      this.past = data.bookings;
      this.totalItems = data.total_items;
      // console.log(this.totalItems);
    });
  }

  // searchQuery:any = BookingListing;

  // searchResultsPast(){
  //   this.airpickPostForm = this.fb.group({
  //     search : ['',[Validators.required]]
  //   })
  // }

  //   pgs: number|any;
  //   totalItemsSearch: any;

  // pgss:any;

  //  getSearch(){
  //    this.apiHide = false;
  //    var serData = JSON.stringify(this.airpickPostForm.value);
  //    localStorage.setItem('search',serData);
  //   var kaku =  <any>localStorage.getItem('search');
  //   let loki = JSON.parse(kaku);
  //   console.log(loki.search);

  //    this.Service.getSearchResult(loki.search, this.pg).subscribe((res)=>{
  //      console.log(res);
  //      this.past = res.bookings;
  //      this.totalItems = res.total_items;

  //      this.apiHide = false;
  //      this.apiShow = true;
  //     })

  //  }

  searchRes: any = '';

  onSearchChange(searchValue: any): void {
    this.searchRes = searchValue.target.value;
    localStorage.setItem('searchPast', this.searchRes);
    this.p = 1;
    this.Service.getSearchResult(this.searchRes, this.p).subscribe((res) => {
      // console.log(res);
      this.past = res.bookings;
      this.totalItems = res.total_items;
    });
  }

  // getPages(pagi:any){
  //   this.localGetPast = localStorage.getItem('searchPast');

  //   var local = <any>localStorage.getItem('currentUser');
  //   let localStore = JSON.parse(local);
  //   const baseUrl = `https://evcityride.9brainz.store/api/v1/booking-search?type=past&agency_id=${localStore.agency_id}&page=${pagi}&search=${this.localGetPast}`
  // this.http.get(baseUrl).subscribe((data: any) => {
  //   console.log(data);
  //   this.past =  data.bookings;
  //   this.totalItems = data.total_items;

  // });
  //  }
}
