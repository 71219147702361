
<div class="preloader"></div>

        
            <div class="position-relative" >

                <div class="searchStyles mt-3" *ngIf="hidePages">
                   <div class="d-flex justify-content-end align-items-center h-100">
                       <div class="form-group mb-0 mr-3 w-100" id="search-field">
                           <input style="width: 314px;" type="text" [value]="localGetOngoing == null ? '' : localGetOngoing "
                             (input)="onSearchChange($event)"  class="form-control" autocomplete="off"
                            id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search Something">
                       </div>
                       <img src="assets/images/icons/search.svg" alt="" class="mr-3" id="search-icon">
                       
                   </div>
               </div>
    
        </div>
        
 


<div class="row">
   
    <div class="col-sm-12 col-md-12">
        <!-- Tab panes Start -->
        <div class="tab-content">
           
   <div class="col-md-12" *ngIf="noBookings">
    <div style="width: 100%; height: 70vh; display: flex;  justify-content: center; align-items: center; flex-direction: column; " >

     <img  src="assets/emptyStats/nooBookings.svg" style="width:285px;" alt=""><br>
     <h2><b> No Bookings</b></h2><br>
     <h5>Book your ride and you're ready to go</h5>
    </div>

    
</div>



<div class="tab-pane  show active" [ngClass]="ongoing === 0 ? 'trip-wrapper' : 'heyy' "
 id="Ongoing"  role="tabpanel" aria-labelledby="Ongoing-tab">
                   
    <!-- <div *ngIf="(ongoing|filterPipe:searchData) as result" > -->
     
   

      <a style="cursor: pointer; text-decoration: none;"
       *ngFor="let On of ongoing | paginate: { itemsPerPage: 10 , currentPage: p, totalItems: totalItems }"
        class="text-dark">
    

        <!-- Change Card For Solve Layout Problem -->
        <div class="card mt-3 trip-card">
            <div class="card-body">
                <div class="Source-and-destination">
                    <div [routerLink]="['/rider/bookings/ongoing/ongoing_details/', On.id]" class="font-weight-bold" *ngIf="On.request_type == 'day rental'" style="cursor: pointer;">
                        <p>Day Rental</p>
                    </div>
                    <div [routerLink]="['/rider/bookings/ongoing/ongoing_details/', On.id]" class="d-flex justify-content-between align-content-center w-100 font-weight-bold" *ngIf="On.request_type !== 'day rental'" style="cursor: pointer;">
                        <p class="text-truncate w-50 font-weight-bold">{{On.source}}</p>
                        <img src="assets/images/icons/Line.svg" alt="" class="ml-4 mr-4">
                        <p class="text-truncate w-50 font-weight-bold">{{On.destination}}</p>
                    </div>
                 
                </div>
                <div class="trip-details">
                    <p [routerLink]="['/rider/bookings/ongoing/ongoing_details/', On.id]" class="charCount" style="cursor: pointer;">{{On.passenger_name}}</p>
                    <p [routerLink]="['/rider/bookings/ongoing/ongoing_details/', On.id]" style="cursor: pointer;">{{On.booking_date}}</p>
                   
                    <a [routerLink]="['/rider/bookings/ongoing_map/', On.id]" style="cursor: pointer;">
                        <img src="assets/images/icons/google maps.svg"  alt="" class="location-image">
                    </a>
                </div>
            </div>
        </div>
        <!-- Change Card For Solve Layout Problem -->
        
         
        
        
         
      </a>
     
           <div class="col-md-12" *ngIf="totalItems === 0">
              <div style="width: 100%; height: 71vh; display: flex;  justify-content: center; align-items: center; flex-direction: column; " >
       
               <img  src="assets/emptyStats/nooBookings.svg" style="width:300px;" alt=""><br>
               <h2><b> No Record Found</b></h2><br>
               <!-- <h5>Book your ride and you're ready to go</h5> -->
              </div>
       
          </div>

   
     

  <!-- </div>  -->
     

  </div>
  <pagination-controls *ngIf="totalItems != 0 && hidePages" class="alignStyles" (pageChange)="getPage(p = $event)"></pagination-controls>

  </div></div></div>

