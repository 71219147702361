import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
// import { MainSidebarComponent } from './main-sidebar/main-sidebar.component';
// import { HeaderComponent } from './header/header.component';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebHeaderComponent } from './web-header/web-header.component';
import { WebFooterComponent } from './web-footer/web-footer.component';
import { FeaturedModule } from '../featured/featured.module';
// import { ForTokenInterceptor } from '../for-token.interceptor';
// import { httpInterceptorProviders } from '../index';
import { ToastrModule } from 'ngx-toastr';
import { NgOtpInputModule } from 'ng-otp-input';
import { LoginServiceService } from './web-header/login-service.service';
import { AuthsService } from './auths.service';
import { HttpInterceptorInterceptor } from '../http-interceptor.interceptor';
// import { ForTokenInterceptor } from '../for-token.interceptor';
// import { LoginComponent } from './Login/login.component';
import { ReqRideLayoutModule } from '../req-ride-layout/req-ride-layout.module';
// import { CountdownModule } from 'ngx-countdown';
// import { NgxSpinnerModule } from "ngx-spinner";
import { CountdownModule } from 'ngx-countdown';


@NgModule({
  declarations: [
    // MainSidebarComponent,
    // HeaderComponent,
    WebHeaderComponent,
    WebFooterComponent,
    // LoginComponent
    
  ],
  imports: [
CommonModule,
    SharedRoutingModule,
    HttpClientModule,

    
    FormsModule,
    ReactiveFormsModule,
    FeaturedModule,
    NgOtpInputModule,
    ReqRideLayoutModule,
    // CountdownModule,
    CountdownModule,
    ToastrModule.forRoot({
      timeOut:2000,
        progressBar:true,
        progressAnimation:'increasing',
        preventDuplicates:true
    }),
    
  ],
  exports:[
    // HeaderComponent,
    // MainSidebarComponent,
    WebHeaderComponent,
    WebFooterComponent
  ],
  providers:[
    LoginServiceService,
    AuthsService,
    {provide:HTTP_INTERCEPTORS, useClass:HttpInterceptorInterceptor, multi:true}
    // httpInterceptorProviders
  ]
})
export class SharedModule { }
