import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { BillingDetailsComponent } from '../featured/billing/billing-details/billing-details.component';
// import { BillingComponent } from '../featured/billing/billing.component';
// import { BookingsDetailsComponent } from '../featured/bookings/bookings-details/bookings-details.component';
// import { BookingsComponent } from '../featured/bookings/bookings.component';
// import { ContactServiceComponent } from '../featured/contact-service/contact-service.component';
// import { InvoiceDetailsComponent } from '../featured/invoice/invoice-details/invoice-details.component';
// import { InvoiceComponent } from '../featured/invoice/invoice.component';
// import { RequestARideComponent } from '../featured/request-a-ride/request-a-ride.component';
// import { TrackTripDetailsComponent } from '../featured/track-trip/track-trip-details/track-trip-details.component';
// import { TrackTripComponent } from '../featured/track-trip/track-trip.component';
// import { RideLayoutComponent } from './Login/login.component';
// import { AuthGuard } from '../auth/auth.guard';


// canActivate: [AuthGuard],
// canActivate: [AuthGuard],
// canActivate: [AuthGuard],
// canActivate: [AuthGuard],
// canActivate: [AuthGuard],
// canActivate: [AuthGuard],





const routes: Routes = [
//   {
//     path: 'rider',
//     loadChildren: () => import('../req-ride-layout/req-ride-layout.module').then(m => m.ReqRideLayoutModule)
// },
  // {path:'logged_in', component:RideLayoutComponent},
  // {path:'request-a-ride', component:RequestARideComponent},
  // {path:'bookings', component:BookingsComponent},
  // {path:'billing', component:BillingComponent},
  // {path:'invoice', component:InvoiceComponent},
  // {path:'contact-service', component:ContactServiceComponent},
  // {path:'track-trip', component:TrackTripComponent},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
