import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeaturedRoutingModule } from './featured-routing.module';
import { RequestARideComponent } from './request-a-ride/request-a-ride.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BookingsComponent } from './bookings/bookings.component';
import { BookingsDetailsComponent } from './bookings/bookings-details/bookings-details.component';
import { BillingComponent } from './billing/billing.component';
import { BillingDetailsComponent } from './billing/billing-details/billing-details.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoiceDetailsComponent } from './invoice/invoice-details/invoice-details.component';
// import { ContactServiceComponent } from './contact-service/contact-service.component';
import { TrackTripComponent } from './track-trip/track-trip.component';
import { TrackTripDetailsComponent } from './track-trip/track-trip-details/track-trip-details.component';
import { AirportPickupComponent } from './billing/airport-pickup/airport-pickup.component';
import { AirportPickupDetailsComponent } from './billing/airport-pickup/airport-pickup-details/airport-pickup-details.component';
import { AirportDropComponent } from './billing/airport-drop/airport-drop.component';
import { AirportDropDetailsComponent } from './billing/airport-drop/airport-drop-details/airport-drop-details.component';
import { OngoingDetailsComponent } from './bookings/ongoing-details/ongoing-details.component';
import { UpcomingDetailsComponent } from './bookings/upcoming-details/upcoming-details.component';
import { PastTripDetailsComponent } from './bookings/past-trip-details/past-trip-details.component';
import { SelfDriveComponent } from './billing/self-drive/self-drive.component';
import { SelfDriveDetailsComponent } from './billing/self-drive/self-drive-details/self-drive-details.component';
// import { OngoingTrackComponent } from './bookings/ongoing-details/ongoing-track/ongoing-track.component';
import { ReqRideService } from './request-a-ride/req-ride.service';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { WebSliderComponent } from './web-slider/web-slider.component';
import { WebYourFirstChoiceComponent } from './web-your-first-choice/web-your-first-choice.component';
import { WebNumbersSpeakComponent } from './web-numbers-speak/web-numbers-speak.component';
import { WebOurFoundersComponent } from './web-our-founders/web-our-founders.component';
import { WebChooseTexiComponent } from './web-choose-texi/web-choose-texi.component';
import { WebAboutComponent } from './web-about/web-about.component';
import { WebDownloadAppComponent } from './web-download-app/web-download-app.component';
import { WebContactComponent } from './web-contact/web-contact.component';
import { WebLoginComponent } from './web-login/web-login.component';
import { SwiperModule } from 'swiper/angular';

// import { NgxSpinnerModule } from "ngx-spinner";
import { RouterModule, Routes } from '@angular/router';
import { AirPickupComponent } from './request-a-ride/air-pickup/air-pickup.component';
import { AirDropComponent } from './request-a-ride/air-drop/air-drop.component';
import { DayRentComponent } from './request-a-ride/day-rent/day-rent.component';
import { NgTempusdominusBootstrapModule } from 'ngx-tempusdominus-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import { HeaderComponent } from '../shared/header/header.component';
// import { MainSidebarComponent } from '../shared/main-sidebar/main-sidebar.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxAutocomPlaceModule } from 'ngx-autocom-place';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { UpcomingComponent } from './bookings/upcoming/upcoming.component';
import { PastTripsComponent } from './bookings/past-trips/past-trips.component';
import { ConfirmBoxComponent } from './bookings/confirm-box/confirm-box.component';
import { EndDate } from './request-a-ride/endDate.validator';
import { FilterPipePipe } from './bookings/pipes/filter-pipe.pipe';
import { OngoingComponent } from './bookings/ongoing/ongoing.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { StatusPipe } from './bookings/pipes/status-pipe';
import { InvoiceFilterPipe } from './bookings/pipes/invoice-filter.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
// import {AngularFireModule} from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from '../../environments/environment';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { TripFilterPipe } from './track-trip/trip-filter.pipe';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { MapsComponent } from './maps/maps.component';
import { OngoingTrackComponent } from './bookings/ongoing-track/ongoing-track.component';
// import { ButtonsModule } from '@progress/kendo-angular-buttons';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// import { AngularFireAuthModule } from 'angularfire2/auth';
// import {  } from 'angularfire2/auth'

export function playerFactory() {
  return player;
}

// const appRoutes:Routes=[
//   {path:'ongoing', component:OngoingComponent}
// ]

@NgModule({
  declarations: [
    RequestARideComponent,
    BookingsComponent,
    BookingsDetailsComponent,
    BillingComponent,
    EndDate,

    BillingDetailsComponent,
    InvoiceComponent,
    InvoiceDetailsComponent,
    // ContactServiceComponent,
    TrackTripComponent,
    TrackTripDetailsComponent,
    AirportPickupComponent,
    AirportPickupDetailsComponent,
    AirportDropComponent,
    AirportDropDetailsComponent,
    OngoingDetailsComponent,
    UpcomingDetailsComponent,
    PastTripDetailsComponent,
    SelfDriveComponent,
    SelfDriveDetailsComponent,
    OngoingTrackComponent,
    WebSliderComponent,
    WebYourFirstChoiceComponent,
    WebNumbersSpeakComponent,
    WebOurFoundersComponent,
    WebChooseTexiComponent,
    WebAboutComponent,
    WebDownloadAppComponent,
    WebContactComponent,
    WebLoginComponent,
    AirPickupComponent,
    AirDropComponent,
    DayRentComponent,
    UpcomingComponent,
    PastTripsComponent,
    ConfirmBoxComponent,
    FilterPipePipe,
    OngoingComponent,
    StatusPipe,
    InvoiceFilterPipe,
    TripFilterPipe,
    MapsComponent,
  ],
  imports: [
    CommonModule,
    FeaturedRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LottieModule.forRoot({ player: playerFactory }),
    SwiperModule,
    // NgxSpinnerModule,
    RouterModule,
    NgTempusdominusBootstrapModule,
    NgxIntlTelInputModule,
    GooglePlaceModule,
    NgxAutocomPlaceModule,
    DateTimePickerModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    Ng2SearchPipeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDuhli7tQQ_xT0geyPwtQ3MpRCuPNvb1Yk',
    }),
    AgmDirectionModule,
    // ButtonsModule,

    // JwPaginationModule
    // ReqRideLayoutModule
    // SweetAlert2Module.forRoot()
    // MatButtonModule
    // MatDatepickerModule,
    // MatInputModule,
    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,
    // NgxMatNativeDateModule
    // SweetAlert2Module.forRoot()
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,

    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDuhli7tQQ_xT0geyPwtQ3MpRCuPNvb1Yk',
    // }),
    // AgmDirectionModule
  ],

  exports: [
    WebSliderComponent,
    WebYourFirstChoiceComponent,
    WebNumbersSpeakComponent,
    WebOurFoundersComponent,
    WebChooseTexiComponent,
    WebAboutComponent,
    WebContactComponent,
    WebDownloadAppComponent,
    WebLoginComponent,
    OngoingComponent,
    AngularFireModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    // RequestARideComponent,
    // BookingsComponent,
    // BookingsDetailsComponent
  ],
  providers: [ReqRideService],
})
export class FeaturedModule {}
