import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bookings-details',
  templateUrl: './bookings-details.component.html',
  styleUrls: ['./bookings-details.component.css']
})
export class BookingsDetailsComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {

  }

  gotoBooking(){
    this.router.navigateByUrl('/rider/bookings');
    // console.log("Enter Booking....");
  }

}
