import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
// import { NgxSpinnerService } from 'ngx-spinner';
import { BookingListingServiceService } from 'src/app/featured/bookings/booking-listing-service.service';
import { Billing } from '../../billing';
@Component({
  selector: 'app-airport-pickup-details',
  templateUrl: './airport-pickup-details.component.html',
  styleUrls: ['./airport-pickup-details.component.css']
})
export class AirportPickupDetailsComponent implements OnInit {

  constructor(private router:Router, private route:ActivatedRoute,
     private Service:BookingListingServiceService, 
     private titleHeader:Title) { }
id:any;
public setTitle(newTitle: string) {
  this.titleHeader.setTitle(newTitle);
}
  ngOnInit(): void {
    this.setTitle('Billings-Airport Pickup Details | Evcityride');

    this.id = this.route.snapshot.params.id;
    
    this.pickDeatils();

    $(document).ready(function(){
      $("aside").hide();
      $(".backdrop").hide();

      $(".backdrop").click(function(){
          $("aside").hide();
          $(".backdrop").hide();
      });
      
      $(".menu-icon").click(function(){
          $("aside").show();
          $(".backdrop").show();
      });
  });

  }
   airPickDetails:Billing | any;
  source:any;
  destination:any;
  trip_date:any;
  trip_end_date:any;
  passengerName:any;
  passengerContact:any;
  passengerCount:any;
  booking_id:any;
  booking_date:any;
  carType:any;
  driver:any;
  driver_number:any;
  date:any;
  end_date:any;
  



  pickDeatils(){
    // this.spinner.show();
    this.Service.getUpcomingBookingsDetails(this.id).subscribe((res)=>{

      if(res.success == true){
        this.airPickDetails = res.data;
        this.source = this.airPickDetails.source;
        this.destination = this.airPickDetails.destination;
        this.trip_date = this.airPickDetails.trip_date;
        this.trip_end_date = this.airPickDetails.trip_end_date;
        this.passengerName = this.airPickDetails.passenger_name;
        this.passengerContact = this.airPickDetails.passenger_contact_number;
        this.passengerCount = this.airPickDetails.total_passenger;
        this.booking_id = this.airPickDetails.booking_id;
        this.booking_date = this.airPickDetails.booking_date;
        this.carType = this.airPickDetails.carType;
        this.driver = this.airPickDetails.driver;
        this.driver_number = this.airPickDetails.driver_number;
        this.date = this.airPickDetails.date;
        this.end_date = this.airPickDetails.end_date;
        // this.spinner.hide();
        setTimeout(() => {
          $(".preloader").css('display','none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      }else{
        
        // this.spinner.hide();
        setTimeout(() => {
          $(".preloader").css('display','none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      }
     
     
     
     
     
     
      
    })
  }




  gotoAirportPick(){
    this.router.navigateByUrl('/rider/billing/airport_pickup');
    
  }
}
