import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { LoginRes } from './login-res';


@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  // serverUrl = 'https://evcityride.9brainz.store/';

  // errorData: {} | any;

  constructor(private http: HttpClient) { 

  }




//   redirectUrl: string| any;

//   login(username: string, password: string){
//     return this.http.post<LoginRes>(`${this.serverUrl}api/v1/login`, 
//     {username: username, password: password})
//     .pipe(map(user => {
//         if (user && user.access_token) {
//           localStorage.setItem('currentUser', JSON.stringify(user));
//           console.log("this is service side :" + JSON.stringify(user) );
//         }
//       }),
//       catchError(this.handleError)
      
//     );
//   }


//   forgotPassword(email: string) {
   
//     return this.http.post<any>(`${this.serverUrl}api/v1/forgot-password`, { email }).pipe(map(user => {
 
//         localStorage.setItem('currentUser', JSON.stringify(user));
   
//     }),
//     catchError(this.handleError)
//   );
// }

// otpVerify(otp:any, id:any){

//   return this.http.post(this.serverUrl + "api/v1/forgot-password-otp-varify?agency_id="+ id +"&otp=" + otp, id)

// }

//   isLoggedIn() {
//     if (localStorage.getItem('currentUser')) {
//       return true;
//     }
//     return false;
//   }

//   getAuthorizationToken() {
//     const currentUser = JSON.parse(<any>localStorage.getItem('currentUser'));
//     return currentUser.access_token;
//   }

//   logout() {
//     localStorage.removeItem('currentUser');
//   }

//   private handleError(error: HttpErrorResponse) {
//     if (error.error instanceof ErrorEvent) {

//       console.error('An error occurred:', error.error.message);
//     } else {

//       console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
//     }

//     this.errorData = {
//       errorTitle: 'Oops! Request for document failed',
//       errorDesc: 'Something bad happened. Please try again later.'
//     };
//     return throwError(this.errorData);
//   }


}
