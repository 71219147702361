import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { RequestRide } from './request-ride';

@Injectable({
  providedIn: 'root',
})
export class ReqRideService {
  baseUrl ='https://admin.evcityride.com'
  // baseUrl = 'https://evcityride.9brainz.store';
  // baseUrl = "https://evcityride.9brainz.store/api/v1/login";
  // baseUrl = 'https://evcityride.9brainz.store/';

  constructor(private http: HttpClient) {}

  airportPickupPOST(data: any): Observable<RequestRide> {
    return this.http
      .post<RequestRide>(this.baseUrl + '/api/v1/airport-pickup', data)
      .pipe(retry(0), catchError(this.errorHandle));
  }

  airportDropPOST(data: any): Observable<RequestRide> {
    return this.http
      .post<RequestRide>(this.baseUrl + '/api/v1/airport-drop', data)
      .pipe(retry(0), catchError(this.errorHandle));
  }

  dayRentPost(data: any): Observable<RequestRide> {
    return this.http
      .post<RequestRide>(this.baseUrl + '/api/v1/day-rental', data)
      .pipe(retry(0), catchError(this.errorHandle));
  }

  // airpickBooking():Observable<any>{
  //   return this.http.get<any>(this.baseUrl + "/api/v1/booking-detail?booking_id=" )
  // }

  choosePlanAirPickup(): Observable<RequestRide> {
    return this.http
      .get<RequestRide>(
        'https://admin.evcityride.com/api/v1/car-type?slug=airport_pickup'
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  choosePlanAirDrop(): Observable<RequestRide> {
    return this.http
      .get<RequestRide>(
        'https://admin.evcityride.com/api/v1/car-type?slug=airport_drop'
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  choosePlanDay_Rental(): Observable<RequestRide> {
    return this.http
      .get<RequestRide>(
        'https://admin.evcityride.com/api/v1/car-type?slug=day_rental'
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  // choosePlanDrop():Observable<RequestRide>{
  //   return this.http.get<RequestRide>("https://admin.evcityride.com/api/v1/car-type?slug=airport_drop").pipe(
  //     retry(0),
  //     catchError(this.errorHandle)
  //   )
  // }

  srcDestiAirportPickup(): Observable<RequestRide> {
    return this.http
      .get<RequestRide>(
        'https://admin.evcityride.com/api/v1/airport-pickup-drop?type=airport_pickup'
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }
  srcDestiAirportDrop(): Observable<RequestRide> {
    return this.http
      .get<RequestRide>(
        'https://admin.evcityride.com/api/v1/airport-pickup-drop?type=airport_drop'
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  getBookingScreen(ids: any): Observable<RequestRide> {
    return this.http
      .get<RequestRide>(
        this.baseUrl + '/api/v1/booking-detail?booking_id=' + ids
      )
      .pipe(retry(0), catchError(this.errorHandle));
  }

  errorHandle(error: HttpErrorResponse) {
    return throwError(error.message || 'Serve Error');
  }

  // For Get Token of Users...
  // getToken(){
  //      return localStorage.getItem('token')
  //   }
}
