<!-- <ngx-spinner id="spinner"  bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
<div class="preloader" ></div>
        <section class="about-style-one ">
            <div class="container">

                <div class="block-title text-center">
                    <div class="dot-line"></div>
                    <p>Welcome to EV cityride</p>
                    <h2>Your first choice <br> for traveling anywhere</h2>
                </div>

                <div class="row high-gutter">
                    <div class="col-md-6 col-lg-6">
                        <div class="about-image-block">
                            <img src="assets/images/resources/choise-1-1.png" alt="Awesome Image">
                            <p>There are many variations of passages of lorem ipsum available but the majority have suffered alteration in some form by injected humour or random words which don't look even slightly believable. If you are going to use a passage of lorem ipsum you need to be sure there isn't anything embarrassing.</p>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-6">
                        <div class="text-block">
                            <div class="video-block-one">
                                <div class="image-block">
                                    <div class="inner-block">
                                        <img src="assets/images/resources/video-1-1.png" alt="Awesome Image">
                                        <a href="#" class="video-popup"><i class="fa fa-play"></i></a>
                                    </div>
                                </div>
                                <div class="content-block">
                                    <h3>We’re specialized in providing a high quality service</h3>
                                </div>
                            </div>
                            <p>There are many variations of passages of lorem ipsum available but the majority have suffered alteration in some form by injected humour or random words which don't look even slightly believable. If you are going to use a passage of lorem ipsum you need to be sure there isn't anything embarrassing.</p>
                            <hr class="style-one">
                            <div class="call-block">
                                <div class="left-block">
                                    <div class="icon-block">
                                        
                                        <i class="conexi-icon-phone-call"></i>
                                    </div>
                                    <div class="content-block">
                                        <p>Call us now and make <br>your booking</p>
                                    </div>
                                </div>
                                <div class="right-block">
                                    <a class="phone-number" href="callto:888-888-0000">888 888 0000</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>