import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { Invoice } from '../invoice';
import { InvoiceServiceService } from '../invoice-service.service';
import  jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.css']
})
export class InvoiceDetailsComponent implements OnInit {

  
  Invoice_Details:any=[
    { id:"1", 'Source':'Saffron Leaf',
     'Destination':'Jolly Grant Airport',
      'User':'Arjun Katariya', 
      'Time':'04:00PM', 
      'Date':'Sat, 8 Jan 2021' },
      {id:"2",'Source':'Saffron Leaf',
     'Destination':'Jolly Grant Airport',
      'User':'Arjun Katariya', 
      'Time':'04:00PM', 
      'Date':'Sat, 8 Jan 2021' },
      {id:"3",'Source':'Saffron Leaf',
     'Destination':'Jolly Grant Airport',
      'User':'Arjun Katariya', 
      'Time':'04:00PM', 
      'Date':'Sat, 8 Jan 2021' },
      ]

        
  Invoice_Details_Two:any=[
    { id:"1", 'Source':'Saffron Leaf',
     'Destination':'Jolly Grant Airport',
      'User':'Arjun Katariya', 
      'Time':'04:00PM', 
      'Date':'Sat, 8 Jan 2021' },
      {id:"2",'Source':'Saffron Leaf',
     'Destination':'Jolly Grant Airport',
      'User':'Arjun Katariya', 
      'Time':'04:00PM', 
      'Date':'Sat, 8 Jan 2021' },
      {id:"3",'Source':'Saffron Leaf',
     'Destination':'Jolly Grant Airport',
      'User':'Arjun Katariya', 
      'Time':'04:00PM', 
      'Date':'Sat, 8 Jan 2021' },
      ]

  constructor(private router:Router, private Service:InvoiceServiceService, private route:ActivatedRoute,

     private tost:ToastrService,private titleHeader:Title) { }

  id:any;
  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  ngOnInit(): void {
  this.setTitle('Invoice-Details | Evcityride');

    this.id = this.route.snapshot.params.id;
    


    $(document).ready(function(){
      $("aside").hide();
      $(".backdrop").hide();

      $(".backdrop").click(function(){
          $("aside").hide();
          $(".backdrop").hide();
      });
      
      $(".menu-icon").click(function(){
          $("aside").show();
          $(".backdrop").show();
      });
  });

  $(document).ready(function(){
    $(".menu-icon").click(function(){
      $(".aside-menu").show();

    });
    
    $(".backdrop").click(function(){  
      $(".aside-menu").hide();
      $(".backdrop").hide();
    });
  });

  this.GetInvoiceDetails();
  }

invoiceD:Invoice | any;
source:any;
destination:any;
passengerName:any;
amount:any;
status:any;
invoiceDownload:any;


  GetInvoiceDetails(){
    // this.spinner.show();
    this.Service.getInvoiceDetails(this.id).subscribe((res)=>{


      if(res.data != 0){
        this.invoiceD = res.data;
        this.source = res.data.source;
  
        this.destination = res.data.destination;
        this.passengerName = res.data.passenger_name;
        this.amount = res.data.final_amount;
        this.status = res.data.payment_status;
        this.invoiceDownload = res.data.invoice;
       
       
        
        // this.spinner.hide();
        setTimeout(() => {
          $(".preloader").css('display','none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      }else{
        // this.spinner.hide();
        setTimeout(() => {
          $(".preloader").css('display','none');
          $('.preloader').fadeOut('slow');
        }, 1000);
        
      }


      // if(res.data.payment_status == 'Payment Pending' ){

      // }else{

      // }

     

    })
  }
 
  showI:boolean=false;

  download(){

var FileSaver = saveAs(this.invoiceDownload, 'invoice.pdf')
 
    this.tost.success("You may have a wait 3 or 4 sec, download starting","Downloading");

  }





  gotoInvoice(){
    this.router.navigateByUrl('/rider/invoice');
    
  }

}
