<!-- <div class="col-sm-4 col-md-3 col-lg-6 icons">
    <div class="d-flex justify-content-end align-items-center h-100">
        <div class="form-group mb-0 mr-3 w-100" id="search-field">
            <input type="email"  [(ngModel)]="nameSearch"  class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search Something">
        </div>
        <img src="assets/images/icons/search.svg" alt="" class="mr-3" id="search-icon">
        
        <img src="assets/images/icons/Filter.svg" alt="" class="tri">
    </div>
</div> -->  

 <!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
<!-- <div class="preloader"></div> -->
<!-- <button class="btn btn-warning btn -block" (click)="getPage(p = $event)" >Next page</button> -->
<div class="preloader"></div>
<!-- <div class="row">
    <div class="col-md-12">
        <button class="btn btn-warning btn-block" (click)="getSearch()" >Get Search Result</button>
    </div>
</div> -->

<div class="row">
    <div class="col-sm-12 col-md-12" style="height: 0; margin-top: 20px;">
        <!-- Tab panes Start -->
        <div class="tab-content">
            

<div class="col-md-12" *ngIf="noBookings">
    <div style="width: 100%; height: 70vh; display: flex;  justify-content: center; align-items: center; flex-direction: column; " >

     <img  src="assets/emptyStats/nooBookings.svg" style="width:285px;" alt=""><br>
     <h2><b> No Bookings</b></h2><br>
     <h5>Book your ride and you're ready to go</h5>
    </div>

</div>

<div >
    
    <!-- <form [formGroup]="airpickPostForm" (ngSubmit)="getSearch()"> -->
    <div  class="searchStyles mt-3" *ngIf="hidePages" >
        <div class="d-flex justify-content-end align-items-center h-100">
            <div class="d-flex align-items-center" >
                <div class="form-group mb-0" id="search-field">
                    <input type="text" [value]="localGetPast == null ? '' : localGetPast " style="width: 314px;"  (input)="onSearchChange($event)" 
                     name="searchName"
                      autocomplete="off" class="form-control"
                     id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search Something">
                </div>
                <!-- <button style="height: 50px; margin-right: 15px;" id="search-wrap" [disabled]="airpickPostForm.invalid" type="submit" class="btn btn-warning">Search</button> -->
                
            </div>
               
                
                <img src="assets/images/icons/search.svg" alt="" class="mr-3" id="search-icon">
                
            </div>
        </div>
<!-- </form> -->
</div>



<!-- |statusPipe:statusSearch  -->
<!-- {{parentData}} -->

<div  class="tab-pane" style="margin-top: 20px; display: block;" id="Pasttrips" role="tabpanel" aria-labelledby="Pasttrips-tab">
    <!-- <div *ngIf="(past| filterPipe:searchData) as result" > -->
        
    <div  [ngClass]="past != 0 ? 'trip-wrapper' : 'hey' ">
        <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
        <!-- <div *ngIf="past != 0" > -->

    <a  style="cursor: pointer; text-decoration: none;"
     *ngFor="let Past of past | paginate: { itemsPerPage: 10 , currentPage: p, totalItems: totalItems }"
     [routerLink]="['/rider/bookings/past/past_trips_details/', Past.id]"  class="text-dark">

        <!-- Change Card For Solve Layout Problem -->
        <div class="card mt-3 trip-card">
            <div class="card-body">

                <div class="Source-and-destination">
                    <div class="font-weight-bold"
                     *ngIf="Past.request_type == 'day rental'">
                        <p>Day Rental</p>
                    </div>
                    <div  class="d-flex justify-content-between align-content-center w-100 font-weight-bold"  *ngIf="Past.request_type !== 'day rental'" >
                        <p class="text-truncate w-50">{{Past.source}}</p>
                        <img src="assets/images/icons/Line.svg" alt="" class="ml-4 mr-4">
                        <p class="text-truncate w-50">{{Past.destination}}</p>
                    </div>
                   

                </div>

                <div class="trip-details">
                    <p class="client-name text-truncate w-25 charCount">{{Past.passenger_name}}</p>
                    <p class="trip-date text-truncate w-50">{{Past.booking_date}}</p>
                    <p [style.color]="Past.booking_status == 'Completed' ? 'green' : 'red' " class="font-weight-bold text-truncate w-25 position-relative booking-status">{{Past.booking_status}}</p>
                </div>
            </div>
        </div>
        <!-- Change Card For Solve Layout Problem -->

    </a>
<!-- </div> -->

    <div class="col-md-12" *ngIf="totalItems === 0">
        <div style="width: 100%;  display: flex;  justify-content: center; align-items: center; flex-direction: column; " >
 
         <img  src="assets/emptyStats/nooBookings.svg" style="width:300px;" alt=""><br>
         <h2><b> No Record Found</b></h2><br>
         <!-- <h5>Book your ride and you're ready to go</h5> -->
        </div>
 
    </div>
	</div>
    

    <!-- <div class="col-md-12" *ngIf="result.length === 0 ">
        <div style="width: 100%; height: 71vh; display: flex;  justify-content: center; align-items: center; flex-direction: column; " >
 
         <img  src="assets/emptyStats/nooBookings.svg" style="width:300px;" alt=""><br>
         <h2><b> No Bookings</b></h2><br>
         <h5>Book your ride and you're ready to go</h5>
        </div>
 
    </div> -->
    <pagination-controls *ngIf="totalItems != 0 && hidePages" class="alignStyles" (pageChange)="getPage(p = $event)"></pagination-controls>
    <!-- <pagination-controls *ngIf="localGetPast != null"  class="alignStyles" (pageChange)="getPages(p = $event)"></pagination-controls> -->
 
    



<!-- </div>  -->
<!-- *ngIf="result.length != 0" -->
<!-- *ngIf="hidePage" -->


</div>
</div></div></div>