import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Track } from './track';
import { TrackServiceService } from './track-service.service';
import { BookingListingServiceService } from './../bookings/booking-listing-service.service';
@Component({
  selector: 'app-track-trip',
  templateUrl: './track-trip.component.html',
  styleUrls: ['./track-trip.component.css'],
})
export class TrackTripComponent implements OnInit {
  searchData: any;

  Track_Trip: any = [
    {
      id: '1',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
    {
      id: '2',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
    {
      id: '3',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
    },
  ];

  constructor(
    public Service: TrackServiceService,
    public services: BookingListingServiceService,
    private router: Router,
    private titleHeader: Title,
    private http: HttpClient
  ) {}

  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  vary: any;
  localGetPast: any;
  searchIcon: boolean = false;

  ngOnInit(): void {
    this.setTitle('Track Trip | Evcityride');

    localStorage.removeItem('sDate');
    localStorage.removeItem('eDate');
    localStorage.removeItem('searchOngoing');
    localStorage.removeItem('searchUpcoming');
    localStorage.removeItem('searchPast');
    this.Service.anonymousLogin();
    setTimeout(() => {
      $('.preloader').css('display', 'none');
      $('.preloader').fadeOut('slow');
    }, 1000);

    // localStorage.setItem("LoginFirebase" ,this.vary);

    //     $(document).ready(function(){

    // });

    $(document).ready(function () {
      $('.searchField').hide();

      $('.searchIcons').click(function () {
        $('.searchField').show();
        $('.searchIcons').hide();
        // alert('runnn');
      });

      $('.searchField').focusin(function () {
        $('.searchIcons').hide();
      });

      $('.searchField').focusout(function () {
        $('.searchIcons').show();
        $('.searchField').hide();
      });
      // $("#search-field").hide();

      // $(".searchIcons").click(function(){
      //     $("#search-field").show();
      //     $(".searchIcons").hide();
      // });

      // $("#search-field").focusin(function(){
      //     $(".searchIcons").hide();
      // });

      // $("#search-field").focusout(function(){
      //     $(".searchIcons").show();
      //     $("#search-field").hide();
      // });

      // ----------------------------------------------------
      // This code is reserved for aside menu in mobile screen
      $('aside').hide();
      $('.backdrop').hide();

      $('.backdrop').click(function () {
        $('aside').hide();
        $('.backdrop').hide();
        $('aside').removeClass('show-drower');
      });
      $('.menu-icon').click(function () {
        $('aside').show();
        $('.backdrop').show();
      });
      // ----------------------------------------------------
    });

    if (localStorage.searchPast == null) {
      this.getTracks();
    } else {
      this.localGetPast = localStorage.getItem('searchPast');
      this.services
        .getSearchResultOngoing(this.localGetPast, this.p)
        .subscribe((res) => {
          this.varTrack = res.bookings;
          this.totalItems = res.total_items;
        });
    }
  }

  varTrack: Track | any;
  noBookings: boolean = false;
  totalItems: any;
  pg: any;
  p: number | any;
  hidePages: boolean = true;

  getTracks() {
    // this.spinner.show();
    this.Service.getTrackTrip(this.pg).subscribe((res) => {
      if (res.bookings != 0) {
        this.varTrack = res.bookings;
        this.totalItems = res.total_items;

        setTimeout(() => {
          $('.preloader').css('display', 'none');
          $('.preloader').fadeOut('slow');
        }, 1000);
        // this.spinner.hide();
        this.noBookings = false;
        this.searchIcon = true;
      } else {
        // this.spinner.hide();
        this.noBookings = true;
        this.hidePages = false;
        this.searchIcon = false;
      }
    });
  }

  getPage(page: any) {
    var local = <any>localStorage.getItem('currentUser');
    let localStore = JSON.parse(local);
    const baseUrl = `https://admin.evcityride.com/api/v1/booking-search?type=ongoing&agency_id=${localStore.agency_id}&page=${page}&search=${this.searchRes}`;

    // const url = `https://api.instantwebtools.net/v1/passenger?page=${page}&size=${this.itemsPerPage}`;
    this.http.get(baseUrl).subscribe((data: any) => {
      this.varTrack = data.bookings;
      this.totalItems = data.total_items;
    });
  }

  searchRes: any = '';

  onSearchChange(searchValue: any): void {
    this.searchRes = searchValue.target.value;
    localStorage.setItem('searchPast', this.searchRes);
    this.p = 1;
    this.services
      .getSearchResultOngoing(this.searchRes, this.p)
      .subscribe((res) => {
        this.varTrack = res.bookings;
        this.totalItems = res.total_items;
      });
  }
  // datos:any;
  // getFire(){
  //   this.Service.getAll().snapshotChanges().pipe(
  //     map(changes =>
  //       changes.map(c =>
  //         ({ key: c.payload.key, ...c.payload.val() })
  //       )
  //     )
  //   ).subscribe(data => {
  //     this.datos = data;

  //
  //   });

  // }

  anonymous() {
    this.Service.anonymousLogin();
  }

  // signInAnonymously(){
  //   this.Service.anonymousLogin()
  //   .then(()=>this.router.navigate(['/']));
  // }

  // logout() {
  //   this.Service.signOut();
  // }

  hello() {}
}
