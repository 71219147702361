import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
// import { NgxSpinnerService } from 'ngx-spinner';
import { TrackServiceService } from '../track-trip/track-service.service';
import { Invoice } from './invoice';
import { InvoiceServiceService } from './invoice-service.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
})
export class InvoiceComponent implements OnInit {
  nameSearch: string = '';
  filter: string = '';
  p: number = 1;
  gmeAe: string = '';
  typedTerm = '';
  Invoice: any = [
    {
      id: '1',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
      INumber: 'DHA0012',
      DFrom: '01/01/2021',
      DTo: '07/01/2021',
    },
    {
      id: '2',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
      INumber: 'DHA0013',
      DFrom: '01/01/2021',
      DTo: '07/01/2021',
    },
    {
      id: '3',
      Source: 'Saffron Leaf',
      Destination: 'Jolly Grant Airport',
      User: 'Arjun Katariya',
      Time: '04:00PM',
      Date: 'Sat, 8 Jan 2021',
      INumber: 'DHA0014',
      DFrom: '01/01/2021',
      DTo: '07/01/2021',
    },
  ];

  config: any;

  constructor(
    private Service: InvoiceServiceService,
    public ServiCE: TrackServiceService,
    private router: Router,
    private titleHeader: Title
  ) {}

  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  ngOnInit(): void {
    // this.afAuth.authState.subscribe((user:any)=>{
    //
    // });

    this.setTitle('Invoice | Evcityride');

    localStorage.removeItem('sDate');
    localStorage.removeItem('eDate');
    localStorage.removeItem('searchOngoing');
    localStorage.removeItem('searchUpcoming');
    localStorage.removeItem('searchPast');
    $(document).ready(function () {
      $('#search-field').hide();
      $('.ngx-pagination').css('padding', '0');

      $('#search-icon').click(function () {
        $('#search-field').show();
        $('#search-icon').hide();
      });

      $('#search-field').focusin(function () {
        $('#search-icon').hide();
      });

      $('#search-field').focusout(function () {
        $('#search-icon').show();
        $('#search-field').hide();
      });

      $('aside').hide();
      $('.backdrop').hide();

      $('.backdrop').click(function () {
        $('aside').hide();
        $('.backdrop').hide();
      });

      $('.menu-icon').click(function () {
        $('aside').show();
        $('.backdrop').show();
      });
    });

    this.getInvoice();

    // this.config={
    //   itemsPerPage: 5,
    //    currentPage: this.p
    // }
  }

  invoiceVar: Invoice | any;
  noInvoice: boolean = false;

  getInvoice() {
    // this.spinner.show();
    this.Service.getInvoices().subscribe((res) => {
      if (res.invoices != 0) {
        this.invoiceVar = res.invoices;

        // this.spinner.hide();
        setTimeout(() => {
          $('.preloader').css('display', 'none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      } else {
        // this.spinner.hide();
        this.noInvoice = true;
        setTimeout(() => {
          $('.preloader').css('display', 'none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      }
    });
  }

  // pageChanged(event:any){
  //   this.config.currentPage = event;
  // }

  // signInAnonymously(){

  //  this.ServiCE.anonymousLogin();

  // }
}
