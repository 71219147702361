import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-numbers-speak',
  templateUrl: './web-numbers-speak.component.html',
  styleUrls: ['./web-numbers-speak.component.css']
})
export class WebNumbersSpeakComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
