<div class="main-banner-wrapper">
<swiper
      [slidesPerView]="1"
        effect=fade
      [loop]=true
      [autoplay]={delay:1000} [parallax]=true>
      <ng-template swiperSlide>
          <img src="../../../assets/images/slider/slider-2-1.jpg" alt="" height="100%">
      </ng-template>
      <ng-template swiperSlide>
        <img src="../../../assets/images/slider/slider-2-2.jpg" alt=""  height="100%">
      </ng-template>
      <!-- <ng-template swiperSlide>Slide 3</ng-template> -->
    </swiper>
</div>


 <!-- <div class="main-banner-wrapper">

    <section class="banner-style-two owl-theme owl-carousel no-dots">
        <div class="slide slide-one" style="background-image: url(../../../assets/images/slider/slider-2-1.jpg);">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center invisible">
                        <div class="banner-circle" style="background-image: url(../../../assets/images/slider/slider-2-1.jpg);">
                            <div class="inner-block">
                                <h3>Now enjoy <br> comfortable <br> trip with <br> conexi</h3>
                                <a href="#" class="banner-btn">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slide slide-two" style="background-image: url(../../../assets/images/slider/slider-2-2.jpg);">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center invisible">
                        <div class="banner-circle" style="background-image: url(../../../assets/images/slider/slider-2-2.jpg);">
                            <div class="inner-block">
                                <h3>Now enjoy <br> comfortable <br> trip with <br> conexi</h3>
                                <a href="#" class="banner-btn">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="carousel-btn-block banner-carousel-btn">
        <span class="carousel-btn left-btn invisible"><i class="conexi-icon-left"></i></span>
        <span class="carousel-btn right-btn invisible"><i class="conexi-icon-right"></i></span>
    </div>
</div>  -->
<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->