import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BookingListingServiceService } from '../../bookings/booking-listing-service.service';
import { Track } from '../track';
import { TrackServiceService } from '../track-service.service';
import { } from 'googlemaps';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-track-trip-details',
  templateUrl: './track-trip-details.component.html',
  styleUrls: ['./track-trip-details.component.css']
})
export class TrackTripDetailsComponent implements OnInit {

// mapShow:boolean = false;


  detailsTrack:Track  | any;
  source:any;
  destination:any;
  trip_date:any;
  trip_end_date:any;
  passengerName:any;
  passengerContact:any;
  passengerCount:any;
  booking_id:any;
  booking_date:any;
  carType:any;
  driver:any;
  driver_number:any;
  booking_status:any;
  request_type:any;
  date:any;
  tripIDS:any;
  destination_latitude:any;
  destination_longitude:any

  constructor(private router:Router, private route:ActivatedRoute,
     private Service:BookingListingServiceService,
       public ServiCE:TrackServiceService,private location: Location,private titleHeader:Title,) {

       }

       public setTitle(newTitle: string) {
        this.titleHeader.setTitle(newTitle);
      }

      



       
  id:any;

  lat: any = 21.5244216;
  lng: any = 70.4528144;
  zoom: Number = 6;
  waypoints:any;

  public origin: any;
public destinationss: any;

public renderOptions = {
  suppressMarkers: true,
}


 public markerOptions = {
        origin: {
            icon: 'assets/carESmall.png',
            draggable: false,
        },
        destination: {
            icon: 'assets/pinSmall.png',
        },
        waypoints: {
          icon: 'path-to-icon'
        }
    }
vary:boolean=true;
  ngOnInit(): void {
    this.setTitle('Track Trip | Evcityride');

     this.shwLog();

    setTimeout(() => {
      $(".preloader").css('display','none');
      $('.preloader').fadeOut('slow');
      this.ServiCE.anonymousLogin();
    }, 1000);
 



    this.id = this.route.snapshot.params.id;
    
    this.getTrackDetails();
    this.ServiCE.anonymousLogin();

    this.ServiCE.getAll().snapshotChanges().pipe(
      map(changes => 
        changes.map(c =>
          ({key:c.payload.key, ...c.payload.val()})))
    ).subscribe(data=>{
      // 
      this.fireData = data;
      this.showFire = true;



      this.fireLoop = this.fireData.filter((ele:any) =>{
        return ele.tripId == this.id
      });


      // 
      this.origin = { lat: Number(this.fireLoop[0].source.lattitude),
         lng: Number(this.fireLoop[0].source.longtitude),
        };

      
     
    })
    // this.anonymousLogins();
  
 

    // this.getFire();
    $(document).ready(function(){
      $("aside").hide();
      $(".backdrop").hide();
      
      $(".backdrop").click(function () {
        $("aside").hide();
        $(".backdrop").hide();
        $("aside").removeClass("show-drower");
      });
      $(".menu-icon").click(function () {
        $("aside").show();
        $(".backdrop").show();
      });
    });


    if( this.vary == true){
      // 
      
      this.ServiCE.anonymousLogin();
      
    }else{
      
    }

  }
  


distance:any;
time:any;


  getTrackDetails(){
    // this.spinner.show();
    this.Service.getOngoingBookingsDetails(this.id).subscribe((res)=>{
      this.detailsTrack = res.data;
      this.source = this.detailsTrack.source;
      this.destination = this.detailsTrack.destination;
      this.trip_date = this.detailsTrack.trip_date;
      this.trip_end_date = this.detailsTrack.trip_end_date;
      this.passengerName = this.detailsTrack.passenger_name;
      this.passengerContact = this.detailsTrack.passenger_contact_number;
      this.passengerCount = this.detailsTrack.total_passenger;
      this.booking_id = this.detailsTrack.booking_id;
      this.booking_date = this.detailsTrack.booking_date;
      this.carType = this.detailsTrack.carType;
      this.driver = this.detailsTrack.driver;
      this.driver_number = this.detailsTrack.driver_number;
      this.booking_status = this.detailsTrack.booking_status;
      this.request_type = this.detailsTrack.request_type;
        this.date = this.detailsTrack.date;
        setTimeout(() => {
          $(".preloader").css('display','none');
          $('.preloader').fadeOut('slow');
        }, 1000);
        
      
      // this.spinner.hide();
      this.tripIDS = this.detailsTrack.id;
      this.destination_longitude = this.detailsTrack.destination_longitude;
      this.destination_latitude  = this.detailsTrack.destination_latitude;

    this.destinationss = { lat: Number(this.destination_latitude), lng: Number(this.destination_longitude) };
    



    });

    
    
  }

  





showFire:boolean =false;
// showMap:boolean=false;

  gotoTrackTrip(){
    this.router.navigateByUrl('/rider/track-trip');
  }


   logout() {
    // this.ServiCE.signOut();
    // this.ngOnInit();
  }

  fireLoop:any;

fireData:Track | any;





  // trackStart(){
  //   
  //   this.getFire();
  // }
  // showTrackTripDetails:boolean=true;

  // showMap(){
  //   this.anonymousLogins();
    
  // }

  shwLog(){
    this.ServiCE.anonymousLogin();
  }

  anonymousLogins(){
    this.getTrackDetails();
    this.ServiCE.anonymousLogin();
    // this.router.navigate[('/rider/track-trip/map/', )];
    // [routerLink]="['/rider/track-trip/map/', trip.id]"
    // this.mapShow = true;
    // this.showTrackTripDetails = false;
  }

showTrackTrip(){
  // this.mapShow = false;
  // this.showTrackTripDetails = true;
  // this.ServiCE.signOut();
}


  

   

}
