import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReqLayoutComponent } from '../featured/req-layout/req-layout.component';
import { WebAboutComponent } from '../featured/web-about/web-about.component';
import { WebContactComponent } from '../featured/web-contact/web-contact.component';
import { WebDownloadAppComponent } from '../featured/web-download-app/web-download-app.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
// import { WebLoginComponent } from '../featured/web-login/web-login.component';
// import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  // {path:'', redirectTo:'/home', pathMatch:'full'},
  // {path:'home', component:HomeComponent},
  // {path:'about', component:WebAboutComponent},
  // {path:'download_app', component:WebDownloadAppComponent},
  // {path:'contact', component:WebContactComponent},
  // {path:'req', component:ReqLayoutComponent},
  

  // {
  //   path:'**', component:PageNotFoundComponent
  // }
//  {path:'**' , component:PageNotFoundComponent}

  // {path:'login', component:WebLoginComponent},



];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
