import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginServiceService } from './login-service.service';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { ToastrServiceService } from 'src/app/services/toastr-service.service';
import { AuthsService } from '../auths.service';
import { ConfirmedValidator } from './confirm.validator';
import { interval, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
// import { NgxSpinnerService } from "ngx-spinner";
import { Title } from '@angular/platform-browser';
import { CountdownComponent } from 'ngx-countdown';

@Component({
  selector: 'app-web-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.css'],
})
export class WebHeaderComponent implements OnInit {
  loginForm: FormGroup | any;
  submitted = false;
  error: {} | any;
  loginError: string | any;
  forgot_form: FormGroup | any;
  userId: any;
  otp: string | any;
  showOtpComponent = true;
  confirmPasswordForm: FormGroup | any;

  showMe: boolean = false;
  // timeLeft: number = 5;

  // interval:any;
  // subscribeTimer: any;
  // timeLeft:number |any;
  // timerId:any;
  // elem:any;
  // private spinner:NgxSpinnerService,
  //
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastrMsg: ToastrServiceService,
    private route: ActivatedRoute,
    public Ser: AuthsService,
    private http: HttpClient,
    private titleHeader: Title
  ) {}
  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }

  urlParams: any = {};
  userDisplayName: any = '';
  // aemjId:any;

  closeAll() {
    $('.modal').hide();
  }

  ngOnInit() {
    // for (let i = 30; i > 0; i--) {
    //
    // }

    // this.startResetTimerTimer();
    // this.startResetTimer();

    // this.startResetTimerCountDown();

    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
    });

    this.forgot_form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    $(document).ready(function () {
      $('.backdrop').hide();

      $(document).ready(function () {
        $('#closeTrigger').click(function () {
          $('#Lg').trigger('reset');
        });
      });
    });

    this.resetiPassLast();
  }

  // oberserableTimer() {
  //   const source = timer(1000, 2000);
  //   const abc = source.subscribe(val => {
  //
  //     this.subscribeTimer = this.timeLeft - val;
  //   });
  // }

  // startResetTimerTimer() {
  //   this.interval = setInterval(() => {
  //     if(this.timeLeft > 0) {
  //       this.timeLeft--;
  //
  //     } else {
  //       // this.timeLeft = 60;
  //       // this.pauseTimer();
  //       // this.startResetTimerTimer();
  //       //
  //       clearInterval(this.interval);
  //       this.showHideResendMail = true;

  //     }
  //   },1000)
  // }

  // pauseTimer() {
  //   clearInterval(this.interval);
  // }

  // startResetTimerCountDown(){
  //   if(this.init && this.init > 0){
  //     this.counter = this.init;
  //     this.doCountDown();
  //
  //   }
  // }

  // doCountDown(){
  //   setTimeout(() => {
  //     this.counter = this.counter -1;
  //     this.processCount();
  //   },1000);
  // }

  // processCount(){
  //
  //   if(this.counter == 0 ){
  //
  //   }
  //   else{
  //     this.doCountDown();
  //   }
  // }

  // mona(){
  //   this.showMe = !this.showMe;
  // }

  fieldTextType: boolean | any;
  repeatFieldTextType: boolean | any;
  fieldTextTypeLogin: boolean | any;

  toggleFieldLogin() {
    this.fieldTextTypeLogin = !this.fieldTextTypeLogin;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleRepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }

  toggleDual() {
    this.fieldTextType = !this.fieldTextType;
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }

  idAgency: any = '';

  resetiPassLast() {
    const cnst = this.desk;

    this.idAgency = localStorage.getItem('agency_id');

    this.confirmPasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]],
      agency_id: [this.idAgency],
    });
  }

  get confirm_password() {
    return this.confirmPasswordForm.get('confirm_password');
  }

  get new_pass() {
    return this.confirmPasswordForm.get('new_pass');
  }

  config = {
    allowNumbersOnly: false,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '50px',
      height: '50px',
    },
  };

  onOtpChange(otp: any) {
    this.otp = otp;
  }

  get username() {
    return this.loginForm.get('username');
  }
  get password() {
    return this.loginForm.get('password');
  }

  get email() {
    return this.forgot_form.get('email');
  }

  get f() {
    return this.confirmPasswordForm.controls;
  }

  serverUrls = 'https://evcityride.9brainz.store/api/v1/reset-password';

  resetpswd: false | any;

  varyReset: any;
  desk: any;
  loginIN: boolean = false;
  forgotpassEmail: boolean = false;
  otpSec: boolean = false;
  confPass: boolean = false;

  // mobiles(){
  //   this.stopCounter();
  // }

  closeMdl() {
    this.stopCounter();
    this.loginIN = false;
    this.forgotpassEmail = false;

    this.otpSec = false;
    this.confPass = false;
    this.forgot_form.reset();
  }

  loginClick() {
    // $(".login").show();
    this.loginIN = true;
    this.forgotpassEmail = false;
    this.otpSec = false;
    this.confPass = false;
  }

  forgetPasswordSection() {
    this.forgotpassEmail = true;
    this.loginIN = false;
  }

  bckTologin() {
    this.loginIN = true;
    this.forgotpassEmail = false;
  }

  goMail() {
    this.forgotpassEmail = true;
    this.loginIN = false;
    this.otpSec = false;
    this.stopCounter();
  }

  // backToLogin() {
  //   $(".login").show();
  //   $(".otp-verification").hide();
  //   $(".forgot-password-details").hide();
  //   $(".forgot-password").hide();
  // }

  localdetails: any;
  // consoleTry: any;
  varyFrgt: any;
  otpSection: false | any;
  forgetPasswrd: true | any;
  isSuccess: boolean | any;

  otps: any;
  styleapply: any;

  public submi: boolean = false;

  onSubmit() {
    this.submi = true;
    this.forgetPasswrd = true;
    // this.spinner.show();
    this.Ser.login(this.username.value, this.password.value).subscribe(
      (data) => {
        // this.spinner.show();

        if (this.loginForm.valid) {
          // this.spinner.show();
          if (this.Ser.isLoggedIn()) {
            // this.spinner.show();
            // const redirect = this.Ser.redirectUrl
            //   ? this.Ser.redirectUrl
            //   : "/rider";
            this.router.navigate(['/rider/request-a-ride']);

            this.loginForm.reset();
            this.toastrMsg.showSuccess(
              ' You are Logged In Successfully ',
              'Logged In'
            );
            this.isSuccess = true;

            $('.modal-backdrop').removeClass('show');

            $('.modal-backdrop').css('display', 'none');
            $('.modal-open').css('overflow', 'auto');
            this.titleHeader.setTitle('Request a ride | EVcityride');
            // setTimeout(() => {
            //   this.spinner.hide();
            // }, 1000);
          } else {
            this.loginError = 'Username or password is incorrect.';
            this.toastrMsg.showError(
              'These credentials don’t match our records',
              'Failed'
            );

            // this.spinner.hide();
          }
        } else {
        }
      },
      (error) => (this.error = error)
    );
  }

  showHideResendMail: boolean = false;

  // init :number = 30;

  // public counter:number = 0;

  // startResetTimer(){
  //   if(this.init && this.init > 0 ){
  //     this.counter = this.init;
  //     this.doCount();
  //   }else{
  //
  //   }
  // }

  // doCount(){

  //   setTimeout(() => {
  //     this.counter = this.counter -1;
  //     this.process();

  //   }, 1000);
  // }

  // process(){

  //   if(this.counter == 0){
  //
  //     this.showHideResendMail = true;
  //   }else{
  //     this.doCount();
  //     this.showHideResendMail = false;
  //   }
  // }

  // otpEnd(){
  //   this.counter = 0;
  //
  // }

  //

  // status = 'ready';
  // @ViewChild('countdown') counter: CountdownComponent | any;

  // start() {
  //   this.status = 'started';
  //   this.counter.begin();
  // }

  // finishTest() {
  //
  //   setTimeout(() => this.counter.restart());
  //   this.status = 'restarted';
  // }

  // resetTimer() {
  //   this.counter.restart();
  // }

  counter: any;
  interval: any;

  startCountdown() {
    this.counter = 30;

    this.interval = setInterval(() => {
      //
      this.counter--;

      if (this.counter <= 0) {
        clearInterval(this.interval);

        this.showHideResendMail = true;
      } else {
        this.showHideResendMail = false;
      }
    }, 1000);
  }

  stopCounter() {
    clearInterval(this.interval);
  }

  resetOTP() {
    this.onForgot();

    this.showHideResendMail = false;
    // this.startResetTimer();
  }

  loggggin: boolean = true;

  emailStored: any;

  onForgot() {
    this.submitted = true;
    this.Ser.forgotPassword(this.email.value).subscribe((res) => {
      var userCurnt = localStorage.getItem('currentUser');
      this.desk = localStorage.getItem('agency_id');

      this.varyFrgt = JSON.parse(<any>userCurnt);

      if (this.varyFrgt.success == true) {
        this.submitted = true;
        this.toastrMsg.showSuccess('Check Your Gmail Now', 'Success');
        this.forgetPasswrd = false;
        this.otpSection = true;
        // this.startResetTimer();
        // this.start();
        this.emailStored = this.email.value;

        this.otpSec = true;
        this.loginIN = false;
        this.forgotpassEmail = false;
        this.startCountdown();
      } else {
        this.submitted = false;
        this.toastrMsg.showError('Your Email is Wrong', 'Failed');
        this.otpSection = false;
        this.forgetPasswrd = true;
      }
    });
  }

  SendOtp() {
    var getID = localStorage.getItem('agency_id');

    this.Ser.otpVerify(this.otp, getID).subscribe((data) => {
      var cUser = localStorage.getItem('currentUser');

      this.otps = JSON.parse(<any>cUser);

      if (this.otps.success == true) {
        this.toastrMsg.showSuccess('OTP is verified', 'Success');
        // $(".forgot-password-details").hide();
        this.otpSection = false;
        this.resetpswd = true;
        this.confPass = true;
        this.loginIN = false;
        this.otpSec = false;
        this.forgotpassEmail = false;

        // this.startResetTimerCountDown();
        // window.location.reloa  d();
      } else {
        this.toastrMsg.showError(this.otps.message, 'Wrong ');
        this.resetpswd = false;
      }
    });
  }

  public subb: boolean = false;

  Pass_submit() {
    this.subb = true;
    const formData = new FormData();

    formData.append('password', this.confirmPasswordForm.get('password').value);
    formData.append(
      'confirm_password',
      this.confirmPasswordForm.get('confirm_password').value
    );
    formData.append('agency_id', <any>localStorage.getItem('agency_id'));

    this.http.post<any>(this.serverUrls, formData).subscribe((res) => {
      localStorage.setItem('currentUser', JSON.stringify(res));

      var userpassCurr = localStorage.getItem('currentUser');

      this.varyReset = JSON.parse(<any>userpassCurr);

      if (this.confirmPasswordForm.valid) {
        if (this.varyReset.success == true) {
          this.router.navigate(['/rider']);
          this.toastrMsg.showSuccess('Password Has Changed', 'Success');
          // localStorage.setItem('agency_name', res.agency_name);
          localStorage.getItem('currentUser');
          this.confirmPasswordForm.reset();
          $('.modal-backdrop').removeClass('show');

          $('.modal-backdrop').css('display', 'none');
          $('#login').css('display', 'none');
          $('#login').removeClass('show');

          $('.modal-open').css('overflow', 'auto');
          this.resetpswd = false;
        } else {
          this.toastrMsg.showError(this.varyReset.message, 'Worng');
        }
      } else {
      }
    });
  }

  // loginClick(){
  //   this.otpSection = false;
  //   this.forgetPasswrd = false;
  //   this.resetpswd = false;
  // }

  // loggn:true | any;
  // loginFrm : any;

  //   bckLogin(){
  //     this.loginFrm = !this.loginFrm;
  //     // this.otpSection = false;
  //     this.resetpswd = false;
  //     // this.forgetPasswrd = false;
  //     alert('heyy');
  //   }
}
