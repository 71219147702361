import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginServiceService } from '../../shared/web-header/login-service.service';
import * as $ from 'jquery';
import { NotificationsService } from './notifications.service';
import { Notify } from './notify';
import { Title } from '@angular/platform-browser';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  notificationCount: Subscription | any;

  constructor(
    private router: Router,
    private loginSer: LoginServiceService,
    private notifyService: NotificationsService,
    private titleHeader: Title,
    private getNotifyAlert: NotificationAlertService
  ) {
    this.getNotifyAlert.sendClickEvent();

    this.notificationCount = this.getNotifyAlert
      .getClickEvent()
      .subscribe(() => {
        this.getNotifications();
      });
  }

  localdetails: any;
  osm: any;
  notify: boolean = false;
  notificationCounts: any = '';

  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  ngOnInit(): void {
    this.setTitle('Request a ride | Evcityride');

    this.getNotifications();

    $(document).ready(function () {
      // $(".drower-menu").hide();
      $('.backdrop').hide();

      // Show & Hide icon and search field in header
      // $("#search-icon").click(function(){
      //   $(".header-search-box").show();
      // });
      // $(".back-icon").click(function(){
      //   $(".header-search-box").hide();
      //   $("#search-icon").show();
      // });

      $('.backdrop').click(function () {
        $('.drower-menu').hide();
        $('.backdrop').hide();
      });

      $('.menu-icon').click(function () {
        $('.drower-menu').show();
        $('.backdrop').show();
      });

      $('.tri').click(function () {
        $('.drower').addClass('show-drower');
        $('.backdrop').show();
      });

      $('#close').click(function () {
        $('.drower').removeClass('show-drower');
        $('.backdrop').hide();
      });

      $('.backdrop').click(function () {
        $('.drower').removeClass('show-drower');
        $('.backdrop').hide();
      });

      // Toggle Notification Container
      $('.notification-tri').click(function () {
        $('.notification-container').toggleClass('show');
      });

      $('#view-booking-tri').click(function () {
        var viewBooking = 'yes';
        localStorage.setItem('tabValue', viewBooking);
        window.location.href = 'bookings.html';
      });
    });

    // localStorage.getItem('agencyName');
    //

    //  this.localdetails = localStorage.getItem('currentUser');
    // this.osm = JSON.parse(this.localdetails);
    //

    var sk = localStorage.currentUser;
    this.osm = JSON.parse(sk);
  }

  close_noty() {
    this.notify = !this.notify;
  }
  // openNotify(){
  //   this.notify = !this.notify;
  // }

  // mouseenter(){
  //   this.notify = !this.notify;

  // }

  notifys: Notify | any;
  dks: any;

  getNotifications() {
    this.notifyService.getNotifications().subscribe((res) => {
      this.notifys = res.notifications;
      //
    });
  }

  logout() {
    //  setTimeout(() => {
    //    $(".preloader").css('display','none');
    //  $('.preloader').fadeOut('slow');
    //  this.forceLogout();
    //  }, 2000);

    this.forceLogout();
    // this.spinner.show();

    // setTimeout(() => {
    //   this.spinner.hide();
    // this.setTitle('Login | Evcityride');

    // }, 1000);

    //  window.location.reload();
    // this.forceLogout();
  }

  forceLogout() {
    localStorage.clear();

    this.router.navigateByUrl('/');
  }
}
