<footer class="site-footer">
    <img src="assets/images/background/footer-bg-1-1.png" class="footer-bg" alt="Awesome Image" />
    <div class="upper-footer">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-lg-4">
                    <div class="footer-widget about-widget">
                        <div class="widget-title">
                            <h3>About</h3>
                        </div>
                        <p>We are a technology - enabled news company that aims to deliver fresh news through our network and provide transparency to our clients.</p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="footer-widget">
                        <div class="widget-title">
                            <h3>Important links</h3>
                        </div>
                        <ul class="link-lists">
                            <li><a href="#" target="_blank">Terms of Use</a></li>
                            <li><a href="#" target="_blank">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="footer-widget">
                        <div class="widget-title">
                            <h3>Contact</h3>
                        </div>
                        <p>Stay in touch with us</p>
                        <div class="social-block">
                            <a href="#"><i class="fa fa-twitter"></i></a>
                            <a href="#"><i class="fa fa-facebook-f"></i></a>
                            <a href="#"><i class="fa fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="bottom-footer">
        <div class="container-fluid">
            <div class="inner-container">
                <div class="left-block">
                    <span>&copy; 2021 <a href="#">evcityride.com</a></span>
                </div>
            </div>
        </div>
    </div>
    
</footer>