import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusPipe'
})
export class StatusPipe implements PipeTransform {


  transform(arr:any[], statusValue:any){
    if(statusValue){
      statusValue = statusValue.toLowerCase();
      return arr.filter(function (value:any) {
        return value.booking_status.toLowerCase().indexOf(statusValue.toLowerCase()) > -1;
      })
    }
    return arr;
  
  }





}
