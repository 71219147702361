import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';
import { BookingListingServiceService } from 'src/app/featured/bookings/booking-listing-service.service';
import { Billing } from '../../billing';


@Component({
  selector: 'app-airport-drop-details',
  templateUrl: './airport-drop-details.component.html',
  styleUrls: ['./airport-drop-details.component.css']
})
export class AirportDropDetailsComponent implements OnInit {

  constructor(private router:Router, private route:ActivatedRoute,
     private Service:BookingListingServiceService,
      private titleHeader:Title) { }

  id:any;
  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  ngOnInit(): void {
  this.setTitle('Billings-Airport Drop Details | Evcityride');

    this.id = this.route.snapshot.params.id;
    

    this.dropDetails();

    $(document).ready(function(){
      $(".menu-icon").click(function(){
        $(".aside-menu").show();

      });
      
      $(".backdrop").click(function(){  
        $(".aside-menu").hide();
        $(".backdrop").hide();
      });
    });
  }

  airDropDetails:Billing | any;
  source:any;
  destination:any;
  trip_date:any;
  trip_end_date:any;
  passengerName:any;
  passengerContact:any;
  passengerCount:any;
  booking_id:any;
  booking_date:any;
  carType:any;
  driver:any;
  driver_number:any;
  date:any;
  end_date:any;

  dropDetails(){
    // this.spinner.show();

    this.Service.getOngoingBookingsDetails(this.id).subscribe((res)=>{


      if(res.success == true){
        this.airDropDetails = res.data;
        this.source = this.airDropDetails.source;
        this.destination = this.airDropDetails.destination;
        this.trip_date = this.airDropDetails.trip_date;
        this.trip_end_date = this.airDropDetails.trip_end_date;
        this.passengerName = this.airDropDetails.passenger_name;
        this.passengerContact = this.airDropDetails.passenger_contact_number;
        this.passengerCount = this.airDropDetails.total_passenger;
        this.booking_id = this.airDropDetails.booking_id;
        this.booking_date = this.airDropDetails.booking_date;
        this.carType = this.airDropDetails.carType;
        this.driver = this.airDropDetails.driver;
        this.driver_number = this.airDropDetails.driver_number;
        this.date = this.airDropDetails.date;
        this.end_date = this.airDropDetails.end_date
        
        // this.spinner.hide();
        setTimeout(() => {
          $(".preloader").css('display','none');
          $('.preloader').fadeOut('slow');
        }, 1000);
       
      }else{
        
        // this.spinner.hide();
        setTimeout(() => {
          $(".preloader").css('display','none');
          $('.preloader').fadeOut('slow');
        }, 1000);
      }
     
     
     
      
    })
  }
  gotoAirportDrop(){
    this.router.navigateByUrl('/rider/billing/airport_drop');
    
  }

}
