<!-- <ngx-spinner bdColor="white" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
<div class="preloader"></div>

<div
  class="tab-pane"
  id="drop"
  role="tabpanel"
  aria-labelledby="airport-drop-tab"
>
  <form [formGroup]="airDropPostForm" (ngSubmit)="airDropPostSubmit()">
    <div class="row">
      <div class="col-md-6">
        <div class="col-sm-12 col-md-12">
          <a
            class="cp-container mb-4"
            style="height: 65px"
            id="choose-plan-tri-2"
            (click)="clickAirport_DropFares(carsTypeDrop[0].fares)"
          >
            <p *ngIf="showMeairDrop">Choose Plan</p>
            <p *ngIf="showMeAfterairDrop">
              {{ airDropfareOBJ.kilometer }}KM - ₹{{
                airDropfareOBJ.basic_fare
              }}
            </p>

            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </a>
          <!-- <div *ngIf="submi">
            <div class="alert alert-danger" *ngIf="showMeAfterairDrop == false">
              Choose Plan First..
            </div>
          </div> -->
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field disab"
                formControlName="source"
                [(ngModel)]="srcDrop"
              />
              <label>Source</label>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field"
                placeholder="Passenger Name"
                name="passenger_name"
                formControlName="passenger_name"
                required
                (keypress)="keyPressAlphaNumericWithCharacters($event)"
                maxlength="50"
              />
              <label>Passenger Name</label>

              <!-- <div *ngIf="f.passenger_name.touched && f.passenger_name.invalid" class="alert alert-danger">
                     Passenger name required and passenger name must alphabets...
                    </div> -->
              <!-- <div
                      *ngIf="f.passenger_name?.errors && f.passenger_name.invalid"
                      class="text-danger"
                    >

                    <div *ngIf="submi || f.passenger_name.invalid">
                      <div class="alert alert-danger" *ngIf="!airDropPostForm.value.passenger_name">Passenger Name Is Required</div>
                    </div>

                    </div> -->
            </div>
          </div>
        </div>

       <!-- <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field"
                placeholder="Executive Name"
                name="executive_name"
                formControlName="executive_name"
                (keypress)="keyPressAlphaNumericWithCharacters($event)"
                maxlength="50"
              />
              <label>Executive Name</label>
              <div></div>
            </div>
          </div>
        </div>-->

        <!-- <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field"
                placeholder="Flight Number"
                name="flight_number"
                formControlName="flight_number"
                required
                maxlength="50"
              />
              <label>Flight Number</label>
              <div></div>
            </div>
          </div>
        </div> -->

        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <p
                style="
                  position: relative !important;
                  margin-bottom: 11px;
                  margin-left: 2px;
                "
              >
                Passenger Mobile Number
              </p>

              <ngx-intl-tel-input
                [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="false"
                [searchCountryFlag]="true"
                [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India"
                [phoneValidation]="true"
                [separateDialCode]="separateDialCode"
                [numberFormat]="PhoneNumberFormat.National"
                name="passenger_contact_number"
                formControlName="passenger_contact_number"
              >
              </ngx-intl-tel-input>
              <div *ngIf="submi">
                <!-- <div class="alert alert-danger" *ngIf="airDropPostForm.controls['passenger_contact_number']?.errors?.required">
                        Contact Number Required
                      </div> -->

                <div
                  class="alert alert-danger"
                  *ngIf="
                    airDropPostForm.controls['passenger_contact_number']?.errors
                      ?.validatePhoneNumber
                  "
                >
                  Invalid Phone
                </div>
              </div>
              <!-- <span style="color: red;"  *ngIf="passenger_contact_number.invalid && passenger_contact_number.touched" >

              </span> -->
              <!-- <div *ngIf="submi">
                <div class="alert alert-danger" *ngIf="!airDropPostForm.value.passenger_contact_number">Contact Number Required</div>
                <div class="alert alert-warning" *ngIf="passenger_contact_number.invalid">Invalid Contact Number</div>
              </div> -->
              <!-- <span *ngIf="f.passenger_contact_number.errors?.required" style="color: red;" >Contact Number is required.</span> -->
              <!-- <label>Passenger Mobile Number</label> -->
              <!-- This is {{!airDropPostForm.controls['passenger_contact_number'].invalid ? 'Right Number' : 'Wrong Number' }} -->
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-12 radio-container">
          <label for="bill-to-agency-2" class="radio">
            <input
              type="radio"
              name="payment_detail"
              id="bill-to-agency-2"
              class="radio__input"
              value="bill_to_agency"
              formControlName="payment_detail"
            />
            <div class="radio__radio"></div>
            Bill to agency
          </label>
          <label for="collect-cash-2" class="radio">
            <input
              type="radio"
              name="payment_detail"
              id="collect-cash-2"
              class="radio__input"
              value="collect_cash"
              formControlName="payment_detail"
            />
            <div class="radio__radio"></div>
            Collect Cash
          </label>
        </div>

        <div class="col-sm-12 col-md-12">
          <div class="footer-action">
            <button
              class="btn btn-primary btn-block submit-button"
              data-toggle="modal"
              style="height: 65px"
              type="submit"
              [disabled]="airDropPostForm.invalid"
            >
              Submit Request
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <div
                class="input-group date"
                data-target-input="nearest"
                NgTempusdominusBootstrap
              >
                <input
                  style="height: 65px"
                  class="form-control"
                  name="start_date"
                  formControlName="start_date"
                  placeholder="Start Date"
                  [options]="optionsDate"
                  NgTempusdominusBootstrapInput
                  type="text"
                />
                <div class="input-group-append" NgTempusdominusBootstrapToggle>
                  <div class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </div>
                </div>
              </div>

              <!-- <div *ngIf="submi">
                <div class="alert alert-danger" *ngIf="!airDropPostForm.value.start_date">Date Is Required</div>
              </div> -->
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field disab"
                formControlName="destination"
                [(ngModel)]="destiDrop"
              />
              <label>Destination</label>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                type="text"
                class="input-field"
                placeholder="Passenger Count"
                name="total_passenger"
                formControlName="total_passenger"
                maxlength="1"
                autocomplete="off"
              />
              <label>Passenger Count</label>

              <div
                class="d-flex w-100 align-items-center text-center justify-content-center mt-2"
              >
                <div
                  *ngIf="
                    airDropPlansOBJ.max_passenger > 0 ||
                    airDropPostForm.get('total_passenger').errors?.crossVal
                  "
                >
                  <div
                    *ngIf="
                      airDropPostForm.get('total_passenger').value >
                      airDropPlansOBJ.max_passenger
                    "
                  >
                    <p
                      class="text-danger mr-2"
                      style="display: inline-block; text-align: center"
                    >
                      Total Passenger Limit is
                      {{ airDropPlansOBJ.max_passenger }}
                    </p>
                  </div>
                </div>
                <div *ngIf="airDropPlansOBJ.max_passenger == ''">
                  <div
                    *ngIf="
                      airDropPostForm.get('total_passenger').errors?.crossVal
                    "
                  >
                    <p
                      class="text-danger"
                      style="display: inline-block; text-align: center"
                    >
                      7
                    </p>
                  </div>
                </div>
              </div>

              <!-- <div class="alert alert-danger" *ngIf="airDropPostForm.get('total_passenger').dirty &&
              airDropPostForm.get('total_passenger').errors
              && airDropPostForm.get('total_passenger').errors.crossVal" >
                Total Passenger must 7
              </div> -->
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-12">
          <div class="form-layout">
            <div class="form-group mb-4">
              <input
                type="text"
                class="input-field"
                placeholder="Remarks"
                name="remarks"
                formControlName="remarks"
                (keypress)="keyPressAlphaNumericWithCharacters($event)"
                maxlength="50"
              />
              <label>Remarks</label>
              <div></div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-sm-12">
          <div
            class="card car-card w-50 mb-4"
            style="max-width: 155px !important"
            *ngIf="showImgairDrop"
          >
            <div class="card-body drower-card-body p-0">
              <img
                *ngIf="airDropPlansOBJ.image != ''"
                name="image"
                [src]="airDropPlansOBJ.image"
                alt="Sorry, Image is not available"
                style="width: 100%; height: 100%"
              />
              <img
                *ngIf="airDropPlansOBJ.image == ''"
                name="image"
                src="assets/images/emptyImage.svg"
                alt="Sorry, Image is not available"
                style="width: 100%; height: 100%"
              />
              <a name="Name" class="car-button">{{ airDropPlansOBJ.name }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!--
<div class="modal fade" *ngIf="hidenSeek" [ngClass]="{'classic':classModal}"  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Confirm Booking</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="image-container d-flex justify-content-center">
  <ng-lottie width="300px" height="300px" [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>

            </div>
            <h5 class="text-center">{{msgCongr}}</h5>
            <p class="text-center">Admin will assign car shortly.</p>
            <p class="text-center text-primary">Booking ID : {{booking_id}}</p>
            <hr>
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <p>Car Type</p>
                    <p>{{carType}}</p>
                </div>
                <div class="col-sm-12 col-md-4">
                    <p>Plan</p>
                    <p>{{airDropfareOBJ.kilometer}} KM - {{hours}} Hours (₹ {{plan_price}})</p>
                </div>
                <div class="col-sm-12 col-md-4">
                    <p>From Date & Time</p>
                    <p>{{trip_date}}</p>
                </div>

            </div>
        </div>
        <div class="modal-footer justify-content-start">
            <button type="button" routerLink="/rider/bookings" class="btn btn-primary">View Bookings</button>
        </div>
    </div>
</div>
</div>  -->

<div
  class="modal fade"
  *ngIf="hidenSeek"
  [ngClass]="{ classic: classModal }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg modalStyle"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirm Booking</h5>
        <button
          type="button"
          class="close"
          (click)="close()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="image-container d-flex justify-content-center">
          <ng-lottie
            class="lotieAnim"
            [options]="options"
            (animationCreated)="animationCreated($event)"
          ></ng-lottie>

          <!-- <lottie-player src="" background="transparent"  speed="1" class="lottie" loop autoplay></lottie-player> -->
          <!-- <img src="assets/images/icons/Right sign.png" alt="" class="ing-fluid"> -->
        </div>
        <h5 class="text-center submitMsg" style="font-weight: bold">
          Booking request submitted successfully...
        </h5>
        <p class="text-center">Admin will assign driver shortly.</p>
        <hr />

        <p class="text-center text-primary" style="font-weight: bold">
          Booking ID : {{ booking_id }}
        </p>
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="card-body">
              <div class="d-flex h-100">
                <div
                  class="left d-flex flex-column justify-content-between align-items-center"
                  style="
                    margin-right: 20px;
                    margin-top: 10px;
                    margin-bottom: 18px;
                  "
                >
                  <div class="round-bg"></div>
                  <div class="round-bg small"></div>
                  <div class="round-bg small"></div>
                  <div class="round-bg small"></div>
                  <div class="round-bg small"></div>
                  <div class="round-bg small"></div>
                  <div class="round-bg"></div>
                </div>
                <div
                  class="right d-flex flex-column h-100 justify-content-between"
                  style="height: 115px !important"
                >
                  <div class="left mb-4 d-flex">
                    <img src="assets/images/icons/Hotel.svg" alt="" />
                    <div class="content" style="padding-left: 30px">
                      <p class="mb-0 font-weight-bold text-dark">
                        {{ source }}
                      </p>
                      <!-- <p class="mb-0 text-muted">01:00 PM | Sat, 8 Jan 2021</p> -->
                    </div>
                  </div>

                  <div class="right d-flex">
                    <img src="assets/images/icons/airplane icon.svg" alt="" />
                    <div class="content pl-3">
                      <p class="mb-0 font-weight-bold text-dark">
                        {{ destination }}
                      </p>
                      <!-- <p class="mb-0 text-muted">12:00 PM | Sat, 8 Jan 2021</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <p>Car Type</p>
            <p style="font-weight: bold">{{ carType }}</p>
          </div>

          <div class="col-sm-12 col-md-6">
            <p>From Date & Time</p>
            <p style="font-weight: bold">{{ trip_date }}</p>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button
          id="hideModel"
          routerLink="/rider/bookings/upcoming"
          type="button"
          class="btn btn-primary btn-block"
        >
          View Bookings
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="drower" id="car-type-2">
  <div class="drower-header fixed-top">
    <a id="close" class="text-dark"
      ><i class="fa fa-arrow-left text-dark mr-2"></i></a
    >
    <b class="mt-3">Choose Plan</b>
  </div>

  <div class="drower-body">
    <p  >Select Car Type *</p>

    <form
      [formGroup]="airportDropForm"
      (ngSubmit)="airDropSubmit()"
      class="mt-2"
    >
      <div class="d-flex mb-3" style="overflow-x: auto; overflow-y: hidden;">
        <div
          class="mr-3"
          style="width: 180px;"
          *ngFor="let AirDrop of carsTypeDrop"
        >
          <label [for]="AirDrop.id" class="radio">
            <input
              type="radio"
              [id]="AirDrop.id"
              class="radio__input car-radio d-none"
              name="airDropControl"
              [value]="AirDrop"
              formControlName="airDropControl"
            />
            <div class="radio__radio d-none"></div>

            <div
              class="card car-card"
              (click)="clickAirport_DropFares(AirDrop.fares)"
            >
              <div class="card-body drower-card-body p-0">
                <img
                  [src]="AirDrop.image"
                  alt="Sorry, Image is not available"
                  class="img-fluid"
                />
                <a class="car-button">{{AirDrop.name}}</a>
              </div>
            </div>
          </label>
        </div>
      </div>

      <div class="container-fluid car-plan">
        <div class="row">
          <p>Plan *</p>

          <div
            class="col-md-12 pl-0 pr-0"
            *ngFor="let AirDropCarPlanLists of faresPlanAirportDrop "
          >
            <label
              for="{{AirDropCarPlanLists.fare_id}}"
              class="form-check-label"
            >
              <div class="card mt-4">
                <div class="card-body">
                  <p style="font-size: 22px;">{{AirDropCarPlanLists.name}}</p>
                  <div class="d-flex justify-content-between">
                    <h3>
                      {{AirDropCarPlanLists.kilometer}} KM -
                      {{AirDropCarPlanLists.hours}} Hours
                    </h3>
                    <h3 class="text-success">
                      ₹ {{AirDropCarPlanLists.basic_fare}}
                    </h3>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="left d-flex align-items-center">
                      <img src="assets/images/infoicon.svg" class="svgSize" alt="" />
                      <p class="plan-details">
                        {{AirDropCarPlanLists.gst_charge}}% GST Charged @ the
                        time of billing, If
                        {{AirDropCarPlanLists.extra_kilometer}} KM exceeds ₹
                        {{AirDropCarPlanLists.rs_per_km}} Per KM &amp; will be
                        Charged
                      </p>
                    </div>
                    <div class="right">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="airDropFareControl"
                          id="{{AirDropCarPlanLists.fare_id}}"
                          [value]="AirDropCarPlanLists"
                          formControlName="airDropFareControl"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>

          <button
            type="submit"
            class="btn btn-primary btn-block my-3 drower-button"
          >
            Apply
          </button>
        </div>
      </div>
    </form>
  </div>
</div> -->

<div class="drower" id="car-type-2">
  <div class="drower-header fixed-top">
    <a id="close" class="text-dark"
      ><i class="fa fa-arrow-left text-dark mr-2"></i
    ></a>
    <b>Choose Plan</b>
  </div>
  <form [formGroup]="airportDropForm" (ngSubmit)="airDropSubmit()" class="mt-2">
    <div class="drower-body">
      <p class="mt-3" style="margin-bottom: 10px !important">
        Select Car Type *
      </p>
      <div class="d-flex mb-4" style="overflow-x: auto; overflow-y: hidden">
        <div class="mr-3 card-width" *ngFor="let AirDrop of carsTypeDrop">
          <label [for]="AirDrop.id" class="radio">
            <input
              type="radio"
              [id]="AirDrop.id"
              class="radio__input car-radio d-none"
              name="airDropControl"
              [value]="AirDrop"
              formControlName="airDropControl"
            />
            <div class="radio__radio d-none"></div>
            <div
              class="card car-card"
              (click)="clickAirport_DropFares(AirDrop.fares)"
            >
              <div class="card-body drower-card-body p-0">
                <img
                  *ngIf="AirDrop.image != ''"
                  [src]="AirDrop.image"
                  alt="Sorry, Image is not available"
                  class="img-fluid plan-img"
                />
                <img
                  *ngIf="AirDrop.image == ''"
                  src="assets/images/emptyImage.svg"
                  alt="Sorry, Image is not available"
                  class="img-fluid"
                  style="max-width: 100%; height: 155px"
                />

                <a class="car-button">{{ AirDrop.name }}</a>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="container-fluid car-plan">
        <div class="row">
          <p>Plan *</p>
          <div
            class="col-md-12 pl-0 pr-0"
            *ngFor="let AirDropCarPlanLists of faresPlanAirportDrop"
          >
            <label
              for="{{ AirDropCarPlanLists.fare_id }}"
              class="form-check-label"
            >
              <div class="card mt-4">
                <div class="card-body">
                  <p style="font-size: 22px">{{ AirDropCarPlanLists.name }}</p>
                  <div class="d-flex justify-content-between">
                    <h3>
                      {{ AirDropCarPlanLists.kilometer }} KM -
                      {{ AirDropCarPlanLists.hours }} Hours
                    </h3>
                    <h3 class="text-success">
                      ₹ {{ AirDropCarPlanLists.basic_fare }}
                    </h3>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="left d-flex align-items-center">
                      <img
                        src="assets/images/infoicon.svg"
                        class="svgSize"
                        alt=""
                      />
                      <p class="plan-details">
                        {{ AirDropCarPlanLists.gst_charge }}% GST will be
                        charged at the time of billing, If
                        {{ AirDropCarPlanLists.extra_kilometer }} KM exceeds
                        then ₹ {{ AirDropCarPlanLists.rs_per_km }} Per KM &amp;
                        ₹{{ AirDropCarPlanLists.rs_per_hour }} for every extra
                        hour will be Charged
                      </p>
                    </div>
                    <div class="right">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="airDropFareControl"
                          id="{{ AirDropCarPlanLists.fare_id }}"
                          [value]="AirDropCarPlanLists"
                          formControlName="airDropFareControl"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="drower-footer fixed-bottom">
      <button
        type="submit"
        [disabled]="this.airportDropForm.invalid"
        class="btn btn-primary btn-block"
      >
        Apply
      </button>
    </div>
  </form>
</div>

<section class="backdrop"></section>
