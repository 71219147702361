import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
// import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
  Swiper,
  EffectCube,
  EffectCoverflow,
  EffectFlip,
  Mousewheel,
} from 'swiper/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrServiceService } from './services/toastr-service.service';
import { AuthsService } from './shared/auths.service';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectCoverflow,
  EffectFlip,
  Mousewheel,
]);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isConnected = true;
  nointerNet: boolean = true;
  showNowNot: boolean = true;

  noInternetConnection: boolean | undefined;

  title = 'EvCity';

  loginForm: FormGroup | any;
  submitted = false;
  error: {} | any;
  loginError: string | any;
  forgot_form: FormGroup | any;
  userId: any;
  otp: string | any;
  showOtpComponent = true;
  confirmPasswordForm: FormGroup | any;
  showMe: boolean = false;

  constructor(
    private tost: ToastrService,
    private connectionService: ConnectionService,
    private fb: FormBuilder,
    public router: Router,
    private toastrMsg: ToastrServiceService,
    private route: ActivatedRoute,
    public Ser: AuthsService,
    private http: HttpClient,
    private titleHeader: Title
  ) {
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.noInternetConnection = false;
        this.nointerNet = true;
        // this.showNowNot = true;
      } else {
        this.noInternetConnection = true;
        this.nointerNet = false;
        // this.showNowNot = false;
      }
    });
  }
  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  urlParams: any = {};
  userDisplayName: any = '';
  closeAll() {
    $('.modal').hide();
  }
  ngOnInit() {
    setTimeout(() => {
      $('.preloader').css('display', 'none');
      $('.preloader').fadeOut('slow');
    }, 1000);
    // this.spino.show();
    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spino.hide();
    // }, 1000);
    this.setTitle('Login | Evcityride');

    // window.location.reload();

    // setTimeout(() => {
    //   $(".preloader").css('display','none');
    //   $('.preloader').fadeOut('slow');
    // }, 1000);

    $('#navbarSupportedContent').removeClass('show');

    $(document).ready(function () {
      $('.menu-icon').click(function () {
        $('.ev-sidebar').css('display', 'block');
        $('.ev-sidebar').addClass('none');

        $('.toggle-password .hide-eye').on('click', function () {
          $(this).parent().addClass('password-toggle');
          $(this)
            .closest('.form-group')
            .addClass('tog-password')
            .removeClass('hide-password');
          $('.tog-password input[type="password"]').attr('type', 'text');
        });
        // Show Password
        $('.toggle-password .show-eye').on('click', function () {
          $(this).parent().removeClass('password-toggle');
          $(this)
            .closest('.form-group')
            .addClass('hide-password')
            .removeClass('tog-password');
          $('.hide-password input[type="text"]').attr('type', 'password');
        });
      });
    });

    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
    });

    this.forgot_form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    $(document).ready(function () {
      $('.backdrop').hide();

      $(document).ready(function () {
        $('#closeTrigger').click(function () {
          $('#Lg').trigger('reset');
        });
      });
    });

    this.resetiPassLast();
  }

  fieldTextType: boolean | any;
  repeatFieldTextType: boolean | any;
  fieldTextTypeLogin: boolean | any;
  fieldTextTypeLogino: boolean | any;
  fieldTextTypeLoginos: boolean | any;

  toggleFieldLogin() {
    this.fieldTextTypeLogin = !this.fieldTextTypeLogin;
  }

  skLogin() {
    this.fieldTextTypeLogino = !this.fieldTextTypeLogino;
  }

  skLogins() {
    this.fieldTextTypeLoginos = !this.fieldTextTypeLoginos;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleRepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }

  toggleDual() {
    this.fieldTextType = !this.fieldTextType;
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }

  idAgency: any = '';

  resetiPassLast() {
    const cnst = this.desk;

    this.idAgency = localStorage.getItem('agency_id');

    this.confirmPasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]],
      agency_id: [this.idAgency],
    });
  }

  get confirm_password() {
    return this.confirmPasswordForm.get('confirm_password');
  }

  get new_pass() {
    return this.confirmPasswordForm.get('new_pass');
  }

  config = {
    allowNumbersOnly: false,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '50px',
      height: '50px',
    },
  };

  onOtpChange(otp: any) {
    this.otp = otp;
  }

  get username() {
    return this.loginForm.get('username');
  }
  get password() {
    return this.loginForm.get('password');
  }

  get email() {
    return this.forgot_form.get('email');
  }

  get f() {
    return this.confirmPasswordForm.controls;
  }

  serverUrls = "https://admin.evcityride.com/api/v1/reset-password";
  // serverUrls = 'https://evcityride.9brainz.store/api/v1/reset-password';

  resetpswd: false | any;

  varyReset: any;
  desk: any;
  loginIN: boolean = true;
  forgotpassEmail: boolean = false;
  otpSec: boolean = false;
  confPass: boolean = false;

  // mobiles(){
  //   this.stopCounter();
  // }

  closeMdl() {
    this.stopCounter();
    this.loginIN = false;
    this.forgotpassEmail = false;

    this.otpSec = false;
    this.confPass = false;
    this.forgot_form.reset();
  }

  loginClick() {
    // $(".login").show();
    this.loginIN = true;
    this.forgotpassEmail = false;
    this.otpSec = false;
    this.confPass = false;
  }

  forgetPasswordSection() {
    this.forgotpassEmail = true;
    this.loginIN = false;
  }

  bckTologin() {
    this.loginIN = true;
    this.forgotpassEmail = false;
  }

  goMail() {
    this.forgotpassEmail = true;
    this.loginIN = false;
    this.otpSec = false;
    this.stopCounter();
  }

  localdetails: any;
  // consoleTry: any;
  varyFrgt: any;
  otpSection: false | any;
  forgetPasswrd: true | any;
  isSuccess: boolean | any;

  otps: any;
  styleapply: any;

  public submi: boolean = false;

  onSubmit() {
    this.submi = true;
    this.forgetPasswrd = true;
    // this.spinner.show();
    this.Ser.login(this.username.value, this.password.value).subscribe(
      (data) => {
        // this.spinner.show();

        if (this.loginForm.valid) {
          // this.spinner.show();
          if (this.Ser.isLoggedIn()) {
            // this.spinner.show();
            // const redirect = this.Ser.redirectUrl
            //   ? this.Ser.redirectUrl
            //   : "/rider";
            this.router.navigate(['/rider/request-a-ride']);

            this.loginForm.reset();
            this.toastrMsg.showSuccess(
              ' You are Logged In Successfully ',
              'Logged In'
            );
            this.isSuccess = true;

            $('.modal-backdrop').removeClass('show');

            $('.modal-backdrop').css('display', 'none');
            $('.modal-open').css('overflow', 'auto');
            this.titleHeader.setTitle('Request a ride | EVcityride');
            // setTimeout(() => {
            //   this.spinner.hide();
            // }, 1000);
          } else {
            this.loginError = 'Username or password is incorrect.';
            this.toastrMsg.showError(
              'These credentials don’t match our records',
              'Failed'
            );

            // this.spinner.hide();
          }
        } else {
        }
      },
      (error) => (this.error = error)
    );
  }

  showHideResendMail: boolean = false;

  counter: any;
  interval: any;

  startCountdown() {
    this.counter = 30;

    this.interval = setInterval(() => {
      //
      this.counter--;

      if (this.counter <= 0) {
        clearInterval(this.interval);

        this.showHideResendMail = true;
      } else {
        this.showHideResendMail = false;
      }
    }, 1000);
  }

  stopCounter() {
    clearInterval(this.interval);
  }

  resetOTP() {
    this.onForgot();

    this.showHideResendMail = false;
    // this.startResetTimer();
  }

  loggggin: boolean = true;

  emailStored: any;

  onForgot() {
    this.submitted = true;
    this.Ser.forgotPassword(this.email.value).subscribe((res) => {
      var userCurnt = localStorage.getItem('currentUser');
      this.desk = localStorage.getItem('agency_id');

      this.varyFrgt = JSON.parse(<any>userCurnt);

      if (this.varyFrgt.success == true) {
        this.submitted = true;
        this.toastrMsg.showSuccess('Check Your Gmail Now', 'Success');
        this.forgetPasswrd = false;
        this.otpSection = true;
        // this.startResetTimer();
        // this.start();
        this.emailStored = this.email.value;

        this.otpSec = true;
        this.loginIN = false;
        this.forgotpassEmail = false;
        this.startCountdown();
        this.setTitle('OTP-Verification | Evcityride');
      } else {
        this.submitted = false;
        this.toastrMsg.showError('Your Email is Wrong', 'Failed');
        this.otpSection = false;
        this.forgetPasswrd = true;
      }
    });
  }

  SendOtp() {
    var getID = localStorage.getItem('agency_id');

    this.Ser.otpVerify(this.otp, getID).subscribe((data) => {
      var cUser = localStorage.getItem('currentUser');

      this.otps = JSON.parse(<any>cUser);

      if (this.otps.success == true) {
        this.toastrMsg.showSuccess('OTP is verified', 'Success');
        // $(".forgot-password-details").hide();
        this.otpSection = false;
        this.resetpswd = true;
        this.confPass = true;
        this.loginIN = false;
        this.otpSec = false;
        this.forgotpassEmail = false;
        this.setTitle('Reset-Password | Evcityride');
        this.stopCounter();

        // this.startResetTimerCountDown();
        // window.location.reloa  d();
      } else {
        this.toastrMsg.showError(this.otps.message, 'Wrong ');
        this.resetpswd = false;
      }
    });
  }

  public subb: boolean = false;

  Pass_submit() {
    this.subb = true;
    const formData = new FormData();

    formData.append('password', this.confirmPasswordForm.get('password').value);
    formData.append(
      'confirm_password',
      this.confirmPasswordForm.get('confirm_password').value
    );
    formData.append('agency_id', <any>localStorage.getItem('agency_id'));

    this.http.post<any>(this.serverUrls, formData).subscribe((res) => {
      localStorage.setItem('currentUser', JSON.stringify(res));

      var userpassCurr = localStorage.getItem('currentUser');

      this.varyReset = JSON.parse(<any>userpassCurr);

      if (this.confirmPasswordForm.valid) {
        if (this.varyReset.success == true) {
          this.router.navigate(['/rider']);
          this.toastrMsg.showSuccess('Password Has Changed', 'Success');
          // localStorage.setItem('agency_name', res.agency_name);
          localStorage.getItem('currentUser');
          this.confirmPasswordForm.reset();
          $('.modal-backdrop').removeClass('show');

          $('.modal-backdrop').css('display', 'none');
          $('#login').css('display', 'none');
          $('#login').removeClass('show');

          $('.modal-open').css('overflow', 'auto');
          this.resetpswd = false;
          this.setTitle('Request a ride | Evcityride');
          this.forgotpassEmail = false;
          this.otpSec = false;
          this.confPass = false;
          this.loginIN = true;
        } else {
          this.toastrMsg.showError(this.varyReset.message, 'Worng');
        }
      } else {
      }
    });
  }

  DualComboForgotPassword() {
    this.forgetPasswordSection();
    this.setTitle('Forgot-Password | Evcityride');
  }

  DualComboBackToLogin() {
    this.stopCounter();
    this.bckTologin();
    this.setTitle('Login | Evcityride');
    this.otpSec = false;
  }

  DualComboBacktoChangeEmail() {
    this.goMail();
    this.setTitle('Forgot-Password | Evcityride');
  }

  // DualComboOtpVerify(){
  //   this.SendOtp();
  // }

  showError() {
    this.tost.error('heyy', 'hllo');
  }
}
