import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';


import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,EffectFade,Swiper,EffectCube, 
  EffectCoverflow,EffectFlip,Mousewheel, 
} from 'swiper/core';




SwiperCore.use([Navigation, Pagination, 
  Scrollbar, A11y,Autoplay,EffectFade,
  EffectCube,EffectCoverflow,EffectFlip,Mousewheel]);



@Component({
  selector: 'app-web-slider',
  templateUrl: './web-slider.component.html',
  styleUrls: ['./web-slider.component.css']
})
export class WebSliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $("#navbarSupportedContent").removeClass('show');

    // this.spinner.show();

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);
  }

}
