<!-- <div class="col-sm-12 col-md-8 col-lg-9"> -->
  <!-- <ngx-spinner bdColor="white" color="black"
  template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner> -->
<!-- Row Start -->
<div class="preloader" ></div>
<div class="row position-relative">
  <!-- Column Start -->
  <div class="col-sm-12 col-md-12">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs mb-4 hyhy " id="myTab" role="tablist">
      <li class="nav-item pt-0" style="cursor: pointer;" >
        <!-- (click)="condition()" -->
        <a
          class="nav-link active"
          id="airport-pickup"
          data-toggle="tab"
          
          (click)="condition()"
          role="tab"
          aria-controls="airport-pickup"
          aria-selected="true"
          >Airport Pickup</a
        >
      </li>
      <li class="nav-item pt-0" style="cursor: pointer;">
        <a
          class="nav-link"
          id="airport-drop"
          data-toggle="tab"
          (click)="conditionDrop()"
          role="tab"
          aria-controls="airport-drop"
          aria-selected="false"
          >Airport Drop</a
        >
      </li>
      <li class="nav-item pt-0" style="cursor: pointer;">
        <a
          class="nav-link"
          id="self-drive"
          data-toggle="tab"
          (click)="conditionRent()"
          role="tab"
          aria-controls="self-drive"
          aria-selected="false"
          >Day Rental</a
        >
      </li>
    </ul>

    <!-- Tab panes -->
    <div class="tab-content">
      <!-- Airport Pickup Tab Start -->
   <app-air-pickup *ngIf="condi" ></app-air-pickup>
      <!-- Modal -->
 

      <!-- Airport Picup Tab End -->
   <app-air-drop *ngIf="condiDrop"></app-air-drop>
      <!-- Airport Drop Tab Start -->
     
      <!-- Airport Drop Tab End -->

      <!-- Day Rental Tab Start -->
    <app-day-rent *ngIf="condiRent"></app-day-rent>
      <!-- Day Rental Tab End -->
    </div>
  </div>
  <!-- Column End -->
</div>
<!-- Row Start -->

<!-- </div> -->
<section class="backdrop"></section>









 <!-- [value]="AirPickCarPlanLists | json" (change)="farePickupChanger($event)" -->

  


<!-- [value]="DayRent | json" 
                       (change)="dayRentalIMGchange($event)" 
                       {{DayRent.id}} -->




<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#confirm-booking">
Launch demo modal
</button> -->




<!-- <button routerLink="/bill" class="btn btn-danger">details</button> -->
