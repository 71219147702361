import { DatePipe } from "@angular/common";
import { ClassGetter } from "@angular/compiler/src/output/output_ast";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import * as $ from "jquery";
import * as moment from "moment";
// import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Billing } from "./billing";
import { BillingServiceService } from "./billing-service.service";
@Component({
  selector: "app-billing",
  templateUrl: "./billing.component.html",
  styleUrls: ["./billing.component.css"],
  providers: [DatePipe],
})
export class BillingComponent implements OnInit {
  constructor(
    private Service: BillingServiceService,
    private tostMsg: ToastrService,
 
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private router:Router,private titleHeader:Title
  ) {}
  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  billingDatePost: FormGroup | any;
  nameSearch: string = "";
  p: number = 1;

  start_date = new Date();
  //   end_date = new Date();

  Bill_Trip: any = [
    {
      id: "1",
      Source: "Saffron Leaf",
      Destination: "Jolly Grant Airport",
      User: "Arjun Katariya",
      Time: "04:00PM",
      Date: "Sat, 8 Jan 2021",
    },
    {
      id: "2",
      Source: "Saffron Leaf",
      Destination: "Jolly Grant Airport",
      User: "Arjun Katariya",
      Time: "04:00PM",
      Date: "Sat, 8 Jan 2021",
    },
    {
      id: "3",
      Source: "Saffron Leaf",
      Destination: "Jolly Grant Airport",
      User: "Arjun Katariya",
      Time: "04:00PM",
      Date: "Sat, 8 Jan 2021",
    },
  ];
  edDates:string = '';

  localSDate : any;
  localEDate : any;

  ngOnInit(): void {
    // var dtes = new Date();
    
    
    

    this.localSDate = localStorage.sDate;
    this.localEDate = localStorage.eDate;


  this.setTitle('Billings | Evcityride');
  localStorage.removeItem('searchOngoing');
  localStorage.removeItem('searchUpcoming');
  localStorage.removeItem('searchPast');
  

    // this.edDates ='';
    // this.End_Date.maxDate = '';
    // this.End_Date = '';
  
    // this.billingDatePost.reset();

    // this.billingDatePost.controls['start_date'].setValue('');
    // this.billingDatePost.controls['end_date'].setValue('');
    // if(localStorage.eDate == null){
    //   this.billingDatePost.end_date.value('');
    // }else{
    //   console.log("ae error");
    // }
    

    if(localStorage.sDate == null){
      this.getBills();
      // this.billingDatePost.reset();
    }else{
      const formdata = new FormData();
    var local = <any>localStorage.getItem("currentUser");
    
    let localStore = JSON.parse(local);
    formdata.append("agency_id", localStore.agency_id);
    formdata.append("start_date",<any>localStorage.getItem('sDate'));
    formdata.append("end_date",<any>localStorage.getItem('eDate'));

      this.Service.getBill(formdata).subscribe((res)=>{
 


        if (res.trips != 0) {
          this.data = res.trips;
          // this.billingDatePost.controls['start_date'].setValue(localStorage.sDate);
          // this.billingDatePost.controls['end_date'].setValue(localStorage.eDate);
          setTimeout(() => {
            $(".preloader").css("display", "none");
            $(".preloader").fadeOut("slow");
          }, 1000);
        } else {
          
          this.noBill = true;
          setTimeout(() => {
            $(".preloader").css("display", "none");
            $(".preloader").fadeOut("slow");
          }, 1000);
        }
  
        this.requestType= res;
  
        if (res.totalPickupTrip != 0) {
          this.isEnable = true;
          this.isDisable = false;
          this.pickTrips = res.totalPickupTrip;
          this.picAmounts = res.totalPickupAmount;
        } else {
          this.isEnable = false;
          this.isDisable = true;
        }
  
        if (res.totalDropTrip != 0) {
          this.showDataDrop = true;
          this.emptyDataDrop = false;
          this.dropTrips = res.totalDropTrip;
          this.dropAmounts = res.totalDropAmount;
        } else {
          this.showDataDrop = false;
          this.emptyDataDrop = true;
        }
  
        if (res.totalDayRentalTrip != 0) {
          this.showData = true;
          this.emptyData = false;
          this.dayRentalTrips = res.totalDayRentalTrip;
          this.dayRentalAmounts = res.totalDayRentalAmount;
        } else {
          this.showData = false;
          this.emptyData = true;
        }

      })
        
    }
    // this.getBills();

    // this.End_Date.minDate = this.start_date;
    // this.Start_Date.maxDate = new Date();
    // if(){

    // }
   
    // this.billingSubmit();

    $(document).ready(function () {

      // $('#txtID').val('');
      $("body").css("overflow", "auto");
      $("#search-field").hide();
      $(".ngx-pagination").css("padding", "0");
      // $(".edDate").attr('value','');

      $("#formClear").trigger('reset');
      $("#testy").removeClass("pointerEvent");

      $('.jqueryEndDate').val(localStorage.eDate);
      $('.jqueryStartDate').val(localStorage.sDate);
      


      // $("#nowData").off('click');

      // $(".tri").click(function(){
      //     $(".backdrop").show();
      //     $(".drower").show();
      //     $("body").css("overflow", "hidden");
      // });

      $("#close").click(function () {
        $(".backdrop").hide();
        $(".drower").removeClass("show-drower");
        $("body").css("overflow", "auto");
      });

      $(".backdrop").click(function () {
        $(".backdrop").hide();
        $(".drower").removeClass("show-drower");
      });

      $(".radio").click(function () {
        $(".time-fields").addClass("d-none");
      });

      $("#year").click(function () {
        $(".time-fields").removeClass("d-none");
      });

      $(".clear-all").click(function () {
        $(".time-fields").addClass("d-none");
      });

      $("#search-icon").click(function () {
        $("#search-field").show();
        $("#search-icon").hide();
      });

      $("#search-field").focusin(function () {
        $("#search-icon").hide();
      });

      $("#search-field").focusout(function () {
        $("#search-icon").show();
        $("#search-field").hide();
      });

      $("aside").hide();
      $(".backdrop").hide();

      $(".backdrop").click(function () {
        $("aside").hide();
        $(".backdrop").hide();
      });

      $(".menu-icon").click(function () {
        $("aside").show();
        $(".backdrop").show();
      });

      $(".fa-times").click(function () {
        $(".drower").removeClass("show-drower");
        $(".backdrop").hide();
      });

      $(".tri").click(function () {
        $(".drower").addClass("show-drower");
        $(".backdrop").show();
      });

      $("#close").click(function () {
        $(".drower").removeClass("show-drower");
        $(".backdrop").hide();
      });

      $(".backdrop").click(function () {
        $(".drower").removeClass("show-drower");
        $(".backdrop").hide();
      });
    });

    // this.billingDatePost = this.fb.group({
    //   start_date: ["", Validators.required],
    //   end_date: ["", Validators.required],
    // });



    this.billingDatePost = new FormGroup({
      start_date:new FormControl('',Validators.required),
      end_date:new FormControl('',Validators.required),
      

    });

    
  }
  submi:boolean=false;
  update() {

    if(localStorage.eDate == null){
      this.Start_Date.maxDate = new Date();
    
      this.End_Date.minDate = this.start_date;
      this.End_Date.maxDate = null;
    }else{
      
      this.Start_Date.maxDate = new Date();
      // this.End_Date.minDate = this.End_Date;
      this.End_Date.maxDate = null;
      this.End_Date.minDate = this.start_date;
      
      // this.billingDatePost.controls['start_date'].setValue(localStorage.sDate);
      // this.billingDatePost.controls['end_date'].setValue(localStorage.eDate);

    }
    
    
  }
 
  data: Billing | any;
  requestType: Billing | any;

  pickTrips: any;
  picAmounts: any;

  dropTrips: any;
  dropAmounts: any;

  dayRentalTrips: any;
  dayRentalAmounts: any;

  noTrips: any;

  showData: boolean = false;
  emptyData: boolean = true;

  showDataDrop: boolean = false;
  emptyDataDrop: boolean = true;

  showDataPick: boolean = false;
  emptyDataPick: boolean = true;

  dataS:any;
  hidey:boolean = true;
  item:any;
isEnable:boolean = true;
isDisable:boolean = false;

  billingSubmit() {
    // this.spinner.show();
    
    this.submi = true;
    const formdata = new FormData();
    var local = <any>localStorage.getItem("currentUser");
    
    let localStore = JSON.parse(local);
    formdata.append("agency_id", localStore.agency_id);
    formdata.append("start_date", this.billingDatePost.get("start_date").value);
    formdata.append("end_date", this.billingDatePost.get("end_date").value);

    // localStorage.setItem('S_Date',this.billingDatePost.get("start_date").value);

    // localStorage.setItem('E_Date',this.billingDatePost.get("end_date").value);
    this.Service.getBill(formdata).subscribe((res) => {
      var data = this.billingDatePost.value;
      data.start_date = this.datePipe.transform(data.start_date, 'dd-MM-YYYY');
      data.end_date = this.datePipe.transform(data.end_date, 'dd-MM-YYYY')

    localStorage.setItem('sDate',<any>data.start_date);
    localStorage.setItem('eDate',<any>data.end_date);
    // this.form.controls['dept'].setValue(selected.id)
    // this.billingDatePost.controls['start_date'].setValue(localStorage.sDate);
    // this.billingDatePost.controls['end_date'].setValue(localStorage.eDate);



      // let opp = JSON.parse(this.billingDatePost.get("start_date"));
      // localStorage.setItem('S_Date',<any>res.totalPickupTrip);
      // let ops = JSON.parse(this.billingDatePost.get("start_date"));

      // localStorage.setItem('E_Date',<any>formdata = new FormData());
      
      if(this.billingDatePost.valid){

        if(res.trips != 0){
          this.data = res.trips;
          
          this.hidey = true;
          this.noBill = false;
          // this.spinner.hide();

          // this.item = this.router.navigateByUrl("/rider/billing/airport_pickup");

         
          


   



          
          // $("#testy").removeClass("pointerEvent");
       

          if(res.totalPickupTrip != 0){
            this.pickTrips = res.totalPickupTrip;
            this.picAmounts = res.totalPickupAmount;
            
            
            this.isEnable = true;
            this.isDisable = false;
          }else{
            this.isEnable = false;
            this.isDisable = true;
          }

          if(res.totalDropTrip != 0){
            this.dropTrips = res.totalDropTrip;
            this.dropAmounts = res.totalDropAmount;
            
            
            this.showDataDrop = true;
            this.emptyDataDrop = false;
          }else{
            this.showDataDrop = false;
            this.emptyDataDrop = true;
          }


          if(res.totalDayRentalTrip != 0){
            this.dayRentalTrips = res.totalDayRentalTrip;
            this.dayRentalAmounts = res.totalDayRentalAmount;
            
            
            this.showData = true;
            this.emptyData = false;
          }else{
            this.showData = false;
            this.emptyData = true;
          }
         



        }else{
          
          this.noBill = true;
          this.hidey = false;
          this.pickTrips = res.totalPickupTrip;
          this.picAmounts = res.totalPickupAmount;
          
          

          
          this.dropTrips = res.totalDropTrip;
          this.dropAmounts = res.totalDropAmount;
          
          



          this.dayRentalTrips = res.totalDayRentalTrip;
          this.dayRentalAmounts = res.totalDayRentalAmount;
          
          
          this.tostMsg.error("No Trips available in this section", "No Data");
          this.isEnable = false;
          this.isDisable = true;
          this.showDataDrop = false;
            this.emptyDataDrop = true;
            this.showData = false;
            this.emptyData = true;
          // $("#testy").addClass("pointerEvent");
          // this.isEnable = false;
          // this.isDisable = true;
         
          // $("#snap").removeAttr("routerLink");
          // this.emptyData = true;
          // this.showData = false;
          // this.showDataPick = false;
          // this.emptyDataPick = true;
          // this.showDataDrop = false;
          // this.emptyDataDrop = true;
        }
      }else{
        
        // this.spinner.hide();


      }
     
    });

    
  }

  subDate() {
    this.billingSubmit();
  }

  noBill: boolean = false;

  getBills() {
    // this.spinner.show();

    const formdata = new FormData();
    var local = <any>localStorage.getItem("currentUser");
    let localStore = JSON.parse(local);
    formdata.append("agency_id", localStore.agency_id);
    this.Service.getBill(formdata).subscribe((res) => {
    // this.billingDatePost.controls['start_date'].setValue(localStorage.sDate);
    // this.billingDatePost.controls['end_date'].setValue(localStorage.eDate);


      if (res.trips != 0) {
        this.data = res.trips;
        setTimeout(() => {
          $(".preloader").css("display", "none");
          $(".preloader").fadeOut("slow");
        }, 1000);
        // this.spinner.hide();

      } else {
        
        this.noBill = true;
        setTimeout(() => {
          $(".preloader").css("display", "none");
          $(".preloader").fadeOut("slow");
        }, 1000);
        // this.spinner.hide();

      }

      this.requestType= res;

      if (res.totalPickupTrip != 0) {
        this.isEnable = true;
        this.isDisable = false;
        this.pickTrips = res.totalPickupTrip;
        this.picAmounts = res.totalPickupAmount;
      } else {
        this.isEnable = false;
        this.isDisable = true;
      }

      if (res.totalDropTrip != 0) {
        this.showDataDrop = true;
        this.emptyDataDrop = false;
        this.dropTrips = res.totalDropTrip;
        this.dropAmounts = res.totalDropAmount;
      } else {
        this.showDataDrop = false;
        this.emptyDataDrop = true;
      }

      if (res.totalDayRentalTrip != 0) {
        this.showData = true;
        this.emptyData = false;
        this.dayRentalTrips = res.totalDayRentalTrip;
        this.dayRentalAmounts = res.totalDayRentalAmount;
      } else {
        this.showData = false;
        this.emptyData = true;
      }

      
    });
  }

  Start_Date: any = {
    locale: moment.locale("ru"),
    // format: 'DD/MM/YYYY HH:mm',
    // buttons: {showClear: true},
    // icons: {clear: 'fa fa-trash'},
    // maxDate:new Date(),
    format: "DD-MM-YYYY",
  };

 
  End_Date: any = {
    locale: moment.locale("ru"),
    format: "DD-MM-YYYY",


    
  };

  disabledDate: [""] | any;

  changeDate(e: any) {
    
    this.disabledDate = e.format("DD-MM-YYYY HH:mm");
    //   console.log(this.disabledDate);
  }

  noDATA() {
    this.tostMsg.error("No Trips available in this section", "No Data");
  }

  getClear(){
    this.getBills();
    this.Start_Date;
    this.End_Date;
    this.billingDatePost.reset();
    this.hidey = true;
    this.noBill = false;
    localStorage.removeItem('sDate');
    localStorage.removeItem('eDate');
    // this.edDates = '';
    // this.End_Date.maxDate = null;
  }
}
