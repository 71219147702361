import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';
import { BookingListing } from '../booking-listing';
import { BookingListingServiceService } from '../booking-listing-service.service';
// import Swal from 'sweetalert2';
// import { Swal } from 'sweetalert2';

@Component({
  selector: 'app-upcoming-details',
  templateUrl: './upcoming-details.component.html',
  styleUrls: ['./upcoming-details.component.css'],
})
export class UpcomingDetailsComponent implements OnInit {
  constructor(
    private router: Router,
    private titleHeader: Title,
    private route: ActivatedRoute,
    private Service: BookingListingServiceService
  ) {}
  id: any;
  public setTitle(newTitle: string) {
    this.titleHeader.setTitle(newTitle);
  }
  ngOnInit(): void {
    this.setTitle('Bookings - Upcoming Details | EVcityride');
    this.id = this.route.snapshot.params.id;
    // console.log(this.id);

    this.getUpcomingDetails();
    $(document).ready(function () {
      $('.menu-icon').click(function () {
        $('.aside-menu').show();
      });

      $('.backdrop').click(function () {
        $('.aside-menu').hide();
        $('.backdrop').hide();
      });
    });
  }

  detailsUpcoming: BookingListing | any;
  source: any;
  destination: any;
  trip_date: any;
  trip_end_date: any;
  passengerName: any;
  passengerContact: any;
  passengerCount: any;
  booking_id: any;
  booking_date: any;
  carType: any;
  driver: any;
  driver_number: any;
  booking_status: any;
  request_type: any;
  executive_name: any = '';
  remarks: any = '';
  flight_number: any = '';

  // trip_date:any;

  getUpcomingDetails() {
    // this.spinner.show();
    this.Service.getUpcomingBookingsDetails(this.id).subscribe((res) => {
      this.detailsUpcoming = res.data;
      this.source = this.detailsUpcoming.source;
      this.destination = this.detailsUpcoming.destination;
      this.trip_date = this.detailsUpcoming.trip_date;
      this.trip_end_date = this.detailsUpcoming.trip_end_date;
      this.passengerName = this.detailsUpcoming.passenger_name;
      this.passengerContact = this.detailsUpcoming.passenger_contact_number;
      this.passengerCount = this.detailsUpcoming.total_passenger;
      this.booking_id = this.detailsUpcoming.booking_id;
      this.booking_date = this.detailsUpcoming.booking_date;
      this.carType = this.detailsUpcoming.carType;
      this.driver = this.detailsUpcoming.driver;
      this.driver_number = this.detailsUpcoming.driver_number;
      this.booking_status = this.detailsUpcoming.booking_status;
      this.request_type = this.detailsUpcoming.request_type;
      this.executive_name = this.detailsUpcoming.executive_name;
      this.remarks = this.detailsUpcoming.remarks;
      this.flight_number = this.detailsUpcoming.flight_number;
      // console.log(this.detailsUpcoming);
      // this.spinner.hide();
      setTimeout(() => {
        $('.preloader').css('display', 'none');
        $('.preloader').fadeOut('slow');
      }, 1000);
    });
  }

  delete() {
    // (Swal as any).fire('hello how Are You')

    // this.Service.deleteBooking(this.id,this.id).subscribe((res)=>{
    //   (Swal as any).fire('Amazing');
    //   console.log(res);
    // })

    if (
      confirm('Are You Sure You Want to Cancel a Booking?-->' + this.booking_id)
    ) {
      this.Service.deleteBooking(this.id, this.id).subscribe((res) => {
        // console.log(res);
        this.router.navigateByUrl('/rider/bookings/upcoming');
      });
    }

    // this.Service.deleteBooking(this.id, data).subscribe((res)=>{
    //   console.log(res);
    // })
  }

  gotoBooking() {
    this.router.navigateByUrl('/rider/bookings/upcoming');
    // console.log('Booking Enter...');
  }
}
