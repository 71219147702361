import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BookingListingServiceService } from '../bookings/booking-listing-service.service';
import { Track } from '../track-trip/track';
import { TrackServiceService } from '../track-trip/track-service.service';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css'],
})
export class MapsComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private Service: BookingListingServiceService,
    public ServiCE: TrackServiceService,
    private location: Location
  ) {}

  id: any;

  lat: any = '';
  lng: any = '';
  zoom: Number = 6;
  waypoints: any;

  public origin: any;
  public destinationss: any;

  public renderOptions = {
    suppressMarkers: true,
  };

  public markerOptions = {
    origin: {
      icon: 'assets/carESmall.png',
      draggable: false,
    },
    destination: {
      icon: 'assets/pinSmall.png',
    },
    waypoints: {
      icon: 'path-to-icon',
    },
  };
  showFire: boolean = false;
  fireLoop: any;

  fireData: Track | any;

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;

    this.getTrackDetails();
    this.ServiCE.anonymousLogin();

    this.ServiCE.getAll()
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() }))
        )
      )
      .subscribe((data) => {
        //
        this.fireData = data;
        this.showFire = true;

        this.fireLoop = this.fireData.filter((ele: any) => {
          return ele.tripId == this.id;
        });

        //
        // this.origin = {
        (this.lat = Number(this.fireLoop[0].source.lattitude)),
          (this.lng = Number(this.fireLoop[0].source.longtitude));
        // };
      });

    $(document).ready(function () {
      $('aside').hide();
      $('.backdrop').hide();

      $('.backdrop').click(function () {
        $('aside').hide();
        $('.backdrop').hide();
        $('aside').removeClass('show-drower');
      });
      $('.menu-icon').click(function () {
        $('aside').show();
        $('.backdrop').show();
      });
    });
  }

  distance: any;
  time: any;
  mapShow: boolean = false;

  detailsTrack: Track | any;
  source: any;
  destination: any;
  trip_date: any;
  trip_end_date: any;
  passengerName: any;
  passengerContact: any;
  passengerCount: any;
  booking_id: any;
  booking_date: any;
  carType: any;
  driver: any;
  driver_number: any;
  booking_status: any;
  request_type: any;
  date: any;
  tripIDS: any;
  destination_latitude: any;
  destination_longitude: any;

  getTrackDetails() {
    this.Service.getOngoingBookingsDetails(this.id).subscribe((res) => {
      this.detailsTrack = res.data;
      this.source = this.detailsTrack.source;
      this.destination = this.detailsTrack.destination;
      this.trip_date = this.detailsTrack.trip_date;
      this.trip_end_date = this.detailsTrack.trip_end_date;
      this.passengerName = this.detailsTrack.passenger_name;
      this.passengerContact = this.detailsTrack.passenger_contact_number;
      this.passengerCount = this.detailsTrack.total_passenger;
      this.booking_id = this.detailsTrack.booking_id;
      this.booking_date = this.detailsTrack.booking_date;
      this.carType = this.detailsTrack.carType;
      this.driver = this.detailsTrack.driver;
      this.driver_number = this.detailsTrack.driver_number;
      this.booking_status = this.detailsTrack.booking_status;
      this.request_type = this.detailsTrack.request_type;
      this.date = this.detailsTrack.date;
      setTimeout(() => {
        $('.preloader').css('display', 'none');
        $('.preloader').fadeOut('slow');
      }, 1000);

      this.tripIDS = this.detailsTrack.id;
      this.destination_longitude = this.detailsTrack.destination_longitude;
      this.destination_latitude = this.detailsTrack.destination_latitude;

      // this.destinationss = {
      //   lat: Number(this.destination_latitude),
      //   lng: Number(this.destination_longitude),
      // };
    });
  }

  showTrackTrip() {
    // this.router.navigateByUrl('/rider/track-trip');
    this.location.back();
  }
}
