import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { LoginRes } from './web-header/login-res';

@Injectable({
  providedIn: 'root',
})
export class AuthsService {
  errorData: {} | any;

  serverUrl = 'https://admin.evcityride.com/';

  // serverUrl = 'https://evcityride.9brainz.store/';

  constructor(private http: HttpClient) {}

  redirectUrl: string | any;

  login(username: string, password: string) {
    return this.http
      .post<any>(`${this.serverUrl}api/v1/login`, {
        username: username,
        password: password,
      })
      .pipe(
        map((user) => {
          if (user && user.access_token) {
            localStorage.setItem('currentUser', JSON.stringify(user));
          }
        }),
        catchError(this.handleError)
      );
  }

  forgotPassword(email: string) {
    return this.http
      .post<any>(`${this.serverUrl}api/v1/forgot-password`, { email })
      .pipe(
        map((user) => {
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('agency_id', user.agency_id);
        }),
        catchError(this.handleError)
      );
  }

  otpVerify(otp: any, id: any) {
    return this.http
      .post<any>(
        this.serverUrl +
          'api/v1/forgot-password-otp-varify?agency_id=' +
          id +
          '&otp=' +
          otp,
        id
      )
      .pipe(
        map((user) => {
          localStorage.setItem('currentUser', JSON.stringify(user));
          // localStorage.setItem('agency_id', user.agency_id)
        }),
        catchError(this.handleError)
      );
  }

  // resetPass(Passform:any){
  //   return this.http.post<any>(`${this.serverUrl}api/v1/reset-password`, {Passform})

  // }

  isLoggedIn() {
    if (localStorage.getItem('currentUser')) {
      return true;
    }
    return false;
  }

  getAuthorizationToken() {
    const currentUser = JSON.parse(<any>localStorage.getItem('currentUser'));
    return currentUser.access_token;
  }

  logout() {
    localStorage.removeItem('currentUser');
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.',
    };
    return throwError(this.errorData);
  }
}
