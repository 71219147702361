 <!-- Navbar Start -->
 <!-- <div class="preloader"></div> -->
 <!-- <ngx-spinner bdColor="white" size="small" color="black"
template="<img src='assets/images/favicon/logo.svg' />"></ngx-spinner>  -->

 <nav class="navbar navbar-expand-md navbar-light bg-light bg-white ev-navbar" style="position: fixed; top: 0;">
    <div class="container">
        <a class="navbar-brand" routerLink="/rider/request-a-ride"><img src="assets/images/resources/EV City Logo.svg" alt=""></a>
        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button> -->

        <!-- <a class="search-in-navigation">
            <img src="assets/images/icons/search.svg" alt="" class="" id="search-icon">
        </a>
        <form action="" class="header-search-box">
            <div class="form-group">
                <input type="text" class="form-control" id="header-search" aria-describedby="searcg">
                <a style="cursor: pointer;" class="back-icon" ><i class="fa fa-arrow-left text-dark" style="font-size: 22px;" aria-hidden="true"></i></a>
            </div>
        </form> -->
        
        <div class="menu-icon">
                <img src="assets/images/icons/menu.svg" alt="">
            </div>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                    <a class="nav-link"  (click)="close_noty()" style="cursor: pointer; "  >
                        <img src="assets/images/icons/notification.svg" style="width: 24px; height: 24px;" alt="">
                        <span class="sr-only">(current)</span></a>
                </li>
              
                
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       {{osm.agency_name}}
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="left: 45px;">
                        <a class="dropdown-item" (click)="logout()" >Logout</a>
                    </div>
                </li>
            </ul>
        </div>
       
    </div>
</nav>
<div class="notification-container" *ngIf="notify">
    <!-- <div class="col-sm-12 col-md-12" >
        <div class="w-100 p-2 bg-white" >

            Notifications
        </div>
    </div> -->
    <div class="card notification-card notifyCard ">
      
        <div class="card-body" *ngFor="let noti of notifys ; let i=index">
            <!-- <p>{{i}}</p> -->
            <!-- <p><b>Booking ID - {{noti.booking_id}}</b></p> -->
            <p class="mb-1" ><b>{{noti.notification}}</b></p>
            <p  style="color: #585858;"  class="notification-time mb-0">{{noti.date}}</p>
            <!-- <hr> -->
        </div>
    </div>
    <!-- <div class="card notification-card">
        <div class="card-body">
            <p>Booking request <span class="font-weight-bold">Ramada to Jolly airport</span> has been accepted.</p>
            <p class="notification-time">Sat, 8 Jan 2021 at 4:30 PM</p>
        </div>
    </div>
    <div class="card notification-card">
        <div class="card-body">
            <p>Satish has been assigned as driver for <span class="font-weight-bold">Ramada to Jolly airport</span> trip.</p>
            <p class="notification-time">Sat, 8 Jan 2021 at 4:30 PM</p>
        </div>
    </div> -->
  </div>
  <div *ngIf="notify" (click)="close_noty()" style="width: 100vw !important; height: 100vh !important; position: fixed !important; display: block !important; background-color: black !important; top:0; left: 0;  z-index: 998; opacity: 0.1; " >

  </div>

<!-- Navbar End -->