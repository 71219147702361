<section class="about-style-one ">
    <div class="container">

        <div class="block-title text-center">
            <div class="dot-line"></div>
            <p>EV cityride partners</p>
            <h2>Our Founders</h2>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-2">
                <div class="founder-image">
                    <img src="assets/images/Evcity/shiraz.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-sm-12 col-md-10 founder-details">
                <h2 class="font-weight-bold text-dark">Shiraz</h2>
                <h4>Founder</h4>
                <p>After 20+ years of scanning the skies, commanding commercial aircrafts, Shiraz decided to utilize the skills acquired over the years, and EV Cityride was born to spark a change for a brighter future. </p>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-sm-12 col-md-2">
                <div class="founder-image">
                    <img src="assets/images/Evcity/sunil.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-sm-12 col-md-10 founder-details">
                <h2 class="font-weight-bold text-dark">Sunil</h2>
                <h4>Finance</h4>
                <p>CA, LS, LLB, and 18 years spent making important financial decisions for major companies. We couldn't have asked for a more capable person to be our resident finance guru. </p>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-sm-12 col-md-2">
                <div class="founder-image">
                    <img src="assets/images/Evcity/rahul.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-sm-12 col-md-10 founder-details">
                <h2 class="font-weight-bold text-dark">Rahul</h2>
                <h4>Finance</h4>
                <p>An LLB from Delhi University with 18+ years in making sure companies meet statutory compliances, our legal specialist Rahul is also an expert in litigation and insurance advisory.</p>
            </div>
        </div>
    </div>
</section>